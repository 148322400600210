import { Button, Text } from 'components';
import { Spinner } from 'react-bootstrap';
import cx from 'classnames';
import { TokenComboboxInput } from 'components/form';
import { InputLayoutDtoProps } from 'components/form/input-layout';
import { TokenInputProps } from 'components/form/token-input';
import Icon from 'components/rp-icon/rp-icon';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Property from 'types/property.type';
import RetailerGroup from 'types/retailerGroup.type';
import UnitType from 'types/unit.type';
import styles from './store-select.module.scss';
import { InputCheckbox } from 'components/form/input-checkbox';

enum SearchBy {
  store = 'store',
  property = 'property',
}

interface StoreSelectProps extends InputLayoutDtoProps {
  getUnitsByRetailerGroup: (retailerGroup: RetailerGroup) => Promise<UnitType[]>;
  getUnitsByProperty: (property: Property) => Promise<UnitType[]>;
  getUnitById: (unitId: string) => UnitType;
  onChange: TokenInputProps['onChange'];
  control: TokenInputProps['control'];
  retailerGroups: RetailerGroup[];
  properties: Property[];
  limit?: number;
}

function StoreSelect({
  getUnitsByRetailerGroup,
  getUnitsByProperty,
  retailerGroups,
  properties,
  getUnitById,
  limit = 50,
  control,
  ...props
}: Readonly<StoreSelectProps>) {
  const [draftValue, setDraftValue] = useState<Set<string>>(new Set());
  const [activeTab, setActiveTab] = useState<SearchBy>(SearchBy.store);
  const [activeGroup, setActiveGroup] = useState<RetailerGroup>();
  const [activeProperty, setActiveProperty] = useState<Property>();
  const [activeUnits, setActiveUnits] = useState<UnitType[]>();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [show, setShow] = useState(false);
  const layoutRef = useRef<HTMLElement>(null);
  const { t } = useTranslation();

  const isRetailerTabActive = activeTab === SearchBy.store;
  const isPropertyTabActive = activeTab === SearchBy.property;
  // ! Find better way to get this reference
  const getOriginRef = () => layoutRef.current?.getElementsByTagName('input')?.[0];
  const getUnitId = (unit: UnitType) => (unit.sys ? unit.sys.id : unit.unitId);

  const valueRef = useRef<string[]>(control.value);
  valueRef.current = control.value;
  useEffect(() => {
    if (show) setDraftValue(new Set(valueRef.current));
  }, [show]);

  const searchByTabs = (
    <div className="d-flex">
      <button
        aria-label={t('store-dropdown.inputPlaceholderStore')}
        className={cx(styles.tab, 'w-50', {
          [styles['tab--active']]: isRetailerTabActive,
        })}
        onClick={() => {
          setActiveGroup(undefined);
          setActiveProperty(undefined);
          setActiveUnits(undefined);
          setActiveTab(SearchBy.store);
        }}
        type="button"
      >
        {t('by_store_name')}
      </button>
      <button
        aria-label={t('store-dropdown.inputPlaceholderProperty')}
        className={cx(styles.tab, 'w-50', {
          [styles['tab--active']]: isPropertyTabActive,
        })}
        onClick={() => {
          setActiveGroup(undefined);
          setActiveProperty(undefined);
          setActiveUnits(undefined);
          setActiveTab(SearchBy.property);
        }}
        type="button"
      >
        {t('by_property')}
      </button>
    </div>
  );

  const normalizeName = (name: string) => {
    // Normalize to lowercase and remove special characters
    return name
      ? name
          .toLowerCase()
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, '')
      : '';
  };

  const renderRetailerGroupsOrProperty = () => {
    const filteredRetailers = isRetailerTabActive
      ? retailerGroups
          .filter(
            (retailerGroup) =>
              (retailerGroup?.name.toLowerCase().includes(search.toLowerCase()) ||
                normalizeName(retailerGroup?.name).includes(search.toLowerCase())) ??
              false
          )
          .slice(0, limit)
      : [];
    const filteredProperties = isPropertyTabActive
      ? properties
          .filter(
            (property) =>
              (property?.webPropertyName.toLowerCase().includes(search.toLowerCase()) ||
                normalizeName(property?.webPropertyName).includes(search.toLowerCase())) ??
              false
          )
          .slice(0, limit)
      : [];

    if (
      (isRetailerTabActive && !filteredRetailers.length) ||
      (isPropertyTabActive && !filteredProperties.length)
    ) {
      return (
        <ul className={styles.items}>
          <li className={styles.item}>
            <Text type="body" color="dusty-gray">
              {t('no_results')}
            </Text>
          </li>
        </ul>
      );
    }

    return (
      <ul className={styles.items}>
        {isRetailerTabActive
          ? filteredRetailers.map((retailerGroup, key) => (
              <li key={key}>
                <button
                  onClick={async () => {
                    setLoading(true);
                    const units = await getUnitsByRetailerGroup(retailerGroup);
                    setActiveUnits(units);
                    setActiveGroup(retailerGroup);
                    setLoading(false);
                  }}
                  className={styles.retailerGroup}
                  type="button"
                >
                  <span>{retailerGroup.name}</span>
                  <Icon
                    alt={t('store-dropdown.view_units', { location: retailerGroup.name })}
                    className={styles.caret}
                    name="caret"
                    width="12"
                    height="12"
                  />
                </button>
              </li>
            ))
          : filteredProperties.map((property) => (
              <li key={property.propertyCd}>
                <button
                  onClick={async () => {
                    setLoading(true);
                    const units = await getUnitsByProperty(property);
                    setActiveUnits(units);
                    setActiveProperty(property);
                    setLoading(false);
                  }}
                  className={styles.retailerGroup}
                  type="button"
                >
                  <span>{property.webPropertyName}</span>
                  <Icon
                    alt={t('store-dropdown.view_units', { location: property.webPropertyName })}
                    className={styles.caret}
                    name="caret"
                    width="12"
                    height="12"
                  />
                </button>
              </li>
            ))}
      </ul>
    );
  };

  const renderUnits = () => {
    if (!activeUnits?.length) {
      return (
        <ul className={styles.items}>
          <li className={styles.item}>
            <Text type="body" color="dusty-gray">
              {t('no_results')}
            </Text>
          </li>
        </ul>
      );
    }

    const groupedUnits: Record<string, UnitType[]> = {};
    activeUnits?.forEach((unit) => {
      const ref = unit.property.propertyCd;
      if (!groupedUnits[ref]) groupedUnits[ref] = [];
      groupedUnits[ref].push(unit);
    });

    const activeUnitsSelectedCount = activeUnits.reduce((acc, unit) => {
      const unitId = getUnitId(unit);
      return acc + Number(draftValue.has(unitId));
    }, 0);

    return (
      <ul className={styles.items}>
        {isRetailerTabActive && (
          <li className={styles.unit}>
            <InputCheckbox
              label={t('store-dropdown.select_all_locations', {
                amount: activeUnits.length,
                location: activeGroup?.name,
              })}
              indeterminate={
                activeUnitsSelectedCount > 0 && activeUnitsSelectedCount < activeUnits.length
              }
              checked={activeUnitsSelectedCount === activeUnits.length}
              onChange={(e) => {
                const newState = new Set(draftValue);
                if (e.currentTarget.checked) {
                  activeUnits.forEach((unit) => {
                    const unitId = getUnitId(unit);
                    return newState.add(unitId);
                  });
                } else {
                  activeUnits.forEach((unit) => {
                    const unitId = getUnitId(unit);
                    return newState.delete(unitId);
                  });
                }
                setDraftValue(newState);
              }}
            />
          </li>
        )}
        {Object.values(groupedUnits).map((units) => {
          const property = units[0].property;
          return [
            <li className={styles.property} key={property.propertyCd}>
              {property.webPropertyName}
            </li>,
            ...units.map((unit, key) => (
              <li className={styles.unit} key={`${property.propertyCd}--${key}`}>
                <InputCheckbox
                  label={`${unit.fno.name} - ${unit.webStoreName}`}
                  checked={draftValue.has(getUnitId(unit))}
                  onChange={(e) => {
                    const newState = new Set(draftValue);
                    const unitId = getUnitId(unit);
                    if (e.currentTarget.checked) {
                      newState.add(unitId);
                    } else {
                      newState.delete(unitId);
                    }
                    setDraftValue(newState);
                  }}
                  value={getUnitId(unit)}
                />
              </li>
            )),
          ];
        })}
      </ul>
    );
  };

  return (
    <TokenComboboxInput
      {...props}
      control={{
        ...control,
        placeholder: isRetailerTabActive
          ? t('store-dropdown.inputPlaceholderStore')
          : t('store-dropdown.inputPlaceholderProperty'),
      }}
      required={control.required}
      renderAboveInput={searchByTabs}
      renderMenu={() => (
        <div
          className={styles.container}
          onKeyDownCapture={(e) => {
            if (e.key !== 'Escape') return;
            e.preventDefault();
            if (activeGroup || activeProperty) {
              setActiveGroup(undefined);
              setActiveProperty(undefined);
              setActiveUnits(undefined);
            } else {
              getOriginRef()?.focus();
              setShow(false);
            }
          }}
        >
          <div className={cx(styles.loader, loading && styles.loading)}>
            {activeGroup || activeProperty ? renderUnits() : renderRetailerGroupsOrProperty()}
          </div>
          <div className={styles.footer}>
            {loading ? <Spinner size="sm" animation="border" className={styles.spinner} /> : null}
            {((isRetailerTabActive && activeGroup) || (isPropertyTabActive && activeProperty)) && (
              <Button
                onClick={() => {
                  setActiveGroup(undefined);
                  setActiveProperty(undefined);
                  setActiveUnits(undefined);
                }}
                className={styles.btn}
                variant="outline"
                size="small"
              >
                {isRetailerTabActive
                  ? t('store-dropdown.back_to_retailers')
                  : t('store-dropdown.back_to_properties')}
              </Button>
            )}
            <Button
              onClick={() => {
                props.onChange?.(Array.from(draftValue));
                setShow(false);
                setActiveGroup(undefined);
                setActiveProperty(undefined);
                setActiveUnits(undefined);
                setSearch('');
              }}
              className={cx(styles.done, styles.btn)}
              size="small"
            >
              {t('done')}
            </Button>
          </div>
        </div>
      )}
      renderToken={(token) => {
        const unit = getUnitById(token);
        return `${unit?.webStoreName} • ${unit?.fno?.name} - ${unit?.property?.webPropertyName}`;
      }}
      onSearchChange={(newSearch, isClear) => {
        setSearch(newSearch);
        if (isClear) {
          setShow(false);
          setActiveGroup(undefined);
          setActiveProperty(undefined);
          setActiveUnits(undefined);
        }
      }}
      onShowChange={setShow}
      search={search}
      ref={layoutRef}
      show={show}
    />
  );
}

export default StoreSelect;
