import { toast } from 'react-toastify';

export default function handleError<T>(serviceCall: Promise<T>, cb?: (e: Error) => void) {
  return serviceCall.catch((e) => {
    // If its from abort controller -> most likely just stale request
    if (e.name === 'AbortError' || e.message === 'canceled') return;

    console.error(e.message);
    toast.error(e.message);
    cb?.(e);
  });
}
