import { Component, ErrorInfo, ReactNode } from 'react';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps {
  children: ReactNode;
  errorMessage: string;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.history.push('/');
    toast.error(this.props.errorMessage);
    console.error(errorInfo);
  }

  render() {
    // Maybe TODO: Add a 'error occured' page here instead of
    // redirecting to main page

    return this.props.children;
  }
}

export default ErrorBoundary;
