import { useState } from 'react';
import { ModalProviderProps } from 'types/modal.type';

export default function useModal() {
  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState<ModalProviderProps | null>(null);

  const setAndShowModal = (props: ModalProviderProps) => {
    setModalProps(props);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return {
    showModal,
    setShowModal,
    modalProps,
    setModalProps,
    setAndShowModal,
    closeModal,
  };
}
