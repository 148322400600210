import { Alert, Button, Col, Container, Navbar, Row } from 'react-bootstrap';
import cx from 'classnames';
import { Text, Tooltip } from '..';
import useOfferForm, { OfferState, OfferType } from './useOfferForm';
import { Trans, useTranslation } from 'react-i18next';
import {
  DateTimeInput,
  RadioGroupInput,
  TokenInput,
  TextInput,
  TextareaInput,
  ImageInput,
} from 'components/form';
import OfferCategory from 'types/offerCategory.type';
import { Offer } from 'types';
import { Fragment, useEffect, useRef } from 'react';
import { MultiSelect, StoreSelect } from 'components/select';
import useOfferLocations from './useOfferLocations';
import { useAppContext } from 'context/app-context';
import styles from './offer-form.module.scss';
import { isEmpty, isNil, omitBy } from 'lodash-es';
import { useModalContext } from 'context/modal-context';
import { ModalType } from 'constants/ModalType';
import * as Validator from 'helpers/validator';

const galleryImages = [
  '/images/offer-default/offer_image_1.jpg',
  '/images/offer-default/offer_image_2.jpg',
  '/images/offer-default/offer_image_3.jpg',
  '/images/offer-default/offer_image_1_fr.jpg',
  '/images/offer-default/offer_image_2_fr.jpg',
  '/images/offer-default/offer_image_3_fr.jpg',
];

export interface OfferFormProps {
  offer?: Offer;
  retailCategories: OfferCategory[];
}

function OfferForm({ offer, retailCategories }: Readonly<OfferFormProps>) {
  const locationsCtx = useOfferLocations(offer);
  const { formik, offerActions } = useOfferForm(offer, locationsCtx.unitMap);
  const formRef = useRef<HTMLFormElement>(null);
  const { locale } = useAppContext();
  const { t } = useTranslation();
  const { setAndShowModal, setShowModal } = useModalContext();

  const timezoneHourOffset = Math.ceil(new Date().getTimezoneOffset() / 60) * -1;
  const browserTimezone = `GMT${timezoneHourOffset < 0 ? '-' : '+'}${Math.abs(timezoneHourOffset)}`;
  const isMultilingual = formik.values.isMultilingual;

  const saveDraft = async () => {
    await formik.setFieldValue('__action', 'draft', false);
    await formik.submitForm();
  };

  const showSaveDraftModal = () =>
    setAndShowModal({
      type: ModalType.Generic,
      title: t('offer_form.save_draft_modal.title'),
      body: t('offer_form.save_draft_modal.body'),
      handleClose: () => setShowModal(false),
      handleAccept: async () => {
        setShowModal(false);
        await saveDraft();
      },
      confirmButtonText: t('offer_form.save_draft_modal.confirm_button'),
      cancelButtonText: t('offer_form.save_draft_modal.cancel_button'),
    });

  const getFieldError = (field: keyof OfferState) => {
    const isTouched = formik.touched[field];
    const error = formik.errors[field];
    return isTouched && error ? t(`error:form.${field}.${error}`) : null;
  };

  const getDateFieldError = (ref: 'start' | 'end' | 'publish') => {
    const field = `${ref}DateTime`;
    const isTouched = formik.touched[field];
    const dateError = formik.errors[field]?.['date'];
    const timeError = formik.errors[field]?.['time'];
    return isTouched && (dateError || timeError)
      ? {
          date: !!dateError && t(`error:form.${ref}Date.${dateError}`),
          time: !!timeError && t(`error:form.${ref}Time.${timeError}`),
        }
      : undefined;
  };

  const action = formik.values.__action;
  const { errors, setFieldValue } = formik;
  useEffect(() => {
    if (action === 'submit' && !isEmpty(omitBy(errors, isNil))) {
      if (formRef.current) {
        const el = formRef.current.querySelector('[aria-invalid="true"]');
        if (el) el.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
      setFieldValue('__action', undefined);
    }
  }, [action, errors, setFieldValue]);

  return (
    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset} ref={formRef}>
      {offer?.reviewComments && (
        <Alert className="mt-3" variant="warning">
          {t(`offer_form.review_comments`)}
          {t(`offer_form.comments.${offer.reviewComments}`, offer.reviewComments)}
        </Alert>
      )}
      <Text className="mb-3" type="h1" tag="h1">
        {t('create_offer')}
      </Text>
      <RadioGroupInput
        label={t('offer_form.languages')}
        error={getFieldError('isMultilingual')}
        control={{
          onChange: (e) => {
            formik.setFieldValue(
              'isMultilingual',
              e.currentTarget.value === 'english_and_french',
              false
            );
          },
          value: formik.values.isMultilingual ? 'english_and_french' : 'english',
          onBlur: formik.handleBlur,
          name: 'isMultilingual',
          required: true,
        }}
        options={[
          { label: t('english'), value: 'english' },
          { label: t('english_and_french'), value: 'english_and_french' },
        ]}
        columns={2}
      />
      <hr />
      <fieldset className="col-sm-10 p-0">
        <legend>
          <Text className="mb-3" type="h2" tag="h2">
            {t('offer_form.set_active_date')}
          </Text>
        </legend>
        <DateTimeInput
          label={t('offer_form.start_date_time', { timezone: browserTimezone })}
          description={t('offer_form.start_date_helptext')}
          errors={getDateFieldError('start')}
          onChange={(dateTime) => {
            formik.setFieldValue('startDateTime', dateTime);
          }}
          control={{
            value: formik.values.startDateTime,
            onBlur: (e) => {
              formik.handleBlur(e);

              // Set default value for Teaser Date/Time if date or time component isn't set
              const dateTime = formik.values.startDateTime;

              if (dateTime) {
                if (!formik.values.publishDateTime) {
                  formik.setFieldValue('publishDateTime', dateTime);
                } else {
                  const [startDate, startTime] = dateTime.split('T');
                  const [publishDate, publishTime] = formik.values.publishDateTime.split('T');

                  if (!publishDate) {
                    formik.setFieldValue('publishDateTime', `${startDate}T${publishTime}`);
                  }

                  if (!publishTime) {
                    formik.setFieldValue('publishDateTime', `${publishDate}T${startTime}`);
                  }
                }
              }
            },
            name: 'startDateTime',
            required: true,
          }}
          type="datetime"
        />
        <DateTimeInput
          label={t('offer_form.end_date_time', { timezone: browserTimezone })}
          description={t('offer_form.end_date_helptext')}
          errors={getDateFieldError('end')}
          onChange={(dateTime) => {
            formik.setFieldValue('endDateTime', dateTime);
          }}
          control={{
            value: formik.values.endDateTime,
            onBlur: formik.handleBlur,
            name: 'endDateTime',
            required: true,
          }}
          type="datetime"
        />
        <DateTimeInput
          label={t('offer_form.publish_date_time', { timezone: browserTimezone })}
          description={t('offer_form.publish_date_helptext')}
          errors={getDateFieldError('publish')}
          onChange={(dateTime) => {
            formik.setFieldValue('publishDateTime', dateTime);
          }}
          control={{
            value: formik.values.publishDateTime,
            onBlur: formik.handleBlur,
            name: 'publishDateTime',
            required: true,
          }}
          type="datetime"
        />
        <Alert variant="info">
          <Text tag="h3" type="h6">
            ⚠️ {t('offer_form.warning_date_required')}
          </Text>
          <Text>{t('offer_form.warning_date_required_body')}</Text>
        </Alert>
      </fieldset>
      <hr />
      <fieldset>
        <legend>
          <Text className="mb-3" type="h2" tag="h2">
            {t('offer_form.help_customers')}
          </Text>
        </legend>
        <RadioGroupInput
          label={t('offer_form.type')}
          error={getFieldError('type')}
          control={{
            onChange: formik.handleChange,
            value: formik.values.type,
            onBlur: formik.handleBlur,
            name: 'type',
            required: true,
          }}
          options={[
            { label: t('store_wide'), value: OfferType.storewide },
            { label: t('product_specific'), value: OfferType.productSpecific },
          ]}
          className="col-sm-10 p-0"
          columns={2}
        />
        <StoreSelect
          label={`${t('offer_form.locations')}${
            formik.values.locations.length ? ` (${formik.values.locations.length})` : ''
          }`}
          description={t('offer_form.locations_helptext')}
          error={getFieldError('locations')}
          onChange={(unitIds) => {
            const categories = unitIds
              .map((id) => {
                const unit = locationsCtx.unitMap[id];
                return unit.retailCategoriesCollection.items ?? [];
              })
              .flat();
            formik.setFieldValue(
              'categories',
              Array.from(
                new Set([
                  ...categories.map((category) => category.sys.id),
                  ...formik.values.categories,
                ])
              )
            );
            formik.setFieldValue('locations', unitIds);
          }}
          control={{
            value: formik.values.locations,
            onBlur: formik.handleBlur,
            name: 'locations',
            required: true,
          }}
          getUnitsByRetailerGroup={locationsCtx.getUnitsByRetailerGroup}
          getUnitsByProperty={locationsCtx.getUnitsByProperty}
          getUnitById={(unitId) => locationsCtx.unitMap[unitId]}
          retailerGroups={locationsCtx.retailerGroups}
          properties={locationsCtx.properties}
          className="col-sm-10 p-0"
        />
        <MultiSelect
          label={t('offer_form.categories')}
          error={getFieldError('categories')}
          onChange={(categories) => {
            formik.setFieldValue('categories', categories);
          }}
          control={{
            placeholder: t('multi_select.placeholder'),
            onChange: formik.handleChange,
            value: formik.values.categories,
            onBlur: formik.handleBlur,
            name: 'categories',
            required: true,
          }}
          items={retailCategories.map((category) => ({
            label: category.name,
            value: category.sys.id,
          }))}
          className="col-sm-10 p-0"
        />
        <div className={isMultilingual ? 'row' : 'col-sm-10 p-0'}>
          <div className={isMultilingual ? 'col-sm-6' : 'p-0'}>
            <TokenInput
              label={`${t('offer_form.keywords')}${isMultilingual ? ' - EN' : ''}`}
              description={t('offer_form.keywords_helptext')}
              error={getFieldError('keywords')}
              onChange={(tokens) => {
                formik.setFieldValue('keywords', tokens);
              }}
              control={{
                placeholder: t('offer_form.keywords_placeholder'),
                value: formik.values.keywords,
                onBlur: formik.handleBlur,
                name: 'keywords',
              }}
            />
          </div>
          {isMultilingual && (
            <Col sm="6">
              <TokenInput
                label={`${t('offer_form.keywords')}${isMultilingual ? ' - FR' : ''}`}
                description={t('offer_form.keywords_helptext')}
                error={getFieldError('keywordsFrench')}
                onChange={(tokens) => {
                  formik.setFieldValue('keywordsFrench', tokens);
                }}
                control={{
                  placeholder: t('offer_form.keywords_placeholder'),
                  value: formik.values.keywordsFrench,
                  onBlur: formik.handleBlur,
                  name: 'keywordsFrench',
                }}
              />
            </Col>
          )}
        </div>
      </fieldset>
      <hr />
      <fieldset className={isMultilingual ? undefined : 'col-sm-10 p-0'}>
        <legend>
          <Text type="h2" tag="h2">
            {t('offer_form.promotion_description')}
          </Text>
        </legend>
        <Text className="mb-4" tag="p" type="bodyLg">
          {t('offer_form.offer_description_body')}
        </Text>
        <Row>
          <Col sm={isMultilingual ? 6 : undefined}>
            <Text tag="h3" type="h3" className="mb-3">
              {t('english')}
            </Text>
            <TextInput
              label={`${t('headline')} ${isMultilingual ? ' - EN' : ''}`}
              error={getFieldError('headline')}
              control={{
                onChange: formik.handleChange,
                value: formik.values.headline,
                onBlur: formik.handleBlur,
                name: 'headline',
                required: true,
              }}
              row={!isMultilingual}
            />
            <TextareaInput
              label={`${t('description')} ${isMultilingual ? ' - EN' : ''}`}
              error={getFieldError('description')}
              control={{
                onChange: formik.handleChange,
                value: formik.values.description,
                onBlur: formik.handleBlur,
                name: 'description',
                required: true,
                rows: 10,
              }}
              row={!isMultilingual}
            />
            <ImageInput
              label={`${t('offer_form.thumbnail')} ${isMultilingual ? ' - EN' : ''}`}
              description={<Tooltip message={t('offer_form.thumbnail_photo_tooltip')} />}
              error={getFieldError('image')}
              onChange={(newFile) => {
                formik.setFieldValue('image', newFile);
              }}
              control={{
                onChange: formik.handleChange,
                value: formik.values.image ?? undefined,
                onBlur: formik.handleBlur,
                name: 'image',
                required: true,
              }}
              gallery={galleryImages}
              row={!isMultilingual}
            />
          </Col>
          {isMultilingual && (
            <Col sm="6">
              <Text tag="h3" type="h3" className="mb-3">
                {t('french')}
              </Text>
              <TextInput
                label={`${t('headline')} - FR`}
                error={getFieldError('headlineFrench')}
                control={{
                  onChange: formik.handleChange,
                  value: formik.values.headlineFrench,
                  onBlur: formik.handleBlur,
                  name: 'headlineFrench',
                  required: true,
                }}
                row={!isMultilingual}
              />
              <TextareaInput
                label={`${t('description')} - FR`}
                error={getFieldError('descriptionFrench')}
                control={{
                  onChange: formik.handleChange,
                  value: formik.values.descriptionFrench,
                  onBlur: formik.handleBlur,
                  name: 'descriptionFrench',
                  required: true,
                  rows: 10,
                }}
                row={!isMultilingual}
              />
              <ImageInput
                label={`${t('offer_form.thumbnail')} - FR`}
                description={<Tooltip message={t('offer_form.thumbnail_photo_tooltip')} />}
                error={getFieldError('imageFrench')}
                onChange={(newFile) => {
                  formik.setFieldValue('imageFrench', newFile);
                }}
                control={{
                  onChange: formik.handleChange,
                  value: formik.values.imageFrench ?? undefined,
                  onBlur: formik.handleBlur,
                  name: 'imageFrench',
                  required: true,
                }}
                gallery={galleryImages}
                row={!isMultilingual}
              />
            </Col>
          )}
        </Row>
      </fieldset>
      <hr />
      <fieldset className="col-sm-10 p-0">
        <legend>
          <Text type="h2" tag="h2">
            {t('offer_form.track_promo_effectiveness')}
          </Text>
        </legend>
        <TextInput
          label={t('offer_form.promo_code')}
          description={t('offer_form.track_promo_helptext')}
          error={getFieldError('promotionCode')}
          control={{
            onChange: formik.handleChange,
            value: formik.values.promotionCode,
            onBlur: formik.handleBlur,
            name: 'promotionCode',
          }}
        />
      </fieldset>
      {!!offer?.auditLog && (
        <>
          <hr />
          <Text type="h2" tag="h2">
            {t('offer_form.audit_log.title')}
          </Text>
          <dl className={cx(styles.auditLog, 'col-sm-10 p-0')}>
            {offer.auditLog.map((entry, key) => (
              <Fragment key={key}>
                <dt className="mt-3">
                  <Text type="h6" tag="h3">
                    {new Date(entry.date).toLocaleString(locale, {
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                      minute: '2-digit',
                      hour: '2-digit',
                    })}
                  </Text>
                </dt>
                <dd>
                  <Text type="body" tag="p">
                    <Trans
                      i18nKey={`offer_form.audit_log.action.${entry.action.toLowerCase()}`}
                      values={{ author: entry.author }}
                    />
                  </Text>
                  {entry.fields && (
                    <Text type="body" tag="p">
                      <Trans
                        i18nKey="offer_form.audit_log.fields"
                        values={{
                          fields: entry.fields
                            .map((field) => t(`offer_form.audit_log.${field}`))
                            .join(', '),
                        }}
                      />
                    </Text>
                  )}
                  <Text type="body" tag="p">
                    <Trans
                      i18nKey="offer_form.audit_log.statusLine"
                      values={{ status: t(`offer_form.audit_log.${entry.status}`) }}
                    />
                  </Text>
                </dd>
              </Fragment>
            ))}
          </dl>
        </>
      )}
      <Navbar bg="cf-action-nav" expand="sm" fixed="bottom">
        <Container className={cx(styles.nav, 'justify-content-start')}>
          {offerActions.saveDraft && (
            <Button
              variant="cf-secondary"
              onClick={async () => {
                const isDateFieldMissing = (ref: 'start' | 'end' | 'publish') => {
                  const field = `${ref}DateTime`;
                  const dateMissing = errors[field]?.['date'] === Validator.ValidationError.missing;
                  const timeMissing = errors[field]?.['time'] === Validator.ValidationError.missing;
                  return dateMissing || timeMissing;
                };

                if (
                  isDateFieldMissing('start') ||
                  isDateFieldMissing('end') ||
                  isDateFieldMissing('publish')
                ) {
                  showSaveDraftModal();
                } else {
                  await saveDraft();
                }
              }}
              value="draft"
              name="__action"
            >
              {t('save_draft')}
            </Button>
          )}
          {offerActions.submitReview && (
            <Button
              variant="cf-primary"
              onClick={async () => {
                await formik.setFieldValue('__action', 'submit', false);
                await formik.submitForm();
              }}
              value="submit"
              name="__action"
            >
              {t('submit_for_review')}
            </Button>
          )}
        </Container>
      </Navbar>
    </form>
  );
}

export default OfferForm;
