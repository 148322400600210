import { useState } from 'react';
import { Trans } from 'react-i18next';
import LoginLinks, { BottomLinks } from './LoginLinks';
import AccountLocked from './AccountLocked';
import { useTranslation } from 'hooks';
import { WidgetStates, WidgetErrorCodes } from 'constants/OktaWidget';
import OktaSignInWidget from 'widgets/oktaSignInWidget';
import { useOktaAuth } from '@okta/okta-react';
import { Layout, Text } from 'components';
import styles from './LoginPage.module.scss';
import { useAppContext } from 'context/app-context';
import {
  EventErrorContext,
  RenderResult,
  RenderResultSuccessOIDC,
  WidgetOptions,
} from '@okta/okta-signin-widget';
import { LoginStatus } from 'helpers/analytics';

const LoginPage = ({ config }: { config: WidgetOptions }) => {
  const { oktaAuth } = useOktaAuth();
  const { t } = useTranslation();
  const [widgetState, setWidgetState] = useState<WidgetStates>();
  const { locale, analyticsInstance } = useAppContext();

  const onSuccess = (res: RenderResult) => {
    const result = res as RenderResultSuccessOIDC;
    const state = JSON.parse(result.state ?? '{}');
    if (state.lang) {
      window.sessionStorage.setItem('lang', state.lang);
    }
    analyticsInstance?.sendLoginEvent(LoginStatus.SUCCESS);
    oktaAuth.handleLoginRedirect(result.tokens);
  };

  const onSignInError = (err: EventErrorContext) => {
    // If sign in failed due to account being locked, display custom widget instead of error
    if (err?.xhr?.responseJSON?.errorCode === WidgetErrorCodes.accountLocked) {
      setWidgetState(WidgetStates.accountLocked);
      analyticsInstance?.sendLoginEvent(LoginStatus.SIGN_IN_ERROR);
    }
  };

  const onError = (err: Error) => {
    console.error('error logging in', err);
    analyticsInstance?.sendLoginEvent(LoginStatus.ERROR);
  };

  return (
    <Layout
      pageTitle={t('metadata:loginPage.pageTitle')}
      pageDescription={t('metadata:loginPage.description')}
      showTapestryLeft
      backgroundColor="pale-blue"
    >
      <div className={styles.signInPage}>
        {widgetState === WidgetStates.accountLocked ? (
          <AccountLocked setWidgetState={setWidgetState} />
        ) : (
          <div>
            <div className={styles.widgetWrapper}>
              <Text type="h2" tag="h1" typeMobile="h1" className="mb-2">
                <Trans i18nKey={`signIn.${widgetState === WidgetStates.mfa ? 'mfa.' : ''}title`}>
                  {''}
                </Trans>
              </Text>
              {widgetState === WidgetStates.login && (
                <Text color="cf-dark-grey" tag="p">
                  {t('signIn.description')}
                </Text>
              )}
              <OktaSignInWidget
                config={{
                  language: locale.slice(0, 2),
                  state: JSON.stringify({
                    lang: locale.slice(0, 2),
                  }),
                  ...config,
                }}
                onSuccess={onSuccess}
                onSignInError={onSignInError}
                onError={onError}
                onChange={setWidgetState}
              />
              {widgetState === WidgetStates.login && <LoginLinks />}
            </div>

            <BottomLinks />
          </div>
        )}
      </div>
    </Layout>
  );
};
export default LoginPage;
