// For API Request
enum UserStatus {
  All = 'all',
  Active = 'active',
  Pending = 'pending',
  Deactivated = 'deactivated',
  InRecovery = 'in-recovery',
}

enum OktaUserStatus {
  STAGED = 'STAGED',
  PROVISIONED = 'PROVISIONED',
  ACTIVE = 'ACTIVE',
  RECOVERY = 'RECOVERY',
  PASSWORD_EXPIRED = 'PASSWORD_EXPIRED',
  LOCKED_OUT = 'LOCKED_OUT',
  SUSPENDED = 'SUSPENDED',
  DEPROVISIONED = 'DEPROVISIONED',
}

enum PortalRole {
  // Offers
  RetailClient = 'retailClient',
  OffersManager = 'cfOffersManager',
  // User Management
  MarketingApprover = 'cfMarketingApprover',
  RavelOpsSupportAdmin = 'ravelOpsSupportAdmin',
  RetailPortalAdmin = 'cfRetailPortalAdmin',
}

export { OktaUserStatus, UserStatus, PortalRole };
