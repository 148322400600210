import Locale from 'constants/Locale';

type PolicyUrl = {
  [Locale.en]: string;
  [Locale.fr]: string;
};

const policyUrls: Record<string, PolicyUrl> = {
  accessbility: {
    [Locale.en]: 'https://cadillacfairview.com/accessibility/',
    [Locale.fr]: 'https://cadillacfairview.com/fr-CA/accessibility/',
  },
  privacy: {
    [Locale.en]: 'https://cadillacfairview.com/privacy-policy/',
    [Locale.fr]: 'https://cadillacfairview.com/fr-CA/privacy-policy/',
  },
  disclaimer: {
    [Locale.en]: 'https://cadillacfairview.com/disclaimer/',
    [Locale.fr]: 'https://cadillacfairview.com/fr-CA/disclaimer/',
  },
  tos: {
    [Locale.en]: 'https://shops.cadillacfairview.com/retail-portal-terms-of-service/',
    [Locale.fr]: 'https://shops.cadillacfairview.com/fr-CA/retail-portal-terms-of-service',
  },
  contactUs: {
    [Locale.en]: 'https://cadillacfairview.com/contact-us/',
    [Locale.fr]: 'https://cadillacfairview.com/fr-CA/contact-us/',
  },
  about: {
    [Locale.en]: 'https://cadillacfairview.com/about-us/',
    [Locale.fr]: 'https://cadillacfairview.com/fr-CA/about-us/',
  },
};

export default policyUrls;
