import cx from 'classnames';
import Icon, { IconProps } from 'components/rp-icon/rp-icon';
import { forwardRef, HTMLAttributes, InputHTMLAttributes, MouseEventHandler } from 'react';
import styles from './input-field.module.scss';

interface InputFieldIcon extends IconProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export interface InputFieldProps extends InputHTMLAttributes<HTMLDivElement> {
  control: InputHTMLAttributes<HTMLInputElement>;
  error?: boolean;
  icon?: {
    left?: InputFieldIcon;
    right?: InputFieldIcon;
  };
}

interface InputIconProps extends HTMLAttributes<HTMLElement> {
  icon?: InputFieldIcon;
}
function InputIcon({ icon, ...props }: InputIconProps) {
  const isButton = !!icon?.onClick;
  const Component = isButton ? 'button' : 'span';

  if (!icon) return null;

  return (
    <Component {...props} type={isButton ? 'button' : undefined} onClick={icon.onClick}>
      <Icon {...icon} />
    </Component>
  );
}

const InputField = forwardRef<HTMLDivElement, InputFieldProps>(function InputField(
  { control, icon, error, ...props }: InputFieldProps,
  ref
) {
  return (
    <div
      {...props}
      className={cx(error && 'is-invalid', control.disabled && 'disabled', styles.group)}
      ref={ref}
    >
      <InputIcon className={styles.prepend} icon={icon?.left} />
      <input {...control} className={cx(control.className, styles.input)} />
      <InputIcon className={styles.append} icon={icon?.right} />
    </div>
  );
});

export default InputField;
