import Spinner from 'react-bootstrap/Spinner';
import { RemoveScroll } from 'react-remove-scroll';
import styles from './full-page-loader.module.scss';

const FullLoaderComponent = ({ isLoading }: FullLoaderComponentProps) => {
  return (
    <>
      {isLoading && (
        <RemoveScroll>
          <div className={styles['loader-container']}>
            <div className={styles.loader}>
              {/* <Icon name="discard" alt="discard-icon" /> */}
              <Spinner animation="border" variant="dark" />
            </div>
          </div>
        </RemoveScroll>
      )}
    </>
  );
};

export interface FullLoaderComponentProps {
  isLoading: boolean;
}

export default FullLoaderComponent;
