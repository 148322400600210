import React from 'react';
import Icon from '../rp-icon/rp-icon';
import imageMap from '../../constants/IconUrlMap';
import styles from './custom-button.module.scss';

const CustomButton = ({
  variant,
  iconName,
  alt,
  text,
  onClick,
  disabled = false,
  customStyle,
  iconWidth,
  iconHeight,
  textCustomStyle,
  id,
}: CustomButtonProps) => {
  return (
    <button
      id={id}
      type="button"
      onClick={onClick}
      className={`btn btn-${variant} ${styles.button} ${customStyle ?? ''}`}
      disabled={disabled}
    >
      <Icon name={iconName} alt={alt} width={iconWidth} height={iconHeight} />
      <span className={`${styles.text} ${textCustomStyle ?? ''}`}>{text}</span>
    </button>
  );
};

export interface CustomButtonProps {
  variant: string;
  iconName: keyof typeof imageMap;
  alt: string;
  text: string;
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
  customStyle?: string;
  iconWidth?: string;
  iconHeight?: string;
  textCustomStyle?: string;
  id?: string;
}

export default CustomButton;
