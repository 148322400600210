import { useFormik } from 'formik';
import Locale from 'constants/Locale';
import * as Validator from 'helpers/validator';
import UserService from 'services/user';
import ApiError from 'lib/errors/ApiError';
import UnitType from 'types/unit.type';
import { useFullPageLoaderContext } from 'context/full-page-loader-context';

export enum FormLabels {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  password = 'password',
  passwordConf = 'passwordConf',
  langPref = 'langPref',
  tosAgreement = 'tosAgreement',
  stores = 'stores',
}

export type SignupFormData = {
  [FormLabels.firstName]: string;
  [FormLabels.lastName]: string;
  [FormLabels.email]: string;
  [FormLabels.password]: string;
  [FormLabels.passwordConf]: string;
  [FormLabels.langPref]: Locale;
  [FormLabels.tosAgreement]: boolean;
  [FormLabels.stores]: UnitType[];
};

export interface RequestUnit {
  unitCd?: string;
  propertyCd: string;
  retailerId?: string;
}

type FormErrors = Partial<Record<FormLabels, Validator.ValidationError | null>>;

function validateForm(data: SignupFormData): FormErrors {
  const errors: FormErrors = {};

  if (Validator.validateFirstName(data.firstName)) {
    errors.firstName = Validator.validateFirstName(data.firstName);
  }

  if (Validator.validateLastName(data.lastName)) {
    errors.lastName = Validator.validateLastName(data.lastName);
  }

  if (Validator.validateEmail(data.email)) {
    errors.email = Validator.validateEmail(data.email);
  }

  if (Validator.validatePassword(data.password)) {
    errors.password = Validator.validatePassword(data.password);
  }

  if (Validator.validateStores(data.stores)) {
    errors.stores = Validator.validateStores(data.stores);
  }

  if (data.passwordConf !== data.password) {
    errors.passwordConf = Validator.ValidationError.misMatch;
  }

  if (!data.tosAgreement) {
    errors.tosAgreement = Validator.ValidationError.missing;
  }

  return errors;
}

function useSignupForm(params: {
  beforeSubmit?(data: SignupFormData): void;
  onSuccess?(data: SignupFormData): void;
  afterSubmit?(data: SignupFormData): void;
}) {
  const { handleLoading } = useFullPageLoaderContext();
  const form = useFormik<SignupFormData>({
    initialValues: {
      [FormLabels.firstName]: '',
      [FormLabels.lastName]: '',
      [FormLabels.email]: '',
      [FormLabels.password]: '',
      [FormLabels.passwordConf]: '',
      [FormLabels.langPref]: Locale.en,
      [FormLabels.tosAgreement]: false,
      [FormLabels.stores]: [],
    },
    validateOnMount: true,
    validate: (values) => validateForm(values),
    onSubmit: (values) =>
      handleLoading(
        (async () => {
          try {
            if (params.beforeSubmit) {
              params.beforeSubmit(values);
            }

            const units: RequestUnit[] = [];
            values.stores.forEach((v) => {
              units.push({
                unitCd: v.unitCd,
                propertyCd: v.property.propertyCd,
                retailerId: v.retailerId,
              });
            });

            await UserService.signup({
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              password: values.password,
              preferredLanguage: values.langPref,
              units: units,
            });

            if (params.onSuccess) {
              params.onSuccess(values);
            }
          } catch (err: unknown) {
            if (err instanceof ApiError) {
              const apiError = err as ApiError;

              if (apiError.status === 400) {
                const errors: Record<string, string> = {};
                if (apiError.details) {
                  apiError.details.forEach((detail) => {
                    if (detail.param) {
                      errors[detail.param as string] = detail.msg;
                    }
                  });
                }
                form.setErrors(errors);
              }
            }
          } finally {
            if (params.afterSubmit) {
              params.afterSubmit(values);
            }
          }
        })()
      ),
  });

  return form;
}

export default useSignupForm;
