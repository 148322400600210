import axios, { AxiosInstance } from 'axios';
import type { OfferCollection, Offer, OfferData } from 'types';
import Status from 'constants/Status';
import { toFormData } from 'helpers/form';
import { OfferIdResponse } from 'types/offer.type';

class OfferRequester {
  axios: AxiosInstance;

  constructor(apiToken: string) {
    this.axios = axios.create({
      baseURL: '/api/offer',
      headers: {
        authorization: `Bearer ${apiToken}`,
        'content-type': 'application/form-data',
      },
    });
  }

  async getOffers(status: string, skip: number, limit: number, search = '') {
    const res = await this.axios.get<OfferCollection>('/', {
      params: {
        status,
        skip,
        limit,
        search,
      },
    });

    return res.data;
  }

  async get(id: string) {
    const res = await this.axios.get<Offer>(`/${id}`);

    return res.data;
  }

  async create(data: Partial<OfferData>, status: Status.Draft | Status.PendingReview) {
    const form = toFormData({ ...data, status });

    const res = await this.axios.post<{ id: string }>('/', form);

    return res.data;
  }

  async edit(id: string, data: Partial<OfferData>, status: Status.Draft | Status.PendingReview) {
    const form = toFormData({ ...data, status });

    const res = await this.axios.put<{ id: string }>(`/${id}`, form);

    return res.data;
  }

  async discard(id: string) {
    const res = await this.axios.delete<Offer>(`/${id}`);

    return res.data;
  }

  async duplicate(id: string) {
    const res = await this.axios.post<OfferIdResponse>(`/${id}/duplicate`);

    return res.data;
  }
}

export default OfferRequester;
