import { Text, Layout } from 'components';
import Container from 'react-bootstrap/Container';
import { useAppContext } from 'context/app-context';
import Locale from 'constants/Locale';
import { useTranslation } from 'react-i18next';

const LAST_UPDATED = new Date('2022-01-26');

function getLastUpdated() {
  return LAST_UPDATED.toLocaleDateString('en', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  });
}

function Heading(props: { children: React.ReactNode }) {
  return (
    <Text tag="h2" type="h2" className="mt-5 mb-2">
      {props.children}
    </Text>
  );
}

function Paragraph(props: { children: React.ReactNode }) {
  return (
    <Text tag="p" type="bodyLg" className="mb-3">
      {props.children}
    </Text>
  );
}

function ToSPageFr() {
  return (
    <div>
      <Heading>Définition</Heading>
      <Paragraph>
        Les conditions générales d’utilisation suivantes s’appliquent à l’accès et à l’utilisation
        de ce portail et des services de publicité en libre-service fournis par la Corporation
        Cadillac Fairview limitée. Ces règles ont une incidence sur les droits légaux de
        l’utilisateur du portail et de tout détaillant que l’utilisateur représente et imposent des
        obligations à l’utilisateur et au détaillant. Chaque fois que vous accédez au portail et que
        vous l’utilisez, vous acceptez, en votre nom et au nom du détaillant que vous représentez,
        d’être lié par la version actuelle des conditions générales d’utilisation. Si vous n’êtes
        pas d’accord avec les conditions générales d’utilisation du portail, votre seul recours
        consiste à cesser de l’utiliser.
      </Paragraph>
      <Paragraph>
        Veuillez consulter notre politique de confidentialité pour obtenir des détails sur la
        collecte, l’utilisation, la conservation, la divulgation et la gestion des renseignements
        personnels recueillis par l’entremise du portail. La politique de confidentialité fait
        partie des conditions générales d’utilisation.
      </Paragraph>

      <Heading>Inscription</Heading>
      <Paragraph>
        L’utilisation du portail nécessite l’inscription et la création d’un profil utilisateur («
        compte »). En vous inscrivant, vous déclarez et garantissez que vous avez atteint l’âge de
        majorité de votre province de résidence. Au cours du processus d’inscription, on vous
        demandera de saisir votre nom et une adresse électronique valide et de choisir un mot de
        passe fort. Vous déclarez et garantissez que vous veillerez à mettre à jour et à maintenir
        l’intégralité et l’exactitude de vos données d’inscription.
      </Paragraph>

      <Heading>Utilisation autorisée</Heading>
      <Paragraph>
        Si vous êtes un utilisateur inscrit du portail et que vous respectez les présentes
        conditions générales d’utilisation, vous pouvez utiliser nos services publicitaires sur le
        portail pour soumettre du contenu publicitaire. Nos politiques de publicité fournissent des
        conseils sur les types de contenu publicitaire autorisés.
      </Paragraph>

      <Heading>Utilisation interdite</Heading>
      <Paragraph>
        l vous incombe de connaître toutes les lois applicables relatives aux services publicitaires
        et à votre utilisation du portail et de vous y conformer. En utilisant le portail, vous
        déclarez et garantissez que votre utilisation est conforme aux lois applicables.
      </Paragraph>
      <Paragraph>
        Vous ne pouvez utiliser le portail d’une manière qui :
        <ol>
          <li>
            enfreint ou viole les droits de toute autre partie (y compris, mais sans s’y limiter,
            marques de commerce, brevets, droits d’auteur, publicités ou autres droits de
            propriété);
          </li>
          <li>
            est illégale, frauduleuse ou trompeuse, ou nuisible, menaçante, abusive, harcelante,
            délictuelle, diffamatoire, vulgaire, obscène, calomnieuse, porte atteinte à la vie
            privée d’autrui, haineuse, ou répréhensible sur le plan racial, ethnique ou autre;{' '}
          </li>
          <li>
            constitue une publicité non sollicitée ou non autorisée, du matériel promotionnel, des «
            pourriels », des « courriels indésirables », des « chaînes de lettres », des «
            stratagèmes pyramidaux » ou toute autre forme de sollicitation;
          </li>
          <li>
            vise à duper des personnes, entraîne la création d’une fausse identité de l’expéditeur
            ou de l’origine d’un message, imite des en-têtes ou manipule autrement des identifiants
            afin de masquer l’origine de tout matériel transmis par l’entremise du portail;
          </li>
          <li>
            usurpe l’identité d’une personne ou d’une entité, y compris, mais sans s’y limiter, un
            employé de Cadillac Fairview, ou déclare faussement ou présente de façon inexacte votre
            affiliation à une personne ou à une entité;
          </li>
          <li>
            entraîne le téléchargement de tout contenu contenant une forme quelconque de logiciel
            destructeur;
          </li>
          <li>
            utilise des robots d’indexation ou des outils de forage de données similaires pour
            accéder à n’importe quelle partie du portail ou l’acquérir, la copier ou la surveiller;
          </li>
          <li>
            copie, modifie, crée une œuvre dérivée, fait de l’ingénierie inverse, décompile ou tente
            d’extraire le code source de tout logiciel exclusif utilisé pour fournir ou maintenir le
            portail ou d’une façon qui soit autrement applicable au portail;
          </li>
          <li>
            tente d’accéder sans autorisation au portail, à d’autres systèmes informatiques ou
            réseaux connectés au portail;
          </li>
          <li>
            prend toute mesure qui impose ou pourrait imposer (comme nous l’avons déterminé à notre
            entière discrétion) une charge déraisonnable ou disproportionnée à notre infrastructure
            (ou celle de nos fournisseurs tiers);
          </li>
          <li>entrave ou perturbe les réseaux ou les serveurs connectés au portail; ou</li>
          <li> facilite ou encourage n’importe lesquelles des actions susmentionnées.</li>
        </ol>
      </Paragraph>

      <Heading>Soumissions d’un contenu publicitaire</Heading>
      <Paragraph>
        Nos politiques sur la publicité fournissent des conseils sur les types de contenu
        publicitaire pouvant être soumis. Toutefois, Cadillac Fairview peut, à son entière
        discrétion, accepter ou refuser les soumissions. Si votre soumission d’un contenu
        publicitaire est acceptée, nous déterminerons la taille, l’emplacement et le positionnement
        de vos annonces sur nos propriétés. Nous pouvons également modifier le contenu de l’annonce
        dans le but de publier la publicité. Consultez « Propriété du contenu » ci-dessous pour plus
        de détails.
      </Paragraph>

      <Heading>Propriété du contenu du portail</Heading>
      <Paragraph>
        Le contenu du portail est la propriété exclusive de Cadillac Fairview et est protégé par les
        droits d’auteur, marques de commerce, marques de service, brevets, secrets commerciaux et
        autres droits et lois sur la propriété intellectuelle au Canada et à l’étranger. Vous
        acceptez de ne pas copier, tenter de procéder à une ingénierie inverse, modifier, traduire
        ou désassembler le logiciel du portail en tout ou en partie, y compris toute tentative de
        reconstruction, d’identification ou de découverte du code source, d’idées sous-jacentes, de
        techniques d’interface utilisateur sous-jacentes ou d’algorithmes du portail. Il vous est
        interdit d’utiliser, d’exporter ou de réexporter le portail, sauf dans les cas autorisés par
        les lois du Canada et des États-Unis et les lois du territoire dans lequel l’accès au
        portail a été obtenu. Il vous est interdit de copier, reproduire, exécuter, distribuer,
        afficher, modifier, traduire, fusionner avec d’autres données, cadrer dans un autre site
        web, publier sur un autre site web ou créer des œuvres dérivées du logiciel du portail ou du
        contenu du portail, en tout ou en partie, dans le but de créer un produit ou un service
        concurrent ou pour les revendre ou les distribuer.
      </Paragraph>

      <Heading>Propriété du contenu publicitaire</Heading>
      <Paragraph>
        En téléchargeant tout contenu publicitaire sur ou par l’entremise du portail, vous accordez
        par la présente (au nom du propriétaire du contenu publicitaire) à Cadillac Fairview, à ses
        sociétés affiliées et à leurs fournisseurs de services, représentants et agents une licence
        non exclusive, pouvant donner lieu à l’octroi d’une sous-licence (uniquement pour afficher
        le contenu publicitaire), internationale, entièrement payée, perpétuelle et irrévocable, et
        une licence libre de redevances pour copier, utiliser, modifier, exécuter publiquement,
        afficher publiquement, enlever, supprimer, reproduire et distribuer ce contenu publicitaire
        conformément aux instructions de la commande d’insertion sans l’obligation d’effectuer un
        paiement au propriétaire ou à un tiers ou de demander une autorisation supplémentaire au
        propriétaire ou à un tiers. Cette licence comprend le droit d’héberger, d’indexer, de mettre
        en cache, de distribuer et d’étiqueter tout contenu publicitaire. De plus, vous renoncez (au
        nom du créateur du contenu publicitaire) à tous les droits moraux sur tout contenu
        publicitaire que vous affichez ou publiez d’une autre manière sur ou par l’intermédiaire du
        portail en faveur de Cadillac Fairview. À l’exception de la licence restreinte accordée dans
        les présentes conditions générales d’utilisation, le propriétaire et le créateur du contenu
        publicitaire continuent de conserver tous vos droits de propriété sur votre contenu
        publicitaire.
      </Paragraph>

      <Heading>Déclarations et garanties</Heading>
      <Paragraph>
        Les utilisateurs du portail qui placent du contenu publicitaire au nom d’un détaillant
        déclarent et garantissent qu’ils ont le pouvoir de lier le détaillant aux présentes
        conditions générales d’utilisation et qu’ils le feront, le cas échéant.
      </Paragraph>
      <Paragraph>
        Vous déclarez et garantissez que : (a) le contenu publicitaire est exact et conforme à
        toutes les lois, réglementations et directives applicables, ainsi qu’à nos{' '}
        <strong>politiques publicitaires</strong>; b) vous avez le droit de soumettre le contenu
        publicitaire et que vous avez le droit d’accorder la licence énoncée dans les présentes
        conditions générales d’utilisation; (c) l’affichage ou la publication du contenu
        publicitaire que vous soumettez et l’utilisation du contenu publicitaire par Cadillac
        Fairview ne violent pas et ne violeront pas les droits à la vie privée, les droits de
        publicité, les droits d’auteur, les droits de marque de commerce, les brevets, les droits
        contractuels ou tout autre droit de propriété intellectuelle ou tout autre droit de toute
        personne ou entité.
      </Paragraph>

      <Heading>Modifications et résiliation</Heading>
      <Paragraph>
        Nous nous réservons le droit, à tout moment et de temps à autre, de modifier ou
        d’interrompre temporairement ou définitivement le portail (ou une partie de celui-ci) avec
        ou sans préavis, même si vous avez créé un compte.
      </Paragraph>
      <Paragraph>
        En cas de résiliation de votre utilisation, votre compte et votre droit d’accès et
        d’utilisation du portail seront immédiatement résiliés. Vous comprenez que toute résiliation
        de votre compte peut entraîner la suppression du contenu publicitaire y étant associé de nos
        systèmes. Nous ne serons aucunement responsables envers vous en cas de résiliation de
        l’utilisation, y compris en cas de résiliation de votre compte ou de suppression de votre
        contenu publicitaire. Nos droits en vertu des présentes conditions générales d’utilisation
        survivront à la résiliation de votre compte.
      </Paragraph>
      <Paragraph>
        Nous pouvons modifier les présentes conditions générales d’utilisation à tout moment en
        publiant des révisions sur le portail sans préavis supplémentaire. Veuillez consulter
        régulièrement les conditions générales d’utilisation en vigueur. Votre accès et votre
        utilisation continus du portail constituent une acceptation des conditions générales
        d’utilisation modifiées. Si vous n’êtes pas d’accord avec les présentes conditions générales
        d’utilisation ou toute modification future, votre seul recours consiste à cesser d’utiliser
        le portail.
      </Paragraph>

      <Heading>Exonération de garantie</Heading>
      <Paragraph>
        AUCUNE DÉCLARATION, GARANTIE OU CAUTION N’EST FAITE CONCERNANT LA PORTÉE OU LA PERFORMANCE
        DE VOTRE CONTENU PUBLICITAIRE, Y COMPRIS, SANS S’Y LIMITER, LE FAIT QUE VOTRE CONTENU
        PUBLICITAIRE SERA VU PAR UN NOMBRE DONNÉ DE PERSONNES OU LE NOMBRE D’INTERACTIONS AVEC VOTRE
        CONTENU PUBLICITAIRE.
      </Paragraph>
      <Paragraph>
        LES INFORMATIONS SUR CE PORTAIL SONT FOURNIES « TELLES QUELLES » SANS AUCUNE GARANTIE
        D’AUCUNE SORTE. CADILLAC FAIRVIEW NE FAIT AUCUNE DÉCLARATION ET REJETTE TOUTES LES GARANTIES
        ET CONDITIONS EXPRESSES ET IMPLICITES DE QUELQUE NATURE QUE CE SOIT, Y COMPRIS, SANS S’Y
        LIMITER, LES DÉCLARATIONS, GARANTIES OU CONDITIONS DE QUELQUE NATURE QUE CE SOIT, Y COMPRIS
        LES DÉCLARATIONS, GARANTIES OU CONDITIONS (A) DE L’EXACTITUDE, DE L’OPPORTUNITÉ OU DE
        L’INTÉGRALITÉ DU CONTENU DU PORTAIL; (B) DE NON-VIOLATION, (C) DE QUALITÉ MARCHANDE, DE
        QUALITÉ SATISFAISANTE OU D’ADÉQUATION À UN USAGE PARTICULIER, (D) QUE LE PORTAIL OU LE
        CONTENU DU PORTAIL SERA ININTERROMPU, EXEMPT D’ERREURS OU DE COMPOSANTS NUISIBLES
      </Paragraph>

      <Heading>Limitation de la responsabilité</Heading>
      <Paragraph>
        EN AUCUN CAS, CADILLAC FAIRVIEW OU SES CONCÉDANTS DE LICENCE NE POURRONT ÊTRE TENUS
        RESPONSABLES DE TOUTE PERTE, BLESSURE, RÉCLAMATION, RESPONSABILITÉ OU TOUT DOMMAGE DE
        QUELQUE NATURE QUE CE SOIT RÉSULTANT DE VOTRE UTILISATION DU PORTAIL OU DE VOTRE INCAPACITÉ
        À L’UTILISER, OU DE TOUTE DÉPENDANCE OU EXÉCUTION DE TOUT MATÉRIEL CONTENU OU ACCESSIBLE À
        PARTIR DE CE PORTAIL. DANS LA MESURE OU LA LOI APPLICABLE LE PERMET, CADILLAC FAIRVIEW
        DÉCLINE TOUTE RESPONSABILITÉ POUR LES DOMMAGES INDIRECTS, ACCESSOIRES, CONSÉCUTIFS OU
        PUNITIFS OU DOMMAGES-INTÉRÊTS PARTICULIERS DE TOUTE NATURE (Y COMPRIS, SANS S’Y LIMITER, LES
        FRAIS JURIDIQUES ET LES DÉPENSES) RÉSULTANT OU DÉCOULANT DE VOTRE UTILISATION DU PORTAIL OU
        DE VOTRE CONTENU PUBLICITAIRE (MÊME SI NOUS LE PUBLIONS), Y COMPRIS LA VIOLATION DES DROITS
        DE PROPRIÉTÉ INTELLECTUELLE, LES INFORMATIONS INCORRECTES ET LES FAUSSES DÉCLARATIONS. EN
        TOUTES CIRCONSTANCES, ENSEMBLE, NOTRE RESPONSABILITÉ ET CELLE DE NOS SOCIÉTÉS AFFILIÉES ET
        DE NOS CONCÉDANTS EN VERTU DES PRÉSENTES CONDITIONS GÉNÉRALES D’UTILISATION NE DÉPASSERONT
        PAS CINQUANTE DOLLARS CANADIENS (50 $).
      </Paragraph>

      <Heading>Dédommagement</Heading>
      <Paragraph>
        En utilisant le portail, vous et le détaillant que vous représentez acceptez de défendre,
        d’indemniser et de dégager de toute responsabilité Cadillac Fairview et ses sociétés
        affiliées, employés, dirigeants, administrateurs, entrepreneurs, représentants et agents
        contre toute réclamation et responsabilité et coûts et dépenses engagés par un tiers, y
        compris, sans s’y limiter, les frais juridiques raisonnables et autres frais juridiques
        découlant de votre utilisation du portail ou liés à celle-ci, y compris les activités menées
        depuis votre compte, ou à toute violation présumée des présentes conditions générales
        d’utilisation de votre part ou de toute personne en votre nom.
      </Paragraph>

      <Heading>Assurances</Heading>
      <Paragraph>
        Le détaillant doit, à ses frais, obtenir et maintenir en vigueur tout au long de la période
        d’affichage publicitaire sur les propriétés de Cadillac Fairview, et toute prolongation ou
        tout renouvellement de celle-ci, une assurance dont les montants sont adéquats pour tous les
        risques normalement assurés par un détaillant qui affiche une publicité, y compris, sans s’y
        limiter, une assurance responsabilité civile générale complète et une assurance
        responsabilité civile des médias complète. Le détaillant doit fournir une preuve
        satisfaisante de l’assurance requise dans les cinq jours ouvrables suivant la demande de
        Cadillac Fairview.
      </Paragraph>

      <Heading>Loi applicable</Heading>
      <Paragraph>
        Les présentes conditions générales d’utilisation sont régies et seront interprétées
        conformément aux lois de la province de l’Ontario et des lois du Canada qui s’y appliquent,
        sans égard aux principes touchant les conflits de lois. Toute action en application des
        présentes conditions générales d’utilisation peut être intentée exclusivement devant les
        tribunaux de la ville de Toronto, province de l’Ontario.
      </Paragraph>

      <Heading>Interprétation</Heading>
      <Paragraph>
        Les présentes conditions générales d’utilisation constituent l’intégralité de l’entente
        conclue entre vous et Cadillac Fairview concernant votre utilisation du portail. Si l’une
        des dispositions des présentes conditions générales d’utilisation est jugée non valide ou
        inapplicable, cette disposition sera supprimée et toutes les autres dispositions demeureront
        en vigueur. Nonobstant toute autre disposition des présentes conditions générales
        d’utilisation, toute disposition des présentes conditions générales d’utilisation qui impose
        ou envisage des droits ou obligations continus à votre égard ou à notre égard survivra à
        l’expiration ou à la résiliation des présentes conditions générales d’utilisation, y
        compris, sans s’y limiter, les dispositions d’indemnisation et de limitation de la
        responsabilité.
      </Paragraph>

      <Heading>
        Politique de publicité CF régissant les offres sur le portail des détaillants de CF 2.0
      </Heading>
      <Paragraph>
        Les titulaires de compte du Portail des détaillants de CF sont considérés comme étant des
        titulaires d’une licence des systèmes publicitaires de CF.
      </Paragraph>
      <Paragraph>
        Le titulaire d’une licence devra s’assurer que toute publicité affichée sur l’écran
        électronique est complémentaire au centre commercial et favorable à l’environnement de
        magasinage du centre commercial. Toute publicité doit être de première qualité et conforme
        aux normes de professionnalisme utilisées par les titulaires d’une licence du centre
        commercial et les annonceurs nationaux et régionaux au Canada. Les catégories suivantes en
        matière de publicité ne seront pas acceptées par le titulaire d’une licence de la part
        d’annonceurs et/ou d’agences de publicité proposés, sans le consentement écrit préalable du
        concédant, qui peut être refusé ou retardé de façon déraisonnable :
        <ol>
          <li>publicité de services publics;</li>
          <li>publicité politique;</li>
          <li>publicité d’intérêt spécial;</li>
          <li>publicité à caractère religieux;</li>
          <li>
            matériel publicitaire de détaillants ou de services/promoteurs
            immobiliers/bars/restaurants qui est directement en concurrence avec les activités de
            promotion et de gestion immobilières du concédant de la licence (à l’exclusion, pour
            plus de certitude, de toute activité publicitaire ou de celle d’une partie qui exécute
            un contrat avec le concédant à cet égard) et/ou des détaillants, services, bars et/ou
            restaurants menant des activités au sein du centre commercial;{' '}
          </li>
          <li>
            matériel publicitaire préjudiciable (en matière de race, de croyance, de religion, de
            sexe, d’origine ethnique et/ou d’orientation sexuelle), clandestin, impliquant une
            nudité totale ou partielle ou qui, de manière objective, serait offensant ou violerait
            les normes communautaires;
          </li>
          <li>publicité sur le tabac et la marijuana.</li>
        </ol>
      </Paragraph>
      <Paragraph>
        De l’avis du concédant, la publicité ne peut en aucun cas être contraire aux intérêts du
        centre commercial, du concédant ou de tout titulaire d’une licence ou occupant du centre
        commercial. À des fins de clarification, une publicité serait contraire aux intérêts du
        centre commercial, du concédant, du titulaire d’une licence ou des occupants du centre
        commercial si elle les discréditait ou discréditait leurs produits et/ou services de quelque
        façon que ce soit. Le titulaire d’une licence ne doit pas autoriser la publicité qui fait la
        promotion ou la publicité par son nom de tout autre centre commercial autre que le centre
        commercial ou qui fait la publicité d’entreprises concurrentes ou qui n’est pas propre à
        chaque centre commercial. Le titulaire d’une licence doit, dans les 24 heures suivant la
        demande, retirer toute publicité que le concédant, à son entière discrétion, demande qu’elle
        soit retirée, agissant raisonnablement.
      </Paragraph>
      <Paragraph>
        Sans limiter ce qui précède, le concédant se réserve le droit d’exiger du titulaire d’une
        licence que ce dernier supprime toute publicité (i) jugée inacceptable compte tenu de son
        caractère controversé ou offensant, ou (ii) qui viole ou peut violer toute exclusivité
        donnée au concédant (ou au propriétaire des centres commerciaux) dans le cadre de son
        programme national de partenariat et de commandite. Le titulaire d’une licence doit retirer
        ces publicités dans les 24 heures suivant la réception d’une demande écrite ou d’un courriel
        du concédant.
      </Paragraph>
    </div>
  );
}

function ToSPageEn() {
  return (
    <div>
      <Paragraph>Last updated: {getLastUpdated()}</Paragraph>

      <Heading>Terms of Use</Heading>
      <Paragraph>
        The following Terms of Use applies to the access and use of this Portal and the associated
        self-serve advertising services provided by The Cadillac Fairview Corporation Limited. These
        rules affect the legal rights of the user of the Portal and any Retailer that the user
        represents and impose obligations on the user and Retailer. Every time you access and use
        the Portal, you agree on your own behalf, and on behalf of the Retailer you represent, to be
        bound by the then-current version of the Terms of Use. If you do not agree with the Terms of
        Use, your sole recourse is to discontinue use of the Portal.
      </Paragraph>
      <Paragraph>
        Please review our Privacy Policy for details regarding the collection, use, retention,
        disclosure, and management of personal information that is collected through the Portal. The
        Privacy Policy forms part of the Terms of Use.
      </Paragraph>

      <Heading>Definition</Heading>
      <Paragraph>
        <strong>Portal</strong> means the website operated by Cadillac Fairview and all of its
        subdomains where users (Retailers or any person on their behalf) log in to place an
        advertising order.
      </Paragraph>
      <Paragraph>
        <strong>Portal Content</strong> means content provided by Cadillac Fairview on the Portal,
        including text, data, graphics, photographs, images, audio, video, trademarks, service
        marks, trade names and other information, visual or other digital material, and all other
        content or any description available on the Portalor available via a link from the Portalto
        a page created by Cadillac Fairview.
      </Paragraph>
      <Paragraph>
        <strong>Ad Content</strong> means content uploaded to the Portal by the user, including
        text, graphics, photographs, images, screen shots, messages, conversations, trademarks,
        logos, names, slogans, articles, data, videos, and the compilation of the foregoing.
      </Paragraph>
      <Paragraph>
        <strong>Ad Services</strong> means functionalities on the Portal that allow your Ad Content
        to be served on properties (e.g., websites, mobile applications, and displays) of Cadillac
        Fairview and enabled third parties.
      </Paragraph>
      <Paragraph>
        <strong>Intellectual Property Rights</strong> means unpatented inventions, patent
        applications, patents, design rights, copyrights, trademarks, service marks, trade names,
        domain name rights, mask work rights, know-how and other trade secret rights, and all other
        intellectual property rights, derivatives thereof, and forms of protection of a similar
        nature anywhere in the world.
      </Paragraph>
      <Paragraph>
        <strong>Retailer</strong> means the legal or natural person whose Ad Content is being
        submitted for publication.
      </Paragraph>
      <Paragraph>
        The words “<strong>user,</strong>” “<strong>you</strong>” and “<strong>your</strong>” mean
        users of the Portal and the Retailer who the user of the Portal represents, and the words “
        <strong>we,</strong>” “<strong>our</strong>” and “<strong>us</strong>” mean The Cadillac
        Fairview Corporation Limited.
      </Paragraph>

      <Heading>Registration</Heading>
      <Paragraph>
        Use of the Portal requires registration and create a user profile (“Account”). By
        registering, you represent and warrant that you are of legal age of majority in your
        province of residence. During the registration process, you will be asked to enter your name
        and valid e-mail address and choose a strong password. You represent and warrant that you
        maintain the currency, completeness, and accuracy of your registration data.
      </Paragraph>

      <Heading>Privacy</Heading>
      <Paragraph>
        We may collect, use, retain, disclose and otherwise process your personal information in
        accordance with the Cadillac Fairview Privacy Policy. By using the Portal, you agree to the
        provisions of the Cadillac Fairview Privacy Policy. If you do not agree with the Privacy
        Policy, you must discontinue use of the Portal.
      </Paragraph>

      <Heading>Password and Security</Heading>
      <Paragraph>
        You must select a strong and unique password, and maintain the confidentiality of your
        password and account. You further agree not to email, post, or otherwise disseminate any
        user ID, password, or other information which provides you access to the Portal. You must
        not re-use a password that you use for other websites or services. You are responsible for
        any and all activities that occur under your account. You agree to notify Cadillac Fairview
        immediately of any unauthorized use of your account. Cadillac Fairview is not liable for any
        loss that you may incur as a result of someone else using your password or account, either
        with or without your knowledge. If you suspect your login credentials have been compromised,
        you must notify us immediately. Cadillac Fairview is not responsible for any delay in
        shutting down your account after you have reported a breach of security to us.
      </Paragraph>

      <Heading>Authorized Use</Heading>
      <Paragraph>
        If you are a registered user of the Portal and provided that you comply with these Terms of
        Use, you can use our Ad Services on the Portal to submit Ad Content. Our Advertising
        Policies provide guidance on what types of ad content are allowed.
      </Paragraph>

      <Heading>Prohibited Use</Heading>
      <Paragraph>
        You are responsible for knowing and obeying all applicable laws relating to the Ad Services
        and your use of the Portal. By using it, you represent and warrant that your use complies
        with applicable laws.
      </Paragraph>
      <Paragraph>
        You will not use the Portal in any manner that:
        <ol>
          <li>
            infringes or violates the rights of any other party (including but not limited to
            trademark, patent, copyright, publicity or other proprietary rights);
          </li>
          <li>
            is unlawful, fraudulent or deceptive, or harmful, threatening, abusive, harassing,
            tortious, defamatory, vulgar, obscene, libelous, invasive of another’s privacy, hateful,
            racially, ethnically or otherwise objectionable;
          </li>
          <li>
            constitutes unsolicited or unauthorized advertising, promotional materials, “junk mail,”
            “spam,” “chain letters,” “pyramid schemes,” or any other form of solicitation;
          </li>
          <li>
            is for the purpose of misleading others, causes the creation of a false identity of the
            sender or the origin of a message, forges headers or otherwise manipulates identifiers
            in order to disguise the origin of any material transmitted through the Portal;
          </li>
          <li>
            impersonates any person or entity, including, but not limited to, a Cadillac Fairview
            employee, or falsely state or otherwise misrepresent your affiliation with a person or
            entity;
          </li>
          <li>causes the upload of any content that contains any form of destructive software;</li>
          <li>
            uses any robots, spiders, or similar data mining tool to access, acquire, copy, or
            monitor any portion of the Portal;
          </li>
          <li>
            copies, modifies, creates a derivative work of, reverse engineers, decompiles, or
            otherwise attempts to extract the source code of any proprietary software used to
            provide, maintain, or otherwise applicable to the Portal;
          </li>
          <li>
            attempts to gain unauthorized access to the Portal, other computer systems or networks
            connected to the Portal;
          </li>
          <li>
            takes any action that imposes or may impose (as determined by us in our sole discretion)
            an unreasonable or disproportionately large load on our (or our third-party providers’)
            infrastructure;
          </li>
          <li>
            interferes with or disrupt networks or servers connected to the Portal; or facilitates
            or encourage any of the foregoing conduct.
          </li>
        </ol>
      </Paragraph>

      <Heading>Ad Content Submissions</Heading>
      <Paragraph>
        Our Advertising Policies provide guidance on what types of ad content may be submitted.
        However, Cadillac Fairview may accept or decline submissions in its sole discretion. If your
        Ad Content submission is accepted, we will determine the size, placement, and positioning of
        your ads on our properties. We may also modify the Ad Content for the purpose of publishing
        the ad. See Content Ownership below for details.
      </Paragraph>

      <Heading>Portal Content Ownership</Heading>
      <Paragraph>
        Portal Content is the sole property of Cadillac Fairview and is protected by Canadian and
        international copyright, trade-mark, service marks, patents, trade secrets and other
        intellectual property rights and laws. You agree that you will not copy, attempt to reverse
        engineer, modify, translate or disassemble the Portal software in whole or in part,
        including any attempts to reconstruct, identify, or discover any source code, underlying
        ideas, underlying user interface techniques, or algorithms of the Portal. You may not use or
        export or re-export the Portal except as permitted under the laws of Canada and the United
        States and the laws of the jurisdiction in which access to the Portal was obtained. You may
        not copy, reproduce, perform, distribute, display, modify, translate, merge with other data,
        frame in another website, post on another website or create derivative works of the Portal
        software or the Portal Content, in whole or in part, for the purpose of creating a competing
        product or service or for resale or distribution.
      </Paragraph>

      <Heading>Ad Content Ownership</Heading>
      <Paragraph>
        By uploading any Ad Content on or through the Portal, you hereby grant (on behalf of the
        owner of the Ad Content) to Cadillac Fairview, its affiliates, and their service providers,
        representatives and agents a non-exclusive, sub-licensable (solely for displaying the Ad
        Content), worldwide, fully-paid, perpetual and irrevocable, and royalty free license to
        copy, use, modify, publicly perform, publicly display, remove, delete, reproduce, and
        distribute such Ad Content in accordance with the insertion order instructions without the
        requirement to make payment to the owner or to any third party or the need to seek any
        further permission from the owner or any third party. This license includes the right to
        host, index, cache, distribute, and tag any Ad Content. In addition, you waive (on behalf of
        the creator of the Ad Content) all moral rights in and to all Ad Content that you display,
        publish, or otherwise post on or through the Portal in favour of Cadillac Fairview. Except
        for the limited licence granted in these Terms of Use, the owner and creator of the Ad
        Content continues to retain all of your ownership rights in your Ad Content.
      </Paragraph>

      <Heading>Representations and Warranties</Heading>
      <Paragraph>
        Users of the Portal who are placing Ad Content on a Retailer’s behalf represent and warrant
        that they have the authority to and will bind the Retailer to these Terms of Use.
      </Paragraph>
      <Paragraph>
        You represent and warrant that: (a) the Ad Content is accurate and complies with all
        applicable laws, regulations, and guidelines, as well as our{' '}
        <strong>Advertising Policies</strong>; (b) you have the right to submit the Ad Content and
        that you have the right to grant the license set forth in these Terms of Use; (c) the
        displaying, publishing or posting of the Ad Content you submit, and Cadillac Fairview’s use
        of the Ad Content does not and will not violate the privacy rights, publicity rights,
        copyrights, trademark rights, patents, contract rights or any other intellectual property
        rights or other rights of any person or entity.
      </Paragraph>

      <Heading>Changes and Termination</Heading>
      <Paragraph>
        We reserve the right at any time and from time to time to modify or discontinue, temporarily
        or permanently, the Portal (or any part thereof) with or without notice even if you have
        created an Account.
      </Paragraph>
      <Paragraph>
        Upon termination of use, your Account and right to access and use the Portal will terminate
        immediately. You understand that any termination of your Account may involve deletion of
        your Ad Content associated therewith from our systems. We will not have any liability
        whatsoever to you for any termination of use, including for termination of your Account or
        deletion of your Ad Content. Our rights under these Terms of Use will survive any
        termination of your Account.
      </Paragraph>
      <Paragraph>
        We may amend these Terms of Use at any time by posting revisions on the Portal with no
        additional notice to you. Please check back frequently to see the Terms of Use then in
        effect. Your continued access to and use of the Portal will constitute acceptance of the
        amended Terms of Use. If you do not agree with these Terms of Use, or any future amendments,
        your sole recourse is to cease use of the Portal.
      </Paragraph>

      <Heading>Disclaimer of Warranty</Heading>
      <Paragraph>
        WE DISCLAIM ANY REPRESENTATION, WARRANTY OR GUARANTEE REGARDING THE REACH OR PERFORMANCE OF
        YOUR AD CONTENT, INCLUDING, WITHOUT LIMITATION, THAT YOUR AD CONTENT WILL BE SEEN BY ANY
        PARTICULAR NUMBER OF PEOPLE OR THE NUMBER OF INTERACTIONS WITH YOUR AD CONTENT.
      </Paragraph>
      <Paragraph>
        THE INFORMATION ON THIS PORTAL IS PROVIDED “AS IS” WITHOUT ANY WARRANTIES OF ANY KIND.
        CADILLAC FAIRVIEW MAKES NO REPRESENTATION AND DISCLAIMS ALL EXPRESS AND IMPLIED WARRANTIES
        AND CONDITIONS OF ANY KIND, INCLUDING WITHOUT LIMITATION, REPRESENTATIONS, WARRANTIES OR
        CONDITIONS OF ANY KIND, INCLUDING ANY REPRESENTATIONS, WARRANTIES OR CONDITIONS (A) OF THE
        ACCURACY, TIMELINESS OR COMPLETENESS OF THE PORTAL CONTENT; (B) OF NON-INFRINGEMENT, (C) OF
        MERCHANTABILITY, SATISFACTORY QUALITY, OR FITNESS FOR A PARTICULAR PURPOSE, (D) THAT THE
        PORTAL OR THE PORTAL CONTENT WILL BE UNINTERRUPTED, ERROR FREE, OR FREE OF HARMFUL
        COMPONENTS.
      </Paragraph>

      <Heading>Limitation of Liability</Heading>
      <Paragraph>
        UNDER NO CIRCUMSTANCES WILL CADILLAC FAIRVIEW OR ITS LICENSORS BE LIABLE FOR ANY LOSS,
        INJURY, CLAIM, LIABILITY OR DAMAGE OF ANY KIND RESULTING FROM YOUR USE OF, OR INABILITY TO
        USE, THE PORTAL, OR ANY RELIANCE UPON OR PERFORMANCE OF ANY MATERIAL CONTAINED IN OR
        ACCESSED FROM THIS PORTAL. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, CADILLAC
        FAIRVIEW DISCLAIMS ALL RESPONSIBILITY FOR INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
        PUNITIVE DAMAGES OF ANY KIND (INCLUDING, WITHOUT LIMITATION, LEGAL FEES AND EXPENSES)
        RESULTING FROM, ARISING OUT OF OR IN ANY WAY RELATED TO YOUR USE OF THE PORTAL OR YOUR AD
        CONTENT (EVEN IF WE PUBLISH IT), INCLUDING VIOLATION OF INTELLECTUAL PROPERTY RIGHTS,
        INCORRECT INFORMATION, AND MISREPRESENTATION. IN ALL CIRCUMSTANCES, OUR AND OUR AFFILIATES’
        AND LICENSORS’ AGGREGATE LIABILITY UNDER THESE TERMS OF USE SHALL NOT EXCEED FIFTY CANADIAN
        DOLLARS ($50)
      </Paragraph>

      <Heading>Indemnification</Heading>
      <Paragraph>
        By using the Portal, you and the Retailer you represent agree to defend, indemnify and hold
        harmless Cadillac Fairview and its affiliates, employees, officers, directors, contractors,
        representatives, and agents from any and all third party claims, liabilities, costs and
        expenses, including, without limitation, reasonable legal fees and other legal expenses,
        arising out of or relating to your use of the Portal, including activities under your
        Account, or any alleged violation by you or any person on your behalf of these Terms of Use.
      </Paragraph>

      <Heading>Insurance</Heading>
      <Paragraph>
        The Retailer must, at its expense, obtain and maintain in force throughout the period of
        advertisement display on Cadillac Fairview properties, and any extensions or renewals
        thereof, insurance in adequate amounts for all risks normally insured against by a Retailer
        placing advertisement on display, including without limitation comprehensive General
        Liability Insurance and Media Liability Insurance. The Retailer shall provide satisfactory
        evidence of required insurance within five (5) business days of Cadillac Fairview’s request.
      </Paragraph>

      <Heading>Governing Law</Heading>
      <Paragraph>
        These Terms of Use are governed by and will be interpreted in accordance with the laws of
        the Province of Ontario and of the laws of Canada applicable therein, without regard to any
        principles of conflicts of law. Any action to enforce these Terms of Use may be brought
        exclusively in the courts located in the City of Toronto, Province of Ontario.
      </Paragraph>

      <Heading>Interpretation</Heading>
      <Paragraph>
        These Terms of Use constitute the entire agreement between you and Cadillac Fairview with
        respect to your use of the Portal. If any provision of these Terms of Use is held to be
        invalid or unenforceable, such provision will be stricken and the remaining provisions
        enforced. Notwithstanding any other provisions of these Terms of Use, any provision of these
        Terms of Use that imposes or contemplates continuing rights or obligations on you or us will
        survive the expiration or termination of these Terms of Use, including, without limitation,
        the indemnification and limitation of liability provisions.
      </Paragraph>

      <Heading>CF Advertising Policy specific to Offers on the CF Retail Portal 2.0</Heading>
      <Paragraph>
        CF Retail Portal Account holders are considered Licensees of CF Advertising systems
      </Paragraph>
      <Paragraph>
        The Licensee shall ensure that all advertising displayed on the Electronic Screen is
        complementary to the Shopping Centre and conducive to the shopping environment of the
        Shopping Centre. All advertising shall be of first class quality and in keeping with the
        standard of professionalism used by the Licensees of the Shopping Centre and national and
        regional advertisers in Canada. The following categories in respect of of advertising will
        not be accepted by the Licensee from proposed advertisers and/or advertising agencies
        without the prior written consent of the Licensor which consent may be unreasonably withheld
        or delayed:
        <ol>
          <li>public service advertising;</li>
          <li>political advertising;</li>
          <li>special interest advertising;</li>
          <li>advertising which is religious in nature;</li>
          <li>
            advertising material of retailers or services/developers/bars/restaurants which are
            directly competitive with the Licensor’s development/management business (but excluding
            for greater certainty, any advertising business or that of any party contracting with
            the Licensor in respect of same) and/or retailers, services, bars and/or restaurants
            operating within the Shopping Centre;{' '}
          </li>

          <li>
            advertising material which is prejudicial (with respect to race, creed, religion,
            gender, ethnic origin and/or sexual orientation), lewd, involves full or partial nudity
            or which would, on an objective basis, be offensive to or would violate community
            standards; and
          </li>
          <li>tobacco and marijuana advertising</li>
        </ol>
      </Paragraph>
      <Paragraph>
        The advertising shall not in any way be adverse to the interests of the Shopping Centre, the
        Licensor or any licensee or occupant of the Shopping Centre, in the sole opinion of the
        Licensor. For clarification, an advertisement would be adverse to the interests of the
        Shopping Centre, Licensor, licensees or occupants of the Shopping Centre if it in any way
        discredited them, their products and/or services. The Licensee shall not permit advertising
        that promotes or advertises by name any other shopping centre other than the Shopping Centre
        or advertises businesses that compete with or are not specific to each Shopping Centre. The
        Licensee shall remove within 24 hours after the Licensor’s request any advertising which the
        Licensor in its sole discretion requires be removed, acting reasonably.
      </Paragraph>
      <Paragraph>
        Without limiting the foregoing, the Licensor reserves the right to require the Licensee to
        remove any advertisements which (i) it deems unacceptable as being controversial or
        offensive, or (ii) breaches, or could breach, any exclusivity given by the Licensor (or
        owner of the Shopping Centres) as part of its national sponsorship/partnership program. The
        Licensee will remove any such advertising within 24 hours of receipt of Licensor’s written
        request or by email.
      </Paragraph>
    </div>
  );
}
function ToSPage() {
  const { locale } = useAppContext();
  const { t } = useTranslation();

  return (
    <Layout
      pageTitle={t('metadata:tosPage.pageTitle')}
      pageDescription={t('metadata:tosPage.description')}
    >
      <Container className="my-5">
        <Text tag="h1" type="h1" className="mb-3">
          {t('terms_of_service')}
        </Text>
        {locale === Locale.en ? <ToSPageEn /> : <ToSPageFr />}
      </Container>
    </Layout>
  );
}

export default ToSPage;
