import common from './common.json';
import error from './errors.json';
import metadata from './metadata.json';

const translations = {
  common,
  error,
  metadata,
};

export default translations;
