import { omitBy, isUndefined, isNil } from 'lodash-es';
import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import Status from 'constants/Status';
import { Offer } from 'types';
import { useFullPageLoaderContext } from 'context/full-page-loader-context';
import { useOfferRequester } from 'context/requesters/offer';
import { ModalType } from 'constants/ModalType';
import OfferQuery from 'constants/OfferQuery';
import { useModalContext } from 'context/modal-context';
import { t } from 'i18next';
import { useRouter } from 'hooks';
import {
  getInitValues,
  toServerPayload,
  transformServerOffer,
  validateOfferForm,
} from './offer-form-helpers';
import UnitType from 'types/unit.type';
import { ValidationError } from 'helpers/validator';

type OfferActionType = {
  submitReview: boolean;
  saveDraft: boolean;
  delete: boolean;
};

export enum OfferType {
  storewide = 'Storewide',
  productSpecific = 'Product Specific',
}

export type OfferState = {
  isMultilingual: boolean;
  startDateTime: string;
  endDateTime: string;
  publishDateTime: string;
  type: OfferType;
  locations: string[];
  categories: string[];
  keywords: string[];
  keywordsFrench: string[];
  headline: string;
  headlineFrench: string;
  description: string;
  descriptionFrench: string;
  image: File | null;
  imageFrench: File | null;
  promotionCode: string;
  __action?: 'draft' | 'submit'; // For handling different buttons
};

function useOfferForm(offer?: Offer, unitMap: Record<string, UnitType> = {}) {
  const { setAndShowModal, setShowModal } = useModalContext();
  const { handleLoading } = useFullPageLoaderContext();
  const offerService = useOfferRequester();
  const router = useRouter();

  const formik = useFormik<OfferState>({
    validate: async (values) => {
      if (values.__action === 'draft') return {};
      const validation = await validateOfferForm(values, offer, unitMap);
      return omitBy(validation, isNil);
    },
    onSubmit: async (values, { setFieldValue }) => {
      setFieldValue('__action', undefined, false);
      if (!offerService) return;

      const data = toServerPayload(values, formik.initialValues);
      const action = values.__action;
      const status = action === 'submit' ? Status.PendingReview : Status.Draft;
      const load = async () => {
        let res = null;
        if (offer) {
          res = await offerService.edit(offer.sys.id, omitBy(data, isUndefined), status);
        } else {
          res = await offerService.create(
            omitBy(
              {
                ...data,
                type: data.type, // Apply this as part of init
              },
              isUndefined
            ),
            status
          );
        }
        return res;
      };

      await handleLoading(load());

      if (action === 'submit') {
        setAndShowModal({
          type: ModalType.Generic,
          title: t('submission_sent') as string,
          body: t('review_notification') as string,
          handleClose: () => {
            setShowModal(false);
            router.toBrowseOffersPage(OfferQuery.review);
          },
          handleAccept: () => {
            setShowModal(false);
            router.toBrowseOffersPage(OfferQuery.review);
          },
          confirmButtonText: t('continue'),
        });
      } else {
        router.toBrowseOffersPage(OfferQuery.draft);
      }
    },
    initialValues: {
      ...getInitValues(),
      __action: undefined,
    },
  });

  const { resetForm, errors, setFieldValue } = formik;
  useEffect(() => {
    if (!offer) return;
    let mounted = true;
    (async () => {
      const offerState = await transformServerOffer(offer);
      if (!mounted) return;
      resetForm({
        values: {
          ...getInitValues(),
          ...offerState,
          __action: undefined,
        },
      });
    })();
    return () => {
      mounted = false;
    };
  }, [offer, resetForm]);

  // If french property set, set multilingual
  useEffect(() => {
    if (errors.locations === ValidationError.requiresFrench) {
      setFieldValue('isMultilingual', true);
    }
  }, [errors.locations, setFieldValue]);

  const status = offer?.status;
  const offerActions = useMemo(
    (): OfferActionType => ({
      submitReview: true,
      saveDraft: [
        Status.Draft,
        Status.PendingReview,
        Status.Declined,
        undefined, // For new offers
      ].includes(status),
      delete: status ? [Status.Draft, Status.PendingReview].includes(status) : false,
    }),
    [status]
  );

  return {
    offerActions,
    formik,
  };
}

export default useOfferForm;
