import Layout from 'components/layout/layout';
import { useTranslation, useRouter } from '../../hooks';
import { Button, Text } from 'components';
import styles from './ForgotPassword.module.scss';
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm';
import { useState } from 'react';

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [email, setEmail] = useState('');

  return (
    <Layout
      pageTitle={t('metadata:forgotPasswordPage.pageTitle')}
      pageDescription={t('metadata:forgotPasswordPage.description')}
      showTapestryLeft
      backgroundColor="pale-blue"
    >
      <div className={styles.forgotPasswordPage}>
        <div className={styles.widgetWrapper}>
          {email ? (
            <div className={styles.thankYou}>
              <div>
                <Text type="h2" tag="h1" typeMobile="h1" className={styles.heading}>
                  {t('forgotPassword.success.title')}
                </Text>
                <Text color="cf-dark-grey" tag="p" className={styles.text}>
                  {t('forgotPassword.success.description', { email })}
                </Text>
              </div>
              <Button type="button" size="stretch" label="back" onClick={router.toLoginPage}>
                {t('forgotPassword.back')}
              </Button>
            </div>
          ) : (
            <>
              <Text type="h2" tag="h1" typeMobile="h1" className={styles.heading}>
                {t('forgotPassword.title')}
              </Text>
              <Text color="cf-dark-grey" tag="p" className={styles.text}>
                {t('forgotPassword.description')}
              </Text>
              <ForgotPasswordForm setSubmit={setEmail} />
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};
export default ForgotPasswordPage;
