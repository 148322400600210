import { Tooltip as BsTooltip, OverlayTrigger } from 'react-bootstrap';
import Icon from '../rp-icon/rp-icon';

const Tooltip = ({ message }: TooltipProps) => {
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 0, hide: 400 }}
      overlay={<BsTooltip id="button-tooltip">{message}</BsTooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <button
          style={{ border: 'none', background: 'none', padding: '0' }}
          type="button"
          {...triggerHandler}
        >
          <Icon name="question" alt="checked icon" height="16" width="16" ref={ref} />
        </button>
      )}
    </OverlayTrigger>
  );
};

interface TooltipProps {
  message: string;
}

export default Tooltip;
