import { useHistory, useParams } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import cx from 'classnames';
import { Button, Icon, Text } from 'components';
import Paths from 'constants/Paths';
import handleError from 'helpers/handleError';
import { UnitService, UserService } from 'services';
import UserType from 'types/user.type';
import { useTranslation } from 'hooks';
import styles from './ApproveUserPanel.module.scss';
import InputToken from 'components/form/input-token';
import UnitType from 'types/unit.type';
import { useModalContext } from 'context/modal-context';
import { ModalType } from 'constants/ModalType';
import { OktaUserStatus } from 'constants/User';

interface ApproveUserPanelProps {
  onUpdate: (id: string, payload: UserType | null) => Promise<void>;
}

function ApproveUserPanel({ onUpdate }: ApproveUserPanelProps) {
  const accessToken = useOktaAuth().authState?.accessToken?.accessToken;
  const { setAndShowModal, setShowModal } = useModalContext();
  const [units, setUnits] = useState<UnitType[]>([]);
  const [user, setUser] = useState<UserType | null>();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const history = useHistory();

  const dateFormat = new Intl.DateTimeFormat('default', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  useEffect(() => {
    const abortController = new AbortController();

    handleError(
      (async () => {
        const user = await UserService.findById(id, accessToken, abortController.signal);
        const units = await UnitService.getUnitsByIds(user.profile.units ?? [], accessToken);
        if (abortController.signal.aborted) return;
        setUnits(units);
        setUser(user);
      })(),
      () => {
        history.replace(Paths.userManagement);
      }
    );

    return () => {
      abortController.abort();
      setUser(null);
    };
  }, [accessToken, id, history]);

  const handleAcceptUser = async () => {
    if (!user) return;
    await UserService.activate(id, accessToken);
    await onUpdate(id, { ...user, status: OktaUserStatus.PROVISIONED });

    setAndShowModal({
      type: ModalType.Generic,
      title: t('user_request_panel.accept_confirmation_title'),
      body: t('user_request_panel.accept_confirmation_body', { email: user?.profile.email }),
      handleClose: () => {
        setShowModal(false);
      },
      handleAccept: () => {
        setShowModal(false);
        history.push(Paths.userManagement);
      },
      confirmButtonText: t('user_request_panel.accept_confirmation_confirm'),
    });
  };

  const handleDenyUser = () => {
    setAndShowModal({
      type: ModalType.DenyUser,
      id,
      onSuccess: async () => {
        await onUpdate(id, null);
        setShowModal(false);
        history.push(Paths.userManagement);
      },
    });
  };

  if (!user) {
    return (
      <div className="p-4 p-sm-5">
        <div className="d-flex align-items-center">
          <Spinner className="mr-3" animation="border" />
          <Text type="h4" tag="h2">
            {t('user_request_panel.loading')}
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div className={cx(styles.container, 'd-flex flex-column')}>
      <div className="p-4 p-sm-5">
        <div>
          <Button
            onClick={() => history.push(Paths.userManagement)}
            className="mb-4 d-md-none"
            variant="outline"
            size="small"
          >
            <Icon className={styles.backIcon} name="caretBlue" ariaHidden alt="" />
            {t('back')}
          </Button>
        </div>
        <Text type="h4" tag="h2">
          {t('user_request_panel.title')}
        </Text>
        <Text type="body" tag="p" color="cf-dark-grey">
          {t('user_request_panel.requested', { date: dateFormat.format(new Date(user.created)) })}
        </Text>
        <div className="mt-4">
          <Text type="h6" tag="p">
            {t('form.firstName')}
          </Text>
          <Text type="body" tag="p" color="cf-dark-grey">
            {user.profile.firstName}
          </Text>
        </div>
        <div className="mt-4">
          <Text type="h6" tag="p">
            {t('form.lastName')}
          </Text>
          <Text type="body" tag="p" color="cf-dark-grey">
            {user.profile.lastName}
          </Text>
        </div>
        <div className="mt-4">
          <Text type="h6" tag="p">
            {t('form.email')}
          </Text>
          <Text type="body" tag="p" color="cf-dark-grey">
            {user.profile.email}
          </Text>
        </div>
        <div className="mt-4">
          <Text type="h6" tag="p">
            {t('form.langPref')}
          </Text>
          <Text type="body" tag="p" color="cf-dark-grey">
            {user.profile.preferredLanguage}
          </Text>
        </div>
        <div className="mt-4">
          <Text type="h6" tag="p">
            {t('form.stores')} ({units.length})
          </Text>
          <InputToken
            className={styles.tokens}
            tokens={units.map(
              (unit) => `${unit.webStoreName} • ${unit.fno.name} - ${unit.property.webPropertyName}`
            )}
            readOnly
          />
        </div>
      </div>
      <div
        className={cx(
          styles.footer,
          'p-4 px-sm-5 d-flex flex-column flex-md-row align-items-stretch align-items-md-center justify-content-end'
        )}
      >
        <Button onClick={handleAcceptUser}>{t('user_request_panel.accept')}</Button>
        <Button onClick={handleDenyUser} variant="outline" theme="secondary">
          {t('user_request_panel.decline')}
        </Button>
      </div>
    </div>
  );
}

export default ApproveUserPanel;
