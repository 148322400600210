import React from 'react';
import { Text, TextInput } from 'components';
import useTranslation from 'hooks/useTranslation';

function TextField(props: {
  label: string;
  value: string;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onBlur(e: React.ChangeEvent<HTMLInputElement>): void;
  error?: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  errorText?: string; // overwrites error, maps to error type e.g MISSING
  required?: boolean;
}) {
  const { t } = useTranslation(['common', 'error']);

  return (
    <div className={props.className}>
      <label>
        <Text type="body" color="cf-dark-grey">
          {t(`form.${props.label}`)}

          <TextInput
            name={props.label}
            type="text"
            error={!!props.error}
            errorText={props.errorText || props.error}
            value={props.value}
            label={props.label}
            onBlur={props.onBlur}
            onChange={props.onChange}
            placeholder={props.placeholder}
            disabled={props.disabled}
            required={!!props.required}
          />
        </Text>
      </label>
    </div>
  );
}

export default TextField;
