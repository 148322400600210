export enum WidgetStates {
  login = 'primary-auth',
  mfa = 'mfa-verify',
  forgotPass = 'forgot-password',
  accountLocked = 'locked',
}

export enum WidgetErrorCodes {
  accountLocked = 'E0000119',
}
