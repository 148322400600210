import { useHistory, useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import Paths from 'constants/Paths';
import OfferQuery from 'constants/OfferQuery';
import { OFFER_TYPE_QUERY_KEY } from 'components/offer-tabs/offer-tabs';

function useRouter() {
  const history = useHistory();
  const location = useLocation();
  const queries = new URLSearchParams(location.search);

  const toBrowseOffersPage = useCallback(
    (query?: OfferQuery) => {
      history.push({
        pathname: Paths.browseOffersPage,
        search: query ? `?${OFFER_TYPE_QUERY_KEY}=${query}` : '',
      });
    },
    [history]
  );

  const toEditOfferPage = useCallback(
    (offerId?: string) => {
      history.push({
        pathname: Paths.createOfferPage,
        search: offerId ? `?id=${offerId}` : '',
      });
    },
    [history]
  );

  const toSignupConfirmedPage = useCallback(() => {
    history.push({
      pathname: Paths.signupConfirmation,
    });
  }, [history]);

  const toUserInfoPage = useCallback(
    (id: string) => {
      history.push({
        pathname: `${Paths.userManagement}/${id}`,
      });
    },
    [history]
  );

  const toLoginPage = useCallback(() => {
    history.push({
      pathname: Paths.loginPage,
    });
  }, [history]);

  const toUserManagementPage = useCallback(() => {
    history.push({
      pathname: Paths.userManagement,
    });
  }, [history]);

  const toProfile = useCallback(() => {
    history.push({
      pathname: Paths.profile,
    });
  }, [history]);

  return {
    toBrowseOffersPage,
    toEditOfferPage,
    toSignupConfirmedPage,
    toUserManagementPage,
    toUserInfoPage,
    toLoginPage,
    toProfile,
    queries,
    pathname: location.pathname,
  };
}

export default useRouter;
