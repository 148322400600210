import { useState, useEffect } from 'react';
import type { OfferBase, OfferCollection } from 'types';
import useTranslation from 'hooks/useTranslation';
import ReactTable from 'react-bootstrap/Table';
import { ActionsButton, PaginationBar, Shimmer } from 'components';
import styles from './offers-table.module.scss';
import Status from 'constants/Status';
import { useAppContext } from 'context/app-context';
import { useBreakpoints } from 'hooks';
import { useOfferRequester } from 'context/requesters/offer';
import Locale from 'constants/Locale';
import OfferQuery from 'constants/OfferQuery';

const OFFERS_PER_PAGE = 10;
const IMAGE_PLACEHOLDER_URL = '/images/offer_placeholder.png';

function ShimmerRow() {
  return (
    <tr>
      <td>
        <Shimmer width={250} height={125} />
      </td>
      <td>
        <Shimmer height={40} />
      </td>
      <td>
        <Shimmer height={20} />
      </td>
      <td>
        <Shimmer height={20} />
      </td>
      <td>
        <Shimmer height={20} />
      </td>
      <td>
        <Shimmer height={50} />
      </td>
    </tr>
  );
}

const Table = ({ status, type, searchTerm }: OffersTableProps) => {
  const [offers, setOffers] = useState<OfferCollection>({
    total: 0,
    skip: 0,
    limit: 0,
    items: [],
  });
  const { t } = useTranslation();
  const { locale, analyticsInstance } = useAppContext();
  const [today] = useState(new Date());
  const [activePage, setActivePage] = useState(1);
  const { isMobile } = useBreakpoints();
  const offerReq = useOfferRequester();
  const [isLoading, setIsLoading] = useState(false);

  const setDateFormat = (date: string) => {
    if (date) {
      return new Date(date).toLocaleDateString(locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
    return date;
  };

  const setStatus = (status: Status, offer: OfferBase) => {
    if (type === Status.Published && offer.endDateTime) {
      if (new Date(offer.endDateTime) < today) {
        return t('expired');
      }
    }
    return t(status.toLowerCase().replaceAll(' ', '_'));
  };

  const skip = OFFERS_PER_PAGE * (activePage - 1);
  const limit = OFFERS_PER_PAGE * activePage;

  useEffect(() => {
    if (offerReq) {
      setIsLoading(true);
      offerReq
        .getOffers(status, skip, OFFERS_PER_PAGE, searchTerm)
        .then((data) => {
          setOffers(data);
          analyticsInstance?.sendViewOfferListEvent(
            status as OfferQuery,
            searchTerm,
            data.total,
            activePage
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [offerReq, skip, limit, status, searchTerm, analyticsInstance]);

  return (
    <>
      {offers && (
        <div
          className={styles.root}
          role="region"
          aria-live="polite"
          aria-label={`${t(status)} ${t('offers')}, ${t('assets_found', { number: offers.total })}`}
        >
          <div className={styles.counter} aria-hidden>
            {t('assets_found', { number: offers.total })}
          </div>
          <div className={styles.tableWrapper}>
            <ReactTable striped>
              <thead>
                <tr>
                  <th style={{ width: '15%' }}> </th>
                  <th style={{ width: '30%' }}>{t('headline')}</th>
                  <th style={{ width: '20%' }}>{t('scheduled_publish_date')}</th>
                  <th style={{ width: '20%' }}>{t('start_date')}</th>
                  <th style={{ width: '10%' }}>{t('status')}</th>
                  <th style={{ width: '5%' }}> </th>
                </tr>
              </thead>
              <tbody>
                {!isLoading &&
                  offers.items.map((offer) => {
                    const headline = offer.headline?.[locale] || offer.headline?.[Locale.en] || '';

                    return (
                      <tr key={offer.sys.id}>
                        <td>
                          <img
                            src={
                              offer.imageUrl?.[locale] ||
                              offer.imageUrl?.[Locale.en] ||
                              IMAGE_PLACEHOLDER_URL
                            }
                            alt={t('thumbnail_photo')}
                            width="250"
                            height="125"
                          />
                        </td>
                        <td>{headline}</td>
                        <td>{offer.publishDateTime && setDateFormat(offer.publishDateTime)}</td>
                        <td>{offer.startDateTime && setDateFormat(offer.startDateTime)}</td>
                        <td>{setStatus(offer.status, offer)}</td>
                        <td>
                          <ActionsButton offerName={headline} offerId={offer.sys.id} type={type} />
                        </td>
                      </tr>
                    );
                  })}

                {isLoading &&
                  Array(OFFERS_PER_PAGE)
                    .fill(0)
                    .map((i) => <ShimmerRow key={i} />)}
              </tbody>
            </ReactTable>
          </div>

          <PaginationBar
            activePage={activePage}
            setActivePage={setActivePage}
            totalItems={offers.total}
            pageSize={OFFERS_PER_PAGE}
            maxPages={isMobile ? 4 : 5}
          />
        </div>
      )}
    </>
  );
};

interface OffersTableProps {
  type: Status;
  status: string;
  searchTerm: string;
}

export default Table;
