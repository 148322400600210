import { forwardRef, HTMLAttributes, ReactNode, useRef } from 'react';
import { Col, FormLabel, FormControl, FormGroup, FormGroupProps } from 'react-bootstrap';
import { uniqueId } from 'lodash-es';
import cx from 'classnames';
import styles from './input-layout.module.scss';
import Text from 'components/text/text';

type BaseInputLayoutProps = InputLayoutDtoProps & HTMLAttributes<HTMLElement>;

// For extending props of consuming component
export interface InputLayoutDtoProps extends FormGroupProps {
  description?: ReactNode;
  required?: boolean;
  label?: ReactNode;
  error?: ReactNode;
  row?: boolean;
}

// For defining props of this component
interface InputLayoutProps extends BaseInputLayoutProps {
  asFieldset?: boolean;
}

export function getAriaTags(
  ref: HTMLElement | null,
  props: Pick<InputLayoutProps, 'description' | 'error' | 'label'>
): HTMLAttributes<HTMLElement> {
  if (!ref) return {};
  return {
    'aria-describedby': props.description ? `${ref.id}--description` : undefined,
    'aria-errormessage': props.error ? `${ref.id}--error` : undefined,
    'aria-labelledby': props.label ? `${ref.id}--label` : undefined,
    'aria-invalid': !!props.error,
    id: ref.id,
  };
}

// This should not be added to index.ts - it ideally should just be used "internally" in this folder to build form components
const InputLayout = forwardRef(function InputLayout(
  { label, required, description, asFieldset, children, error, row, ...props }: InputLayoutProps,
  ref
) {
  const id = useRef(uniqueId('form-'));
  const LabelElement = asFieldset ? 'legend' : FormLabel;

  return (
    <FormGroup
      {...props}
      as={props.as ?? (asFieldset ? 'fieldset' : FormGroup)}
      className={cx(
        props.className,
        'mb-4',
        row && 'row',
        !!error && styles.hasError,
        styles.container
      )}
      controlId={id.current}
      id={id.current}
      ref={ref}
    >
      <Col sm={row ? 3 : undefined} className={cx(row || 'p-0')}>
        {label && (
          <LabelElement id={`${id.current}--label`} htmlFor={id.current}>
            <Text tag="span" className={cx(required && styles.required)} type="h6">
              {label}
            </Text>
          </LabelElement>
        )}
        {description && (
          <Text
            className="mb-3"
            id={`${id.current}--description`}
            color="black"
            type="body"
            tag="p"
          >
            {description}
          </Text>
        )}
      </Col>
      <Col sm={row ? 9 : undefined} className={cx(row || 'p-0')}>
        {children}
        {error && (
          <FormControl.Feedback id={`${id.current}--error`} className={styles.error} type="invalid">
            {error}
          </FormControl.Feedback>
        )}
      </Col>
    </FormGroup>
  );
});

export default InputLayout;
