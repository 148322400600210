import { HTMLAttributes, MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import Icon from 'components/rp-icon/rp-icon';
import Text from 'components/text/text';
import styles from './input-token.module.scss';

interface InputTokenProps<T extends ReactNode = string>
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  onChange?: (newTokens: T[], event: MouseEvent<HTMLButtonElement>) => void;
  renderToken?: (token: T) => ReactNode;
  isEqual?: (a: T, b: T) => boolean;
  readOnly?: boolean;
  tokens: T[];
}

function InputToken<T extends ReactNode>({
  renderToken = (token) => token,
  isEqual = (a, b) => a === b,
  onChange,
  readOnly,
  tokens,
  ...props
}: InputTokenProps<T>) {
  const { t } = useTranslation();
  return (
    <div {...props}>
      <ul className={styles.pills}>
        {tokens.map((token, key) => (
          <li className={styles.pill} key={key}>
            <Text className={styles.text} type="bodySm" color="midnight-blue">
              {renderToken(token)}
            </Text>
            {!readOnly && (
              <Button
                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                  onChange?.(
                    tokens.filter((t) => !isEqual(t, token)),
                    event
                  );
                }}
                className={styles.close}
                disabled={readOnly}
                variant="light"
              >
                <Icon name="closeOutlined" width="16" height="16" alt="Remove item" />
              </Button>
            )}
          </li>
        ))}
      </ul>
      {!readOnly && !!tokens.length && (
        <Button
          onClick={(event: MouseEvent<HTMLButtonElement>) => onChange?.([], event)}
          className={styles.clearAll}
          variant="link"
        >
          {t('clear_all')}
        </Button>
      )}
    </div>
  );
}

export default InputToken;
