import React from 'react';
import cx from 'classnames';
import { Helmet } from 'react-helmet';
import Header from '../header/header';
import Footer from '../footer/footer';
import styles from './layout.module.scss';
import Color from 'types/color.type';
import useBreakpoints from 'hooks/useBreakpoints';
import imageMap from '../../constants/IconUrlMap';

const Layout = ({
  children,
  pageTitle,
  pageDescription,
  withFooterNavBar = false,
  showFooterNav = false,
  showHeaderNav = false,
  showTapestryLeft = false,
  showTapestryRight = false,
  backgroundColor = 'white',
  showTapestryOnMobile = false,
  tapestryName = 'tapestryTopLeft',
  className,
}: LayoutProps) => {
  const { isMobile } = useBreakpoints();
  const canShowTapestry = isMobile ? showTapestryOnMobile : true;

  return (
    <div className={styles[`bg-${backgroundColor}`]}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>

      <Header displayNav={showHeaderNav} />
      <main className={styles.content}>
        {canShowTapestry && (showTapestryLeft || showTapestryRight) && (
          <img
            src={imageMap[tapestryName]}
            alt=""
            className={cx(
              styles.tapestry,
              {
                [styles['tapestry--left']]: showTapestryLeft,
                [styles['tapestry--right']]: showTapestryRight,
                [styles['tapestry--reduced']]: tapestryName === 'tapestryTop',
              },
              className
            )}
          />
        )}
        {children}
      </main>
      <Footer withNavBar={withFooterNavBar} withNavItems={showFooterNav} />
    </div>
  );
};

export interface LayoutProps {
  children?: React.ReactNode;
  pageTitle: string;
  pageDescription: string;
  withFooterNavBar?: boolean;
  showFooterNav?: boolean;
  showHeaderNav?: boolean;
  showTapestryLeft?: boolean;
  showTapestryRight?: boolean;
  backgroundColor?: Color;
  showTapestryOnMobile?: boolean;
  tapestryName?: string;
  className?: string;
}

export default Layout;
