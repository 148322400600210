import { Icon } from 'components';
import styles from './single-select.module.scss';

function SingleSelect({
  options = [],
  value,
  name,
  onChange,
  disabled = false,
}: SingleSelectProps) {
  return (
    <div className={styles.root}>
      <select
        className={styles.rmDefault}
        onChange={onChange}
        value={value}
        name={name}
        disabled={disabled}
      >
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>

      <div className={styles.indicator}>
        <Icon name="caretTriangle" alt="dropdown" width="12" />
      </div>
    </div>
  );
}

type Option = {
  label: string;
  value: string;
};

interface SingleSelectProps {
  options: Option[];
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
  name: string;
  value?: string;
  disabled?: boolean;
}

export default SingleSelect;
