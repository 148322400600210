import { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Text, TextField, Button, SingleSelect, PasswordField, PasswordPolicy } from 'components';
import { StoreSelect } from 'components/select';
import { useTranslation, useRouter } from 'hooks';
import useSignupForm, { FormLabels } from './useSignupForm';
import ToSAgreement from './ToSAgreement';
import Locale from 'constants/Locale';
import Property from 'types/property.type';
import RetailerGroup from 'types/retailerGroup.type';
import PropertyService from 'services/property';
import RetailerGroupService from 'services/retailerGroup';
import UnitService from 'services/units';
import UnitType from 'types/unit.type';
import handleError from 'helpers/handleError';
import { Trans } from 'react-i18next';
import { keyBy } from 'lodash-es';

function SignupForm() {
  const { t } = useTranslation();
  const router = useRouter();
  const signupForm = useSignupForm({
    onSuccess: () => {
      router.toSignupConfirmedPage();
    },
  });

  const [retailerGroups, setRetailerGroups] = useState<RetailerGroup[]>([]);
  const [properties, setProperties] = useState<Property[]>([]);
  const [unitMap, setUnitMap] = useState<Record<string, UnitType>>({});

  useEffect(() => {
    async function fetchRetailerGroupsAndProperties() {
      const [groups, allProperties] = await Promise.all([
        handleError(RetailerGroupService.getAllRetailerGroupsSecured()),
        handleError(PropertyService.getAllPropertiesSecured()),
      ]);

      setRetailerGroups(groups as RetailerGroup[]);
      setProperties(allProperties as Property[]);
    }
    fetchRetailerGroupsAndProperties();
  }, []);

  const selectedStoreCount = signupForm.values.stores.length;
  const count = selectedStoreCount ? `(${selectedStoreCount})` : '';

  return (
    <div>
      <div className="mb-3">
        <Text type="h2" tag="h1">
          <Trans i18nKey="signup.title">{''}</Trans>
        </Text>
      </div>
      <div>
        <Text type="body" tag="p" color="cf-dark-grey">
          {t('signup.subtitle')}
        </Text>
      </div>
      <form onSubmit={signupForm.handleSubmit}>
        <Container fluid className="px-0">
          <Row sm={2} xs={1}>
            <Col>
              <TextField
                label={FormLabels.firstName}
                value={signupForm.values.firstName}
                error={signupForm.touched.firstName ? signupForm.errors.firstName : ''}
                onChange={signupForm.handleChange}
                onBlur={signupForm.handleBlur}
                className="mt-4"
                required
              />
            </Col>

            <Col>
              <TextField
                label={FormLabels.lastName}
                value={signupForm.values.lastName}
                error={signupForm.touched.lastName ? signupForm.errors.lastName : ''}
                onChange={signupForm.handleChange}
                onBlur={signupForm.handleBlur}
                className="mt-4"
                required
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <TextField
                label={FormLabels.email}
                value={signupForm.values.email}
                error={signupForm.touched.email ? signupForm.errors.email : ''}
                onChange={signupForm.handleChange}
                onBlur={signupForm.handleBlur}
                className="mt-4"
                required
              />
            </Col>
          </Row>

          <Row sm={2} xs={1}>
            <Col>
              <PasswordField
                label={FormLabels.password}
                value={signupForm.values.password}
                error={signupForm.touched.password ? signupForm.errors.password : ''}
                onChange={signupForm.handleChange}
                onBlur={signupForm.handleBlur}
                className="mt-4"
                ariaDescribedBy="password-policy-0 password-policy-1"
                required
              />
            </Col>

            <Col>
              <PasswordField
                label={FormLabels.passwordConf}
                value={signupForm.values.passwordConf}
                error={signupForm.touched.passwordConf ? signupForm.errors.passwordConf : ''}
                onChange={signupForm.handleChange}
                onBlur={signupForm.handleBlur}
                className="mt-4"
                ariaDescribedBy="password-policy-0 password-policy-1"
                required
              />
            </Col>
          </Row>

          <PasswordPolicy />

          <Row className="mt-4">
            <Col>
              <label>
                <Text type="body" color="cf-dark-grey">
                  {t('form.langPref')}
                </Text>

                <SingleSelect
                  options={[
                    { label: t(Locale.en), value: Locale.en },
                    { label: t(Locale.fr), value: Locale.fr },
                  ]}
                  value={signupForm.values.langPref}
                  name={FormLabels.langPref}
                  onChange={signupForm.handleChange}
                />
              </label>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <Container fluid className="mt-2 px-0">
                <StoreSelect
                  label={`${t('search_for_stores')} ${count}`}
                  onChange={(stores) => {
                    signupForm.setFieldValue(
                      FormLabels.stores,
                      stores.map((store) => unitMap[store])
                    );
                  }}
                  control={{
                    value: signupForm.values.stores.map((store) => store.unitId),
                    name: FormLabels.stores,
                  }}
                  getUnitsByRetailerGroup={async (retailerGroup) => {
                    if (!retailerGroup?.id) return [];
                    const units = await UnitService.getSecuredUnitsByRetailer(retailerGroup.id);
                    setUnitMap((unitMap) => ({
                      ...unitMap,
                      ...keyBy(units, 'unitId'),
                    }));
                    return units;
                  }}
                  getUnitsByProperty={async (property) => {
                    if (!property?.propertyCd) return [];
                    const units = await UnitService.getSecuredUnitsByPropertyCd(
                      property.propertyCd
                    );
                    setUnitMap((unitMap) => ({
                      ...unitMap,
                      ...keyBy(units, 'unitId'),
                    }));
                    return units;
                  }}
                  getUnitById={(unitId) => unitMap[unitId]}
                  retailerGroups={retailerGroups}
                  properties={properties}
                />
              </Container>
            </Col>
          </Row>

          <Row className="my-3">
            <Col>
              <ToSAgreement
                name={FormLabels.tosAgreement}
                checked={signupForm.values.tosAgreement}
                onChange={signupForm.handleChange}
                error={!!signupForm.touched.tosAgreement && !!signupForm.errors.tosAgreement}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Button type="submit" size="stretch" label="Sign Up">
                {t('signup.signup')}
              </Button>
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  );
}

export default SignupForm;
