import { useCallback, useEffect, useState } from 'react';
import './styles/custom-bootstrap.scss';
import 'react-toastify/dist/ReactToastify.css';
import './styles/global.scss';
import Locale from './constants/Locale';
import { AppContextProvider, langToLocale } from 'context/app-context';
import useTranslation from 'hooks/useTranslation';
import { FullPageLoaderContextProvider } from 'context/full-page-loader-context';
import AppRouter from 'pages/app-router/app-router';
import { ToastContainer } from 'react-toastify';
import ErrorBoundary from './ErrorBoundary';
import { withRouter } from 'react-router';
import GoogleAnalyticsInitializer from 'helpers/analytics';

const ErrorBoundaryWithHistory = withRouter(ErrorBoundary);

function getExplicitLang() {
  const urlLang = new URLSearchParams(window.location.search).get('lang');
  const sessionLang = window.sessionStorage.getItem('lang');
  return urlLang ?? sessionLang;
}

function App() {
  const { i18n, t } = useTranslation();
  const [appLocale, setAppLocale] = useState<Locale>(Locale.en);
  const errorMessage = t('generic_error_message');
  const [googleAnalyticsInstance, setGoogleAnalyticsInstance] =
    useState<GoogleAnalyticsInitializer>();

  const handleSetAppLocale = useCallback(
    (locale: Locale, force = false) => {
      if (getExplicitLang() && !force) return;
      setAppLocale(locale);
      i18n.changeLanguage(locale);
    },
    [i18n]
  );

  useEffect(() => {
    const lang = getExplicitLang() ?? window.navigator.language.split('-')[0];
    const newLocale = langToLocale[lang] ?? Locale.en;
    handleSetAppLocale(newLocale, true);
    window.sessionStorage.removeItem('lang');

    if (!googleAnalyticsInstance) {
      setGoogleAnalyticsInstance(new GoogleAnalyticsInitializer(newLocale));
    }
  }, [handleSetAppLocale]);

  return (
    <AppContextProvider
      locale={appLocale}
      setLocale={handleSetAppLocale}
      analyticsInstance={googleAnalyticsInstance}
    >
      <FullPageLoaderContextProvider>
        <ErrorBoundaryWithHistory errorMessage={errorMessage}>
          <AppRouter />
        </ErrorBoundaryWithHistory>
        <ToastContainer hideProgressBar />
      </FullPageLoaderContextProvider>
    </AppContextProvider>
  );
}

export default App;
