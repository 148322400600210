import { useOktaAuth } from '@okta/okta-react';
import { PortalRole } from 'constants/User';
import { useFullPageLoaderContext } from 'context/full-page-loader-context';
import handleError from 'helpers/handleError';
import { useState, useEffect, useCallback } from 'react';
import { UserService, UnitService, PropertyService, RetailerGroupService } from 'services';
import { Offer } from 'types';
import Property from 'types/property.type';
import RetailerGroup from 'types/retailerGroup.type';
import UnitType from 'types/unit.type';

function useOfferLocations(offer?: Offer) {
  const { accessToken } = useOktaAuth()?.authState?.accessToken ?? {};
  const { handleLoading } = useFullPageLoaderContext();

  const [retailerGroups, setRetailerGroups] = useState<RetailerGroup[]>([]);
  const [properties, setProperties] = useState<Property[]>([]);
  // List of units to show based on what user has selected on profile
  const [userUnitFilter, setUserUnitFilter] = useState<Set<string>>(new Set());
  // List of unitIds to Unit objects for rendering purposes
  const [unitMap, setUnitMap] = useState<Record<string, UnitType>>({});

  const addToUnitMap = useCallback((units: UnitType[]) => {
    setUnitMap((unitMap) => {
      const newUnits = units.reduce((acc, unit) => {
        acc[unit.sys.id] = unit;
        return acc;
      }, {});
      return { ...unitMap, ...newUnits };
    });
  }, []);

  const getUnitsByRetailerGroup = useCallback(
    async (retailerGroup: RetailerGroup): Promise<UnitType[]> => {
      if (!retailerGroup?.sys?.id) return [];
      const units =
        (await handleError(UnitService.getUnitsByRetailer(retailerGroup?.sys?.id, accessToken))) ??
        [];
      const filteredUnits = units.filter((unit) => userUnitFilter.has(unit.sys.id));
      addToUnitMap(filteredUnits);
      return filteredUnits;
    },
    [userUnitFilter, addToUnitMap, accessToken]
  );

  const getUnitsByProperty = useCallback(
    async (property: Property): Promise<UnitType[]> => {
      if (!property?.sys?.id) return [];
      const units =
        (await handleError(UnitService.getUnitsByProperty(property.sys.id, accessToken, true))) ??
        [];
      addToUnitMap(units);
      return units;
    },
    [addToUnitMap, accessToken]
  );

  useEffect(() => {
    if (!accessToken) return;
    let mounted = true;
    handleLoading(
      (async () => {
        const user = await UserService.getMyProfile(accessToken);
        const {
          portalRole = PortalRole.RetailClient,
          retailerGroups = [],
          units = [],
        } = user?.profile ?? {};
        if (!mounted) return;
        setUserUnitFilter(new Set(units));

        const isAdmin = portalRole !== PortalRole.RetailClient;
        const [unitRes, retailerGroupsRes, propertiesRes] = await Promise.all([
          handleError(
            UnitService.getUnitsByIds([...(offer?.locations ?? []), ...units], accessToken)
          ),
          handleError(
            isAdmin
              ? RetailerGroupService.getAllRetailerGroups(accessToken)
              : RetailerGroupService.getRetailerGroupsByIds(accessToken, retailerGroups)
          ),
          handleError(PropertyService.getAllProperties(accessToken, true)),
        ]);
        if (!mounted || !unitRes || !retailerGroupsRes || !propertiesRes) return;
        setRetailerGroups(retailerGroupsRes);
        setProperties(propertiesRes);
        addToUnitMap(unitRes);
      })()
    );

    return () => {
      mounted = false;
    };
  }, [handleLoading, accessToken, offer, addToUnitMap]);

  return {
    getUnitsByRetailerGroup,
    getUnitsByProperty,
    retailerGroups,
    properties,
    userUnitFilter,
    addToUnitMap,
    unitMap,
  };
}

export default useOfferLocations;
