import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import { PasswordPolicy, PasswordField } from 'components';
import GenericModal from '../GenericModal';
import { toast } from 'react-toastify';

import useTranslation from 'hooks/useTranslation';
import UserService from 'services/user';

import useChangePasswordForm, { FormLabels } from './useChangePasswordForm';
import { useOktaAuth } from '@okta/okta-react';

const ChangePasswordModal = ({
  title,
  handleClose,
  confirmButtonText,
  cancelButtonText,
}: ChangePasswordModalProps) => {
  const { t } = useTranslation();
  const accessTokenObj = useOktaAuth()?.authState?.accessToken;
  const { uid: id } = accessTokenObj.claims;
  const { accessToken } = accessTokenObj;

  const changePasswordForm = useChangePasswordForm({
    changePassword: (passwordData) => {
      return UserService.changePassword(id, passwordData, accessToken);
    },
    beforeSubmit: () => {
      const invalidFields = Object.keys(changePasswordForm.errors);
      if (invalidFields.length > 0) {
        const target = document.getElementById(invalidFields[0]);
        if (target) {
          target.focus();
          target.scrollIntoView();
        }
      }
    },
    onSuccess: () => {
      toast.success(t('change_password_modal.toast'));
      handleClose();
    },
    onFailure: () => toast.error(t('error:general_description')),
  });

  return (
    <GenericModal
      show
      title={title}
      handleClose={handleClose}
      handleAccept={() => changePasswordForm.submitForm()}
      confirmButtonText={confirmButtonText}
      cancelButtonText={cancelButtonText}
      fullWidthButtons
      acceptDisabled={changePasswordForm.dirty && !changePasswordForm.isValid}
    >
      <form>
        <hr />
        <Container fluid className="px-0">
          <Row>
            <Col>
              <PasswordField
                id={FormLabels.oldPassword}
                label={FormLabels.oldPassword}
                value={changePasswordForm.values.oldPassword}
                error={
                  changePasswordForm.touched.oldPassword
                    ? changePasswordForm.errors.oldPassword
                    : ''
                }
                onChange={changePasswordForm.handleChange}
                onBlur={changePasswordForm.handleBlur}
                className="mt-3"
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <PasswordField
                id={FormLabels.passwordNew}
                label={FormLabels.passwordNew}
                value={changePasswordForm.values.passwordNew}
                error={
                  changePasswordForm.touched.passwordNew
                    ? changePasswordForm.errors.passwordNew
                    : ''
                }
                onChange={changePasswordForm.handleChange}
                onBlur={changePasswordForm.handleBlur}
                className="mt-3"
                ariaDescribedBy="password-policy"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <PasswordField
                id={FormLabels.passwordConf}
                label={FormLabels.passwordConf}
                value={changePasswordForm.values.passwordConf}
                error={
                  changePasswordForm.touched.passwordConf
                    ? changePasswordForm.errors.passwordConf
                    : ''
                }
                onChange={changePasswordForm.handleChange}
                onBlur={changePasswordForm.handleBlur}
                className="mt-3"
              />
            </Col>
          </Row>
          <div id="password-policy">
            <PasswordPolicy />
          </div>
        </Container>
      </form>
    </GenericModal>
  );
};

interface ChangePasswordModalProps {
  title: string;
  handleClose: () => void;
  handleAccept: React.MouseEventHandler;
  confirmButtonText?: string;
  cancelButtonText?: string;
}
export default ChangePasswordModal;
