import { REACT_APP_OKTA_DOMAIN, REACT_APP_OKTA_CLIENT_ID } from './env';

const oktaAuthConfig = {
  // Note: If your app is configured to use the Implicit flow
  // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
  // you will need to add `pkce: false`
  issuer: `https://${REACT_APP_OKTA_DOMAIN}/oauth2/default`,
  clientId: `${REACT_APP_OKTA_CLIENT_ID}`,
  redirectUri: window.location.origin + '/',
};

const oktaSignInConfig = {
  baseUrl: `https://${REACT_APP_OKTA_DOMAIN}`,
  clientId: `${REACT_APP_OKTA_CLIENT_ID}`,
  redirectUri: window.location.origin + '/',
  authParams: {
    scopes: ['openid', 'email', 'groups', 'profile', 'offline_access'],
    // If your app is configured to use the Implicit flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to uncomment the below line
    // pkce: false
  },
  // Additional documentation on config options can be found at https://github.com/okta/okta-signin-widget#basic-config-options
  i18n: {
    en: {
      // Labels
      'primaryauth.username.placeholder': 'Email',
      'primaryauth.password.placeholder': 'Password',
      goback: 'Back to sign in',
      // MFA Labels
      'email.mfa.description': 'Send a verification code to {0}?',
      'email.button.send': 'Send code',
      'email.mfa.email.sent.description':
        'A verification code has been sent to {0}. Check your email and enter the 6-digit code below.',
      'mfa.challenge.verify': 'Verify and Sign In',
      // Errors
      'error.username.required': 'Please enter a valid email address',
      'error.password.required': 'Please enter a password',
      'errors.E0000004': 'Incorrect username or password',
      'oform.errorbanner.title': 'Please review the form and make corrections.',
    },
    fr: {
      // Labels
      'primaryauth.username.placeholder': 'Courriel',
      'primaryauth.password.placeholder': 'Mot de passe',
      remember: 'Se souvenir de moi',
      'primaryauth.submit': 'Ouverture de session',
      goback: 'Retour à l’ouverture de session',
      // MFA Labels
      'email.mfa.description': 'Envoyer un code de vérification à {0}?',
      'email.button.send': 'Envoyer le code',
      'email.mfa.email.sent.description':
        'Un code de vérification a été envoyé à {0}. Consultez vos courriels et entrer le code à 6 chiffres ci-dessous.',
      'mfa.challenge.verify': 'Vérifier et ouvrir la session',
      // Errors
      'error.username.required': 'Veuillez entrer une adresse courriel valide',
      'error.password.required': 'Veuillez entrer un mot de passe',
      'errors.E0000004': 'Nom d’utilisateur ou mot de passe erroné',
      'oform.errorbanner.title': 'Veuillez réviser le formulaire et apporter les corrections.',
    },
  },
  useClassicEngine: true,
  features: {
    showPasswordToggleOnSignInPage: true,
  },
};

export { oktaAuthConfig, oktaSignInConfig };
