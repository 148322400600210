import Pagination from 'react-bootstrap/Pagination';
import styles from './pagination-bar.module.scss';

const PaginationBar = ({
  activePage,
  setActivePage,
  totalItems,
  pageSize,
  maxPages,
}: PaginationBarProps) => {
  const items = [];
  const totalPages = Math.ceil(totalItems / pageSize);
  const testStart = Math.max(1, activePage - Math.floor(maxPages / 2));
  const end = Math.min(testStart + (maxPages - 1), totalPages);
  const start = Math.max(1, end - (maxPages - 1));

  for (let page = start; page <= end; page++) {
    if (totalPages > 1) {
      items.push(
        <Pagination.Item
          key={page}
          active={page === activePage}
          onClick={() => setActivePage(page)}
        >
          {page === end && end !== totalPages ? `${page}...` : page}
        </Pagination.Item>
      );
    }
  }

  return (
    <div className={styles.pagination}>
      {totalPages > 1 && (
        <Pagination>
          <Pagination.Prev
            onClick={() => setActivePage(activePage - 1)}
            className="page-navigation"
            disabled={activePage === 1}
            aria-disabled={activePage === 1}
          />
          {items}
          <Pagination.Next
            onClick={() => setActivePage(activePage + 1)}
            className="page-navigation"
            disabled={activePage === totalPages}
            aria-disabled={activePage === totalPages}
          />
        </Pagination>
      )}
    </div>
  );
};

interface PaginationBarProps {
  activePage: number;
  setActivePage: (page: number) => void;
  totalItems: number;
  pageSize: number;
  maxPages: number;
}

export default PaginationBar;
