import { FormCheck, FormCheckProps } from 'react-bootstrap';
import cx from 'classnames';
import styles from './input-checkbox.module.scss';
import { useEffect, useRef } from 'react';
import { uniqueId } from 'lodash-es';

export interface InputCheckboxProps extends FormCheckProps {
  indeterminate?: boolean;
}

export function InputCheckbox({ indeterminate, ...props }: InputCheckboxProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const id = useRef(uniqueId('form-'));

  useEffect(() => {
    if (!inputRef.current || typeof indeterminate !== 'boolean') return;
    inputRef.current.indeterminate = indeterminate;
  }, [indeterminate]);

  return (
    <FormCheck
      {...props}
      className={cx(props.className, styles.check)}
      id={props.id ?? id.current}
      type="checkbox"
      ref={inputRef}
      custom
    />
  );
}
