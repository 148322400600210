import { useState } from 'react';
import GenericModal from '../GenericModal';
import { Text, SingleSelect, TextInput } from 'components';
import useTranslation from 'hooks/useTranslation';
import UserService from 'services/user';
import { useOktaAuth } from '@okta/okta-react';
import { useModalContext } from 'context/modal-context';
import { toast } from 'react-toastify';
import { useFullPageLoaderContext } from 'context/full-page-loader-context';

enum declineOptions {
  accountExists = 'account_exists',
  incorrectInfo = 'incorrect_info',
  incorrectStore = 'incorrect_store',
  invalidLocation = 'invalid_location',
  other = 'other',
}

const DeclineUserModal = ({ id, onSuccess }: DeclineUserModalProps) => {
  const { t } = useTranslation();
  const [declineReason, setDeclineReason] = useState<string>(declineOptions.accountExists);
  const [customReason, setCustomReason] = useState('');
  const [customReasonError, setCustomReasonError] = useState(false);
  const { accessToken } = useOktaAuth()?.authState?.accessToken || {};
  const loader = useFullPageLoaderContext();

  const { setShowModal } = useModalContext();

  const options = Object.values(declineOptions).map((v) => ({
    value: v,
    label: t(`decline_user_modal.reason.${v}`),
  }));

  return (
    <GenericModal
      show
      title={t('decline_user_modal.title')}
      handleAccept={async () => {
        const shouldProvideReason = declineReason === declineOptions.other;
        const reason = !shouldProvideReason ? declineReason : customReason;
        if (shouldProvideReason && (customReason.length < 1 || customReason.length > 100)) {
          setCustomReasonError(true);
          return;
        }

        await loader.handleLoading(UserService.delete(id, accessToken, reason));
        toast.success(t('decline_user_modal.toast'));
        setShowModal(false);
        if (typeof onSuccess === 'function') onSuccess();
      }}
      confirmButtonText={t('decline_user_modal.confirm_button')}
      acceptDisabled={loader.isLoading}
      cancelButtonText={t('cancel')}
    >
      <Text>{t('decline_user_modal.body')}</Text>

      <hr />

      <Text type="h6">{t('decline_user_modal.input_title')}</Text>
      <SingleSelect
        name="select"
        value={declineReason}
        options={options}
        onChange={(e) => setDeclineReason(e.target.value)}
        disabled={loader.isLoading}
      />
      {declineReason === declineOptions.other && (
        <div className="mt-2">
          <TextInput
            type="text"
            value={customReason}
            label={t('decline_user_modal.custom_reason_placeholder')}
            onChange={(e) => {
              setCustomReason(e.target.value);
            }}
            placeholder={t('decline_user_modal.custom_reason_placeholder')}
            error={customReasonError}
          />
        </div>
      )}
    </GenericModal>
  );
};

export interface DeclineUserModalProps {
  id: string;
  onSuccess?(): void;
}

export default DeclineUserModal;
