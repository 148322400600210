import { Text } from 'components';
import { useTranslation } from 'hooks';
import { Col, Row } from 'react-bootstrap';
import styles from './form-components.module.scss';

const PasswordPolicy = () => {
  const { t } = useTranslation();

  const passwordRequirementGroups = [
    [t('passwordPolicy.minChar'), t('passwordPolicy.letters'), t('passwordPolicy.common')],
    [t('passwordPolicy.symbols'), t('passwordPolicy.number'), t('passwordPolicy.noParts')],
  ];

  return (
    <Row className="mt-2">
      <Col xs={12}>
        <Text type="h7" tag="h2" color="cf-dark-grey">
          {t('passwordPolicy.passwordReq')}
        </Text>
      </Col>

      <>
        {passwordRequirementGroups.map((group, i) => (
          <Col xs={12} sm={6} key={i}>
            <Text type="bodySm" tag="div" color="cf-dark-grey">
              <ul id={`password-policy-${i}`} className={styles.bulletList}>
                {group.map((req) => (
                  <li key={req}>{req}</li>
                ))}
              </ul>
            </Text>
          </Col>
        ))}
      </>
    </Row>
  );
};

export default PasswordPolicy;
