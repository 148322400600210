import { ComponentPropsWithoutRef } from 'react';
import cx from 'classnames';
import { useTranslation } from 'hooks';
import { Text, Button } from 'components';
import { ModalType } from 'constants/ModalType';
import { useModalContext } from 'context/modal-context';

function PageHeader(props: ComponentPropsWithoutRef<'div'>) {
  const { t } = useTranslation();
  const { setAndShowModal, setShowModal } = useModalContext();

  const createUser = () => {
    setAndShowModal({
      type: ModalType.CreateUser,
      title: t('create_user_modal.title'),
      handleClose: () => setShowModal(false),
      confirmButtonText: t('create_user_modal.submit'),
      cancelButtonText: t('cancel'),
    });
  };

  return (
    <div
      {...props}
      className={cx(props.className, 'd-flex flex-wrap align-items-center justify-content-between')}
    >
      <div className="my-2 mr-2">
        <Text className="pb-2" type="allCaps" tag="span" color="cf-dark-grey">
          {t('user_table.eyebrow')}
        </Text>
        <Text type="h2" tag="div">
          {t('user_table.title')}
        </Text>
      </div>
      <Button onClick={createUser} size="small" label="New User">
        {t('create_user_modal.title')}
      </Button>
    </div>
  );
}

export default PageHeader;
