import { useFullPageLoaderContext } from 'context/full-page-loader-context';
import { useFormik } from 'formik';
import * as Validator from 'helpers/validator';
import ApiError from 'lib/errors/ApiError';
import { UserService } from 'services';

export enum FormLabels {
  email = 'email',
}

export type ForgotPasswordFormData = {
  [FormLabels.email]: string;
};

type FormErrors = Partial<Record<FormLabels, Validator.ValidationError | null>>;

function validateForm(data: ForgotPasswordFormData): FormErrors {
  const errors: FormErrors = {};
  if (Validator.validateEmail(data.email)) {
    errors.email = Validator.validateEmail(data.email);
  }

  return errors;
}

function useForgotPasswordForm(params: {
  beforeSubmit?(): void;
  afterSubmit?(): void;
  onSuccess?(data: ForgotPasswordFormData): void;
}) {
  const { handleLoading } = useFullPageLoaderContext();
  const form = useFormik<ForgotPasswordFormData>({
    initialValues: {
      [FormLabels.email]: '',
    },
    validateOnMount: true,
    validate: (values) => validateForm(values),
    onSubmit: (values) =>
      handleLoading(
        (async () => {
          try {
            if (params.beforeSubmit) {
              params.beforeSubmit();
            }

            if (params.onSuccess) {
              await UserService.forgotPassword(values.email);
              params.onSuccess(values);
            }
          } catch (err: unknown) {
            if (err instanceof ApiError) {
              const apiError = err as ApiError;

              if (apiError.status === 400) {
                const errors: Record<string, string> = {};
                if (apiError.details) {
                  apiError.details.forEach((detail) => {
                    if (detail.param) {
                      errors[detail.param as string] = detail.msg;
                    }
                  });
                }
                form.setErrors(errors);
              }
            }
          } finally {
            if (params.afterSubmit) {
              params.afterSubmit();
            }
          }
        })()
      ),
  });

  return form;
}

export default useForgotPasswordForm;
