import { createContext, useContext, useState, useEffect } from 'react';
import OfferRequester from './offerRequester';
import { useAuthContext } from 'context/auth-context';

const Context = createContext<OfferRequester | null>(null);

export function OfferRequesterProvider({ children }: { children: React.ReactNode }) {
  const [requester, setRequester] = useState<OfferRequester | null>(null);
  const { token } = useAuthContext();

  useEffect(() => {
    setRequester(token ? new OfferRequester(token) : null);
  }, [token]);

  return <Context.Provider value={requester}>{children}</Context.Provider>;
}

export function useOfferRequester() {
  return useContext(Context);
}
