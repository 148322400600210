import { ValidationError } from 'helpers/validator';
import { sortBy } from 'lodash-es';

export function getFileSize(file: File): Promise<{ width: number; height: number }> {
  const url = URL.createObjectURL(file);
  const img = new Image();

  return new Promise((resolve) => {
    img.onload = () => {
      URL.revokeObjectURL(url);
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.src = url;
  });
}

export function toFormData(
  obj: Record<string, string | string[] | File | Record<string, string>[] | null>
): FormData {
  const form = new FormData();

  Object.entries(obj).forEach(([key, value]) => {
    if (value === null) return;

    if (Array.isArray(value)) {
      // TODO: need to polish this up and test this
      value.forEach((ele, i) => {
        if (typeof ele === 'object' && ele !== null) {
          Object.entries(ele).forEach(([k, v]) => {
            form.append(`${key}[${i}][${k}]`, v);
          });
        } else {
          form.append(`${key}[${i}]`, ele);
        }
      });
    } else {
      form.append(key, value);
    }
  });

  return form;
}

export function errorToNoPronounError(error: string | undefined) {
  if (!error) return error;

  if (error) {
    if (error === ValidationError.missing) {
      return `${error}_NO_PRONOUN`;
    } else {
      return error;
    }
  }
}

// Simple checker for offer-form
export function getChangedFields<T extends object>(
  keys: string[],
  initState: T,
  currentState: T
): Partial<T> {
  return keys.reduce<Partial<T>>((acc, key) => {
    const type = typeof initState[key];
    let hasChange = type !== typeof currentState[key];
    if (!hasChange) {
      if (type === 'object') {
        if (initState[key] instanceof File && currentState[key] instanceof File) {
          const lastModified =
            (initState[key] as File)?.lastModified - (currentState[key] as File)?.lastModified;
          hasChange = hasChange || !!lastModified;
        } else if (Array.isArray(initState[key]) && Array.isArray(currentState[key])) {
          hasChange =
            hasChange || sortBy(initState[key]).join(',') !== sortBy(currentState[key]).join(',');
        } else {
          hasChange = hasChange || initState[key] !== currentState[key];
        }
      } else {
        hasChange = hasChange || initState[key] !== currentState[key];
      }
    }

    if (hasChange) {
      acc[key] = currentState[key];
    }
    return acc;
  }, {});
}

/**
 * @returns Today's date as a string, format is: YYYY-MM-DDThh:mm
 */
export function getDatetimeString(date: Date): string {
  const tzOffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(date.getTime() - tzOffset).toISOString().slice(0, -8);
}

/**
 * @returns Today's date as a string, format is: YYYY-MM-DDThh:mm
 */
export function getTodayString(): string {
  return getDatetimeString(new Date());
}

export function getTimeNow(): string {
  return new Date().toLocaleTimeString('en-CA', { hour12: false });
}

export function getDifferenceInDays(date1: string, date2: string) {
  const differenceInTime = new Date(date2).getTime() - new Date(date1).getTime();
  return differenceInTime / (1000 * 3600 * 24);
}
