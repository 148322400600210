import { ComponentPropsWithoutRef, ReactNode, useRef } from 'react';
import cx from 'classnames';
import InputLayout, { InputLayoutDtoProps, getAriaTags } from 'components/form/input-layout';
import styles from './select-input.module.scss';
import Icon from 'components/rp-icon/rp-icon';

type SelectInputItem = { label: ReactNode; value: string };

interface SelectInputProps<Item extends SelectInputItem = SelectInputItem>
  extends InputLayoutDtoProps {
  control: ComponentPropsWithoutRef<'select'>;
  items: Item[];
}

function SelectInput<Item extends SelectInputItem>({
  control,
  items,
  ...props
}: SelectInputProps<Item>) {
  const layoutRef = useRef<HTMLElement>(null);
  const ariaTags = getAriaTags(layoutRef.current, props);
  const isPlaceholder = items.every((item) => item.value !== control.value);

  return (
    <InputLayout {...props} required={control.required} ref={layoutRef}>
      <div className={styles.frame}>
        <select
          {...control}
          {...ariaTags}
          defaultValue=""
          className={cx(styles.input, isPlaceholder && styles.placeholder)}
        >
          {control.placeholder && (
            <option disabled hidden value="">
              {control.placeholder}
            </option>
          )}
          {items.map((item, key) => (
            <option key={key} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <Icon
          className={styles.caret}
          name="caretTriangle"
          alt="show options"
          ariaHidden
          width="12"
          height="12"
        />
      </div>
    </InputLayout>
  );
}

export default SelectInput;
