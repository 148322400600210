class ApiError extends Error {
  constructor(
    public status: number,
    public errorCode: number,
    public message: string,
    public details?: Array<{
      msg: string;
      param: string;
      location: string;
    }>
  ) {
    super(message);
  }
}

export default ApiError;
