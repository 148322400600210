/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FORGOT_PASSWORD_API_ENDPOINT,
  SIGNUP_API_ENDPOINT,
  USER_API_ENDPOINT,
  VERIFY_RECOVERY_TOKEN_API_ENDPOINT,
  RESET_PASSWORD_TOKEN_API_ENDPOINT,
} from 'config/env';
import axios from 'axios';
import ApiError from 'lib/errors/ApiError';
import UserType from 'types/user.type';
import { RequestUnit } from 'pages/SignupPage/SignupForm/useSignupForm';

const UserService = {
  signup: async (data: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    preferredLanguage: string;
    units: RequestUnit[];
  }): Promise<void> => {
    try {
      await axios.post(SIGNUP_API_ENDPOINT, data);
    } catch (error: any) {
      const { response } = error;
      if (response) {
        const { data } = response;
        throw new ApiError(data.status, data.errorCode, data.message, data.details);
      } else {
        throw new Error(error.message);
      }
    }
  },

  forgotPassword: async (email: string): Promise<any> => {
    const res = await axios.post(`${FORGOT_PASSWORD_API_ENDPOINT}/${encodeURIComponent(email)}`);
    return res.data;
  },

  verifyRecoveryToken: async (recoveryToken: string): Promise<any> => {
    try {
      const res = await axios.post(VERIFY_RECOVERY_TOKEN_API_ENDPOINT, { token: recoveryToken });

      return res.data;
    } catch (error: any) {
      const { response } = error;
      if (response) {
        const { data } = response;
        throw new ApiError(data.status, data.errorCode, data.message, data.details);
      } else {
        throw new Error(error.message);
      }
    }
  },

  resetPasswordWithToken: async (stateToken: string, newPassword: string): Promise<any> => {
    try {
      const res = await axios.post(RESET_PASSWORD_TOKEN_API_ENDPOINT, {
        stateToken,
        newPassword,
      });

      return res.data;
    } catch (error: any) {
      const { response } = error;
      if (response) {
        const { data } = response;
        throw new ApiError(data.status, data.errorCode, data.message, data.details);
      } else {
        throw new Error(error.message);
      }
    }
  },

  activate: async (id: string, accessToken: string): Promise<any> => {
    try {
      const res = await axios.post(
        `${USER_API_ENDPOINT}/${id}/activate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return res.status;
    } catch (error: any) {
      const { response } = error;
      if (response) {
        const { data } = response;
        throw new ApiError(data.status, data.errorCode, data.message, data.details);
      } else {
        throw new Error(error.message);
      }
    }
  },

  deactivate: async (id: string, accessToken: string): Promise<any> => {
    try {
      const res = await axios.post(
        `${USER_API_ENDPOINT}/${id}/deactivate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return res.status;
    } catch (error: any) {
      const { response } = error;
      if (response) {
        const { data } = response;
        throw new ApiError(data.status, data.errorCode, data.message, data.details);
      } else {
        throw new Error(error.message);
      }
    }
  },

  delete: async (id: string, accessToken: string, reason?: string): Promise<any> => {
    let path = `${USER_API_ENDPOINT}/${id}`;
    if (reason) {
      path = `${path}?reason=${reason}`;
    }
    try {
      const res = await axios.delete(path, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return res.status;
    } catch (error: any) {
      const { response } = error;
      if (response) {
        const { data } = response;
        throw new ApiError(data.status, data.errorCode, data.message, data.details);
      } else {
        throw new Error(error.message);
      }
    }
  },

  getRequests: async (accessToken: string, signal?: AbortSignal): Promise<any> => {
    try {
      const res = await axios.get('/api/request', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        signal,
      });

      return res.data;
    } catch (error: any) {
      const { response } = error;
      if (response) {
        const { data } = response;
        throw new ApiError(data.status, data.errorCode, data.message, data.details);
      } else {
        throw new Error(error.message);
      }
    }
  },

  getUsers: async (params: any, accessToken: string, signal?: AbortSignal): Promise<any> => {
    try {
      const res = await axios.get(`${USER_API_ENDPOINT}`, {
        params,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        signal,
      });

      return res.data;
    } catch (error: any) {
      const { response } = error;
      if (response) {
        const { data } = response;
        throw new ApiError(data.status, data.errorCode, data.message, data.details);
      } else {
        throw new Error(error.message);
      }
    }
  },

  findById: async (id: string, accessToken: string, signal?: AbortSignal): Promise<UserType> => {
    try {
      const res = await axios.get(`${USER_API_ENDPOINT}/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        signal,
      });

      return res.data as UserType;
    } catch (error: any) {
      const { response } = error;
      if (response) {
        const { data } = response;
        throw new ApiError(data.status, data.errorCode, data.message, data.details);
      } else {
        throw new Error(error.message);
      }
    }
  },

  update: async (id: string, userData: any, accessToken: string): Promise<UserType> => {
    try {
      const res = await axios.post(`${USER_API_ENDPOINT}/${id}`, userData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return res.data as UserType;
    } catch (error: any) {
      const { response } = error;
      if (response) {
        const { data } = response;
        throw new ApiError(data.status, data.errorCode, data.message, data.details);
      } else {
        throw new Error(error.message);
      }
    }
  },

  resetPassword: async (id: string, accessToken: string): Promise<number> => {
    try {
      const res = await axios.post(
        `${USER_API_ENDPOINT}/${id}/resetPassword`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return res.status;
    } catch (error: any) {
      const { response } = error;
      if (response) {
        const { data } = response;
        throw new ApiError(data.status, data.errorCode, data.message, data.details);
      } else {
        throw new Error(error.message);
      }
    }
  },
  changePassword: async (id: string, passwordData: any, accessToken: string): Promise<number> => {
    try {
      const res = await axios.post(`${USER_API_ENDPOINT}/${id}/changePassword`, passwordData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return res.status;
    } catch (error: any) {
      const { response } = error;
      if (response) {
        const { data } = response;
        throw new ApiError(data.status, data.errorCode, data.message, data.details);
      } else {
        throw new Error(error.message);
      }
    }
  },
  createUser: async (
    data: {
      firstName: string;
      lastName: string;
      email: string;
      preferredLanguage: string;
      units: string[] | any[];
    },
    accessToken: string
  ): Promise<any> => {
    try {
      const res = await axios.post(USER_API_ENDPOINT, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return res.data;
    } catch (error: any) {
      const { response } = error;
      if (response) {
        const { data } = response;
        throw new ApiError(data.status, data.errorCode, data.message, data.details);
      } else {
        throw new Error(error.message);
      }
    }
  },

  getMyProfile: async (accessToken: string): Promise<any> => {
    try {
      const res = await axios.get('/api/auth/profile', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return res.data;
    } catch (error: any) {
      const { response } = error;
      if (response) {
        const { data } = response;
        throw new ApiError(data.status, data.errorCode, data.message, data.details);
      } else {
        throw new Error(error.message);
      }
    }
  },

  editMyProfile: async (userData: any, accessToken: string): Promise<UserType> => {
    try {
      const res = await axios.post('api/auth/profile', userData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return res.data as UserType;
    } catch (error: any) {
      const { response } = error;
      if (response) {
        const { data } = response;
        throw new ApiError(data.status, data.errorCode, data.message, data.details);
      } else {
        throw new Error(error.message);
      }
    }
  },
};

export default UserService;
