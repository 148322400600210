import { forwardRef, ReactNode, Ref, useImperativeHandle, useRef } from 'react';
import { Overlay } from 'react-bootstrap';
import styles from './input-dropdown.module.scss';

type InputDropdownChildrenParams<T extends HTMLElement> = {
  ref: Ref<T>;
};
interface InputDropdownProps<T extends HTMLElement> {
  children: ({ ref }: InputDropdownChildrenParams<T>) => ReactNode;
  renderDropdown: ReactNode;
  showDropdown: boolean;
}

export type DropdownRef = { isFocused: (el: Element | null) => boolean };

const InputDropdown = forwardRef(function InputDropdown<T extends HTMLElement>(
  { children, renderDropdown, showDropdown }: InputDropdownProps<T>,
  ref: Ref<DropdownRef>
) {
  const overlayContainer = useRef<HTMLDivElement>(null);
  const target = useRef<T>(null);

  useImperativeHandle(ref, () => ({
    isFocused(el: Element | null) {
      return (target.current?.contains(el) || overlayContainer.current?.contains(el)) ?? false;
    },
  }));

  return (
    <>
      {children({ ref: target })}
      <div ref={overlayContainer} />
      <Overlay
        container={overlayContainer}
        placement="bottom-start"
        show={showDropdown}
        target={target}
      >
        {({ show, arrowProps, popper, placement, ...props }) => (
          <div {...props} className={styles.dropdown}>
            {renderDropdown}
          </div>
        )}
      </Overlay>
    </>
  );
});

export default InputDropdown;
