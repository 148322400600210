import { memo, useEffect } from 'react';
import OktaSignIn, {
  EventContext,
  EventErrorContext,
  RenderResult,
  WidgetOptions,
} from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/css/okta-sign-in.min.css';
import './oktaSignInWidget.modules.scss';
import WidgetStates from 'constants/WidgetStates';

interface OktaSignInWidgetProps {
  config: WidgetOptions;
  onSuccess: (res: RenderResult) => void;
  onSignInError: (error: EventErrorContext) => void;
  onError: (error: Error) => void;
  onChange: (newState?: WidgetStates) => void;
}

// Avoid rerendering widget on state change
const areEqual = (prevProps: OktaSignInWidgetProps, nextProps: OktaSignInWidgetProps) => {
  return (
    prevProps.onSuccess.toString() === nextProps.onSuccess.toString() &&
    prevProps.onError.toString() === nextProps.onError.toString() &&
    prevProps.config.language === nextProps.config.language
  );
};

const OktaSignInWidget = ({
  config,
  onSuccess,
  onSignInError,
  onError,
  onChange,
}: OktaSignInWidgetProps) => {
  useEffect(() => {
    const widget = new OktaSignIn(config);

    // Monitor state change
    widget.on('afterRender', (context: EventContext) => {
      onChange(context.controller as WidgetStates);
    });

    // Monitor sign in errors
    widget.on('afterError', (_: EventContext, error: EventErrorContext) => {
      onSignInError(error);
    });

    widget.showSignIn({ el: '#okta-widget' }).then(onSuccess, onError);

    return () => {
      widget.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, onSuccess, onError]);

  return <div id="okta-widget" />;
};

export default memo(OktaSignInWidget, areEqual);
