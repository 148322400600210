import { forwardRef } from 'react';
import imageMap from '../../constants/IconUrlMap';

export interface IconProps {
  name: keyof typeof imageMap;
  alt: string;
  className?: string;
  width?: string | number;
  height?: string | number;
  ariaHidden?: boolean;
}

const Icon = forwardRef<HTMLImageElement, IconProps>(function Icon(
  { name, alt, width = '25', height = '25', className = '', ariaHidden = false },
  ref
) {
  return (
    <img
      src={imageMap[name]}
      alt={alt}
      width={width}
      height={height}
      className={className}
      aria-hidden={ariaHidden}
      ref={ref}
    />
  );
});

export default Icon;
