import { Button, TextInput, Text } from 'components';
import useForgotPasswordForm, { FormLabels } from './useForgotPasswordForm';
import { useRouter, useTranslation } from 'hooks';
import styles from './ForgotPasswordForm.module.scss';
import { Dispatch, SetStateAction } from 'react';

function ForgotPasswordForm({ setSubmit }: { setSubmit: Dispatch<SetStateAction<string>> }) {
  const { t } = useTranslation();
  const router = useRouter();
  const forgotPasswordForm = useForgotPasswordForm({
    beforeSubmit: () => {
      const invalidFields = Object.keys(forgotPasswordForm.errors);
      if (invalidFields.length > 0) {
        const target = document.getElementById(invalidFields[0]);
        if (target) {
          target.focus();
          target.scrollIntoView();
        }
      }
    },
    onSuccess: (values) => {
      setSubmit(values.email);
    },
  });

  const emailError = forgotPasswordForm.touched.email && forgotPasswordForm.errors.email;

  return (
    <form onSubmit={forgotPasswordForm.handleSubmit}>
      <div className={styles.form}>
        <Text type="body" color="cf-dark-grey">
          {t(`forgotPassword.${FormLabels.email}`)}

          <TextInput
            id={FormLabels.email}
            name={FormLabels.email}
            type="text"
            error={!!emailError}
            errorText={forgotPasswordForm.errors.email}
            value={forgotPasswordForm.values.email}
            label={FormLabels.email}
            onBlur={forgotPasswordForm.handleBlur}
            onChange={forgotPasswordForm.handleChange}
          />
        </Text>
        <div className={styles.forgotPasswordButtons}>
          <Button type="submit" size="stretch" label="submit">
            {t('forgotPassword.submit')}
          </Button>
          <Button
            variant="outline"
            type="button"
            size="stretch"
            label="back"
            onClick={router.toLoginPage}
          >
            {t('forgotPassword.back')}
          </Button>
        </div>
      </div>
    </form>
  );
}

export default ForgotPasswordForm;
