import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'hooks';
import { Text } from 'components';
import styles from './LoginPage.module.scss';
import { WidgetStates } from 'constants/OktaWidget';
import Paths from 'constants/Paths';
import { Dispatch, SetStateAction } from 'react';

const AccountLocked = ({
  setWidgetState,
}: {
  setWidgetState: Dispatch<SetStateAction<WidgetStates | undefined>>;
}) => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.widgetWrapper, styles.widgetWrapperLocked)}>
      <div>
        <Text type="h2" tag="h1" typeMobile="h1">
          {t('signIn.locked.title')}
        </Text>
        <Text color="cf-dark-grey" tag="p" className={styles.text}>
          {t('signIn.locked.description')}
        </Text>
      </div>
      <Link
        className={styles.button}
        to={Paths.loginPage}
        onClick={() => setWidgetState(WidgetStates.login)}
      >
        {t('signIn.locked.back')}
      </Link>
    </div>
  );
};
export default AccountLocked;
