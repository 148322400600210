import { forwardRef, ReactNode, Ref, useEffect, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'hooks';
import { TokenInputProps } from '../token-input';
import InputLayout, { getAriaTags, InputLayoutDtoProps } from '../input-layout';
import InputDropdown, { DropdownRef } from '../input-dropdown';
import InputField from '../input-field';
import InputToken from '../input-token';
import styles from './token-combobox-input.module.scss';

export type TokenComboboxContext = {
  search: string;
  id?: string;
};

export interface TokenComboboxProps extends InputLayoutDtoProps {
  onChange: TokenInputProps['onChange'];
  control: TokenInputProps['control'];
  renderMenu: (ctx: TokenComboboxContext) => ReactNode;
  renderToken?: (token: string) => ReactNode;
  renderAboveInput?: ReactNode;
  onSearchChange?: (search: string, isClear: boolean) => void;
  onShowChange?: (show: boolean) => void;
  search: string;
  show: boolean;
}

const TokenCombobox = forwardRef(function TokenCombobox(
  {
    renderToken = (t) => t,
    renderMenu,
    renderAboveInput,
    onChange,
    onSearchChange,
    onShowChange,
    search,
    show,
    control,
    ...props
  }: TokenComboboxProps,
  ref
) {
  const { t } = useTranslation();
  const dropdownRef = useRef<DropdownRef>(null);
  const layoutRef = useRef<HTMLElement>(null);
  const canClear = !!search && show;

  useImperativeHandle(ref, () => layoutRef.current);

  useEffect(() => {
    if (!show) return;
    const handler = (event: MouseEvent) => {
      if (dropdownRef.current?.isFocused(event.target as Element)) return;
      if (dropdownRef.current?.isFocused(document.activeElement)) return;
      onShowChange?.(false);
    };

    window.addEventListener('click', handler, true);
    return () => window.removeEventListener('click', handler, true);
  }, [show, onShowChange]);

  const ariaTags = getAriaTags(layoutRef.current, props);

  return (
    <InputLayout {...props} ref={layoutRef}>
      {renderAboveInput}
      <InputDropdown
        renderDropdown={renderMenu({ id: layoutRef.current?.id, search })}
        showDropdown={canClear}
        ref={dropdownRef}
      >
        {({ ref }) => (
          <>
            <InputField
              icon={{
                left: {
                  onClick: canClear ? () => onSearchChange?.('', true) : undefined,
                  name: canClear ? 'close' : 'searchBlack',
                  alt: t(canClear ? 'clear_search' : 'search'),
                  height: 14,
                  width: 14,
                },
              }}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  onShowChange?.(false);
                } else if (!show) {
                  onShowChange?.(true);
                }
              }}
              onFocus={() => onShowChange?.(true)}
              control={{
                ...ariaTags,
                ...control,
                onChange: (e) => onSearchChange?.(e.currentTarget.value, false),
                value: search,
              }}
              error={!!props.error}
              ref={ref as Ref<HTMLDivElement>}
            />
            <InputToken
              className={styles.tokens}
              readOnly={control.disabled || canClear}
              renderToken={renderToken}
              onChange={onChange}
              tokens={control.value}
            />
          </>
        )}
      </InputDropdown>
    </InputLayout>
  );
});

export default TokenCombobox;
