import Layout from 'components/layout/layout';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'react-bootstrap';
import SignupForm from './SignupForm/SignupForm';
import styles from './SignupPage.module.scss';
import { Switch, Route } from 'react-router-dom';
import Paths from 'constants/Paths';
import { Text } from 'components';
import useTranslation from 'hooks/useTranslation';
import { Link } from 'react-router-dom';
import { ModalType } from 'constants/ModalType';
import { useModalContext } from 'context/modal-context';
import { REACT_APP_CF_HELP_EMAIL } from 'config/env';

export default function SignupPage() {
  const { t } = useTranslation();
  const { setAndShowModal, setShowModal } = useModalContext();

  const showHelpModal = () =>
    setAndShowModal({
      type: ModalType.Generic,
      title: t('signup.help_title'),
      body: (
        <>
          {t('signup.help_body')}
          <br />
          <a
            href={`mailto:${REACT_APP_CF_HELP_EMAIL}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.termsLink}
          >
            {REACT_APP_CF_HELP_EMAIL}
          </a>

          <br />
          <a
            href={t('help_modal.learn_more_url')}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.termsLink}
          >
            {t('help_modal.learn_more')}
          </a>
        </>
      ),
      handleClose: () => setShowModal(false),
    });

  return (
    <Layout
      pageTitle={t('metadata:signupPage.pageTitle')}
      pageDescription={t('metadata:signupPage.description')}
      backgroundColor="pale-blue"
      showTapestryLeft
    >
      <Row className="justify-content-md-center">
        <Col sm={12} md={8} lg={5} xl={5}>
          <Switch>
            <Route exact path={Paths.signupPage}>
              <div className={styles.widgetContainer}>
                <div className={styles.formContainer}>
                  <SignupForm />
                </div>
                <div className={styles.helpLinks}>
                  <Text type="body" className={styles.terms} color="cf-dark-grey">
                    {t('signup.existingAccount')}{' '}
                    <Link to={Paths.loginPage} className={styles.termsLink}>
                      {t('signup.signInLinkText')}
                    </Link>
                  </Text>
                  <Text type="body" className={styles.terms}>
                    <button onClick={showHelpModal} className={styles.termsLink}>
                      {t('signup.helpLinkText')}
                    </button>
                  </Text>
                </div>
              </div>
            </Route>

            <Route exact path={Paths.signupConfirmation}>
              <Helmet>
                <title>{t('metadata:signupConfirmPage.pageTitle')}</title>
                <meta name="description" content={t('metadata:signupConfirmPage.description')} />
              </Helmet>
              <div className={styles.confirmContainer}>
                <Text tag="h1" type="h2" className="mb-2">
                  {t('signup.confirmationTitle')}
                </Text>
                <div className="mb-4">
                  <Text tag="p" type="h5" color="midnight-blue">
                    {t('signup.confirmationBody1')}
                  </Text>
                </div>
                <div>
                  <Text tag="p" type="h5" color="cf-red">
                    {t('signup.confirmationBody2')}
                  </Text>
                </div>
              </div>
            </Route>
          </Switch>
        </Col>
      </Row>
    </Layout>
  );
}
