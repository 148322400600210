import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FullPageLoader } from 'components';
import { toast } from 'react-toastify';

type FullPageLoaderContextType = {
  isLoading: boolean;
  handleLoading: <T = unknown>(loadPromise: Promise<T>, noToast?: boolean) => Promise<T | void>;
};

const FullPageLoaderContext = createContext<FullPageLoaderContextType>({
  isLoading: false,
  handleLoading: async () => {},
});

export function FullPageLoaderContextProvider({ children }: FullPageLoaderContextProviderProps) {
  const [numOfPromises, setNumOfPromises] = useState(1);
  const isLoading = numOfPromises > 0;

  // Stop loading if not used at all
  useEffect(() => {
    const timer = window.setTimeout(() => {
      setNumOfPromises((num) => num - 1);
    }, 500);

    return () => {
      window.clearTimeout(timer);
    };
  }, []);

  /**
   * Wrap loading function with default error handling.
   * Overwrite default error handling by chaining custom handler in
   * provided Promise.
   * @param loadPromise Load function Promise
   */
  const handleLoading = useCallback(
    async <T = unknown,>(loadPromise: Promise<T>, noToast?: boolean): Promise<T | void> => {
      setNumOfPromises((num) => num + 1);
      return loadPromise
        .catch((error) => {
          if (!noToast) toast.error(error.message);
          console.error(error);
        })
        .finally(() => setNumOfPromises((num) => num - 1));
    },
    []
  );

  const sharedState: FullPageLoaderContextType = useMemo(
    () => ({
      isLoading,
      handleLoading,
    }),
    [isLoading, handleLoading]
  );

  return (
    <FullPageLoaderContext.Provider value={sharedState}>
      {children}
      <FullPageLoader isLoading={isLoading} />
    </FullPageLoaderContext.Provider>
  );
}

export interface FullPageLoaderContextProviderProps {
  children?: React.ReactNode;
}

export function useFullPageLoaderContext() {
  return useContext(FullPageLoaderContext);
}
