import UnitType from 'types/unit.type';
import Locale from 'constants/Locale';

export const formatUnitNameWithFloor = (unit: UnitType, locale: Locale) => {
  const {
    webStoreName,
    webStoreNameFrench,
    fno: { name: floorName },
  } = unit;

  if (locale === Locale.fr && webStoreNameFrench) {
    return `${floorName} - ${webStoreNameFrench}`;
  }

  return `${floorName} - ${webStoreName}`;
};

export const formatUnitNameWithPropertyAndFloor = (unit: UnitType, locale: Locale) => {
  const {
    webStoreName,
    webStoreNameFrench,
    fno: { name: floorName },
    property: { webPropertyName },
  } = unit;

  if (locale === Locale.fr && webStoreNameFrench) {
    return `${webStoreNameFrench} • ${floorName} - ${webPropertyName}`;
  }

  return `${webStoreName} • ${floorName} - ${webPropertyName}`;
};
