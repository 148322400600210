import UnitType from 'types/unit.type';
import { getDifferenceInDays, getFileSize, getTimeNow, getTodayString } from './form';

export enum ValidationError {
  missing = 'MISSING',
  invalid = 'INVALID_FORMAT',
  misMatch = 'MISMATCH',
  tooLong = 'TOO_LONG',
  tooShort = 'TOO_SHORT',
  tooSmall = 'TOO_SMALL',
  matches = 'MATCHES_OLD_PASSWORD',
  dateInPast = 'DATE_IN_PAST',
  timeInPast = 'TIME_IN_PAST',
  endDateBeforeStartDate = 'END_DATE_BEFORE_START_DATE',
  endTimeBeforeStartTime = 'END_TIME_BEFORE_START_TIME',
  publishDateAfterEndDate = 'PUBLISH_DATE_AFTER_END_DATE',
  publishTimeAfterEndTime = 'PUBLISH_TIME_AFTER_END_TIME',
  publishDateAfterStartDate = 'PUBLISH_DATE_AFTER_START_DATE',
  publishTimeAfterStartTime = 'PUBLISH_TIME_AFTER_START_TIME',
  publishTimeBeforeToday = 'PUBLISH_TIME_BEFORE_TODAY',
  requiresFrench = 'REQUIRES_FRENCH',
}

export function validateEmail(value: string, required = true): ValidationError | undefined {
  if (!value && required) {
    return ValidationError.missing;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return ValidationError.invalid;
  }

  return undefined;
}

export function validatePassword(value: string, required = true): ValidationError | undefined {
  if (!value && required) {
    return ValidationError.missing;
  } else if (value.length < 8) {
    return ValidationError.tooShort;
  }

  return undefined;
}

export function validateNewPassword(
  oldValue: string,
  newValue: string,
  required = true
): ValidationError | undefined {
  if (!newValue && required) {
    return ValidationError.missing;
  } else if (newValue.length < 8) {
    return ValidationError.tooShort;
  } else if (newValue === oldValue) {
    return ValidationError.matches;
  }

  return undefined;
}

export function validatePasswordConf(
  passwordNew: string,
  passwordConf: string,
  required = true
): ValidationError | undefined {
  if (!passwordConf && required) {
    return ValidationError.missing;
  } else if (passwordNew !== passwordConf) {
    return ValidationError.misMatch;
  }

  return undefined;
}

export function validateFirstName(value: string, required = true): ValidationError | undefined {
  if (!value && required) {
    return ValidationError.missing;
  } else if (value.length > 50) {
    return ValidationError.tooLong;
  }

  return undefined;
}

export function validateLastName(value: string, required = true): ValidationError | undefined {
  if (!value && required) {
    return ValidationError.missing;
  } else if (value.length > 50) {
    return ValidationError.tooLong;
  }

  return undefined;
}

export function validateStores(value: UnitType[], required = true): ValidationError | undefined {
  if (!value.length && required) {
    return ValidationError.missing;
  }

  return undefined;
}

export function validateStartDate(startDate: string, required = true): ValidationError | undefined {
  const todayDateString = getTodayString().substring(0, 10);

  if (!startDate && required) {
    return ValidationError.missing;
  } else if (startDate < todayDateString) {
    return ValidationError.dateInPast;
  }

  return undefined;
}

export function validateEndDate(
  endDate: string,
  startDate: string,
  required = true
): ValidationError | undefined {
  const todayDateString = getTodayString().substring(0, 10);

  if (!endDate && required) {
    return ValidationError.missing;
  } else if (endDate < todayDateString) {
    return ValidationError.dateInPast;
  } else if (endDate < startDate) {
    return ValidationError.endDateBeforeStartDate;
  } else if (getDifferenceInDays(startDate, endDate) > 30) {
    return ValidationError.tooLong;
  }

  return undefined;
}

export function validatePublishDate(
  publishDate: string,
  startDate: string,
  endDate: string,
  required = true
): ValidationError | undefined {
  if (!publishDate && required) {
    return ValidationError.missing;
  } else if (publishDate > startDate) {
    return ValidationError.publishDateAfterStartDate;
  } else if (getDifferenceInDays(publishDate, startDate) > 7) {
    return ValidationError.tooLong;
  } else if (publishDate > endDate) {
    return ValidationError.publishDateAfterEndDate;
  }

  return undefined;
}

export function validateStartTime(
  startTime: string,
  startDate: string,
  required = true
): ValidationError | undefined {
  const timeNow = getTimeNow();
  const todayDateString = getTodayString().substring(0, 10);

  if (!startTime && required) {
    return ValidationError.missing;
  } else if (startDate === todayDateString && startTime < timeNow) {
    return ValidationError.timeInPast;
  }

  return undefined;
}

export function validateEndTime(
  endTime: string,
  startTime: string,
  endDate: string,
  startDate: string,
  required = true
): ValidationError | undefined {
  if (!endTime && required) {
    return ValidationError.missing;
  } else if (endDate === startDate && endTime < startTime) {
    return ValidationError.endTimeBeforeStartTime;
  }

  return undefined;
}

export function validatePublishTime(
  endTime: string,
  startTime: string,
  publishTime: string,
  publishDate: string,
  endDate: string,
  startDate: string,
  required = true
): ValidationError | undefined {
  if (!publishTime && required) {
    return ValidationError.missing;
  } else if (publishDate === endDate && publishTime > endTime) {
    return ValidationError.publishTimeAfterEndTime;
  } else if (publishDate === startDate && publishTime > startTime) {
    return ValidationError.publishTimeAfterStartTime;
  } else if (new Date(`${publishDate}T${publishTime}`) < new Date()) {
    return ValidationError.timeInPast;
  }

  return undefined;
}

export function validateLocations(
  locations: string[],
  required = true
): ValidationError | undefined {
  if ((!locations || locations.length === 0) && required) {
    return ValidationError.missing;
  }

  return undefined;
}

export function validateHeadline(headline: string, required = true): ValidationError | undefined {
  if (!headline && required) {
    return ValidationError.missing;
  } else if (headline.length > 256) {
    return ValidationError.tooLong;
  }

  return undefined;
}

export function validateDescription(
  description: string,
  required = true
): ValidationError | undefined {
  if (!description && required) {
    return ValidationError.missing;
  } else if (description.length > 5000) {
    return ValidationError.tooLong;
  }

  return undefined;
}

export function validateKeywords(keywords: string[], required = true): ValidationError | undefined {
  if ((!keywords || keywords.length === 0) && required) {
    return ValidationError.missing;
  }

  return undefined;
}

export function validatePromotionCode(
  promotionCode: string,
  required = true
): ValidationError | undefined {
  if (!promotionCode && required) {
    return ValidationError.missing;
  } else if (!promotionCode.match(/^[0-9A-Za-z]*$/g)) {
    return ValidationError.invalid;
  }

  return undefined;
}

const ALLOWED_FILE_EXTENSIONS = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'];

export async function validateOfferImageFile(
  img?: File | null,
  isRequired = true
): Promise<ValidationError | undefined> {
  if (!img) return isRequired ? ValidationError.missing : undefined;
  const size = await getFileSize(img);

  const aspectRatio = size.width / size.height;
  if (size.width < 1126 || size.height < 563) {
    return ValidationError.tooSmall;
  }

  if (aspectRatio < 1.95 || aspectRatio > 2.05) {
    return ValidationError.invalid;
  }

  if (ALLOWED_FILE_EXTENSIONS.indexOf(img.type) < 0) {
    return ValidationError.invalid;
  }

  return undefined;
}

export function validateOfferLocale(
  locations: string[],
  viewableUnits: Record<string, UnitType>,
  isMultilingual: boolean
) {
  if (!isMultilingual) {
    const hasFrenchLocation = locations
      .map((location) => viewableUnits[location])
      .filter(Boolean)
      .some((location) => location.property.requiresFrench);
    if (hasFrenchLocation) {
      return ValidationError.requiresFrench;
    }
  }

  return undefined;
}
