import { useState, useEffect } from 'react';
import { debounce } from 'lodash-es';
import { useEventListener } from './useEventListener';
import BREAKPOINTS from '../constants/BreakPoints';

export enum BP {
  XS = 0,
  S = 1,
  M = 2,
  L = 3,
  XL = 4,
}

enum DEVICE {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
}

/**
 * return information about the current screen size
 * @returns {Object} { breakpoint, device, isMobile, isTablet, isDesktop }
 */
function useBreakpoints() {
  const [breakpoint, setBreakpoint] = useState(BP.L);
  const [device, setDevice] = useState<string | null>(null);

  useEffect(() => {
    updateBp();
  }, []);

  useEventListener('resize', debounce(updateBp, 10));

  function updateBp() {
    const width = window.innerWidth;

    let device: DEVICE = DEVICE.desktop;
    let bp: BP = BP.XL;

    if (width < BREAKPOINTS.S) {
      device = DEVICE.mobile;
      bp = BP.XS;
    } else if (width < BREAKPOINTS.M) {
      device = DEVICE.tablet;
      bp = BP.S;
    } else if (width < BREAKPOINTS.L) {
      device = DEVICE.desktop;
      bp = BP.M;
    } else if (width < BREAKPOINTS.XL) {
      device = DEVICE.desktop;
      bp = BP.L;
    }

    setBreakpoint(bp);
    setDevice(device);
  }

  return {
    breakpoint: breakpoint,
    device: device,
    isMobile: device === DEVICE.mobile,
    isTablet: device === DEVICE.tablet,
    isDesktop: device === DEVICE.desktop,
  };
}

export default useBreakpoints;
