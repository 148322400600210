import cx from 'classnames';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import useTranslation from 'hooks/useTranslation';
import { debounce } from 'lodash-es';
import Icon from '../rp-icon/rp-icon';
import { useBreakpoints } from 'hooks';

const SearchBox = ({ onChange }: SearchBoxProps) => {
  const { t } = useTranslation('common');
  const { isMobile, isDesktop, isTablet } = useBreakpoints();

  const handleTermChange = debounce((val: string) => {
    onChange(val);
  }, 1000);

  return (
    <InputGroup
      className={cx({
        'w-100': isMobile,
        'w-50': isTablet,
        'w-25': isDesktop,
      })}
    >
      <Form.Control
        placeholder={`${t('search')} ...`}
        className="w-50"
        type="text"
        onChange={(event) => {
          event.preventDefault();
          handleTermChange(event.target.value);
        }}
      />
      <InputGroup.Prepend>
        <InputGroup.Text>
          <Icon name="search" alt="search icon" width="18" height="18" />
        </InputGroup.Text>
      </InputGroup.Prepend>
    </InputGroup>
  );
};

interface SearchBoxProps {
  onChange: (val: string) => void;
}

export default SearchBox;
