import { OktaUserStatus, UserStatus } from 'constants/User';

export function toUserStatus(oktaUserStatus: OktaUserStatus): UserStatus {
  switch (oktaUserStatus) {
    case OktaUserStatus.ACTIVE:
      return UserStatus.Active;

    case OktaUserStatus.DEPROVISIONED:
    case OktaUserStatus.SUSPENDED:
      return UserStatus.Deactivated;

    case OktaUserStatus.PROVISIONED:
    case OktaUserStatus.STAGED:
      return UserStatus.Pending;

    case OktaUserStatus.PASSWORD_EXPIRED:
    case OktaUserStatus.LOCKED_OUT:
    case OktaUserStatus.RECOVERY:
      return UserStatus.InRecovery;
  }
}

export function toOktaUserStatuses(userStatus: UserStatus | string): OktaUserStatus[] {
  switch (userStatus) {
    case UserStatus.All:
      return [
        OktaUserStatus.ACTIVE,
        OktaUserStatus.DEPROVISIONED,
        OktaUserStatus.SUSPENDED,
        OktaUserStatus.PROVISIONED,
        OktaUserStatus.PASSWORD_EXPIRED,
        OktaUserStatus.LOCKED_OUT,
        OktaUserStatus.RECOVERY,
      ];
    case UserStatus.Active:
      return [OktaUserStatus.ACTIVE];
    case UserStatus.Deactivated:
      return [OktaUserStatus.DEPROVISIONED, OktaUserStatus.SUSPENDED];
    case UserStatus.Pending:
      return [OktaUserStatus.PROVISIONED];
    case UserStatus.InRecovery:
      return [OktaUserStatus.PASSWORD_EXPIRED, OktaUserStatus.LOCKED_OUT, OktaUserStatus.RECOVERY];
    default:
      return [];
  }
}
