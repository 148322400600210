import cx from 'classnames';
import UnitType from 'types/unit.type';
import { Container } from 'react-bootstrap';
import styles from './pill-button.module.scss';
import PillButton from './pill-button';
import { formatUnitNameWithPropertyAndFloor } from 'helpers/unitNameFormatter';
import Locale from 'constants/Locale';
import { useTranslation } from 'hooks';

function pillSortFunc(a: UnitType, b: UnitType) {
  let sortValue = a.property.webPropertyName.localeCompare(b.property.webPropertyName);
  if (sortValue === 0) {
    sortValue = a.fno.name.localeCompare(b.fno.name);
  }
  if (sortValue === 0) {
    sortValue = a.webStoreName.localeCompare(b.webStoreName);
  }
  return sortValue;
}

export function PillContainer({
  units,
  locale,
  updateUnitSelection,
  className = 'mt-2 pl-2',
  showCloseIcon = true,
}: PillContainerProps) {
  const { t } = useTranslation();

  return (
    <Container fluid className={cx(styles.pillContainer, className)}>
      {[...units].sort(pillSortFunc).map((v) => (
        <PillButton
          key={v.sys ? v.sys.id : v.unitId}
          label={t('form.pill_button_remove_label', {
            name: formatUnitNameWithPropertyAndFloor(v, locale),
          })}
          text={formatUnitNameWithPropertyAndFloor(v, locale)}
          onIconClick={showCloseIcon && updateUnitSelection ? () => updateUnitSelection(v) : null}
        />
      ))}
    </Container>
  );
}

export interface PillContainerProps {
  units: UnitType[];
  locale: Locale;
  updateUnitSelection?: (v: UnitType) => void;
  className?: string;
  showCloseIcon?: boolean;
}
