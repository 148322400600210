import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { Badge, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { keyBy } from 'lodash-es';
import cx from 'classnames';

import { LangToPlainTextMapping } from 'constants/Locale';
import { useModalContext } from 'context/modal-context';
import { useAuthContext } from 'context/auth-context';
import Property from 'types/property.type';
import RetailerGroup from 'types/retailerGroup.type';
import { useAppContext } from 'context/app-context';
import { toUserStatus } from 'helpers/userStatus';
import { OktaUserStatus } from 'constants/User';
import { ModalType } from 'constants/ModalType';
import UserType from 'types/user.type';
import UnitType from 'types/unit.type';
import Paths from 'constants/Paths';

import { Button, Icon, InlineActions, Text } from 'components';
import { UnitService, UserService } from 'services';
import { StoreSelect } from 'components/select';
import handleError from 'helpers/handleError';
import { TextInput } from 'components/form';
import { useTranslation } from 'hooks';

import styles from './ViewUserPanel.module.scss';

interface ViewUserPanelProps {
  onUserUpdate: (id: string, user: Partial<UserType> | null) => Promise<UserType | null>;
  retailerGroups: RetailerGroup[];
  properties: Property[];
}

function ViewUserPanel({ retailerGroups, properties, onUserUpdate }: ViewUserPanelProps) {
  const { token = '' } = useAuthContext();
  const initValues = useRef<Partial<UserType>>({});
  const formik = useFormik<Partial<UserType>>({
    initialValues: initValues.current,
    enableReinitialize: true,
    async onSubmit(values) {
      const newUser = await UserService.update(
        id,
        {
          units: values.profile?.units.filter((unitId) => unitMap[unitId]) ?? [],
          firstName: values.profile?.firstName,
          lastName: values.profile?.lastName,
        },
        token
      );
      await updateUser(newUser);
      toast.success(t('user_info_panel.edit_success'));
    },
  });
  const [unitMap, setUnitMap] = useState<Record<string, UnitType>>({});
  const { setAndShowModal, setShowModal } = useModalContext();
  const { id } = useParams<{ id: string }>();
  const { resetForm, setValues } = formik;
  const { locale } = useAppContext();
  const { t } = useTranslation();
  const history = useHistory();

  const dateFormat = new Intl.DateTimeFormat('default', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  const updateUser = async (payload: Partial<UserType> | null) => {
    const updatedUser =
      (await onUserUpdate(id, payload ? { ...formik.values, ...payload } : null)) ?? {};
    if (!updatedUser) {
      history.push(Paths.userManagement);
      return;
    }
    initValues.current = updatedUser;
    formik.resetForm();
  };

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      const res = await handleError(UserService.findById(id, token), () => {
        history.replace(Paths.userManagement);
      });

      const units = await UnitService.getUnitsByIds(res?.profile?.units ?? [], token);

      if (controller.signal.aborted) return;
      setUnitMap((unitMap) => ({ ...unitMap, ...keyBy(units, 'sys.id') }));
      initValues.current = res ?? {};
      resetForm(res ?? {});
      setValues(res ?? {});
    })();

    return () => {
      controller.abort();
      initValues.current = {};
      resetForm({});
      setValues({});
    };
  }, [token, resetForm, setValues, history, id]);

  if (!formik.values) {
    return (
      <div className="p-4 p-sm-5">
        <div className="d-flex align-items-center">
          <Spinner className="mr-3" animation="border" />
          <Text type="h4" tag="h2">
            {t('user_info_panel.loading')}
          </Text>
        </div>
      </div>
    );
  }

  const userUnits = (formik.values.profile?.units ?? []).filter((unit) => unitMap[unit]);
  const lastLogin = dateFormat.format(new Date(formik.values?.lastLogin ?? 0));
  const oktaUserStatus = formik.values.status ? OktaUserStatus[formik.values.status] : null;
  const userStatus = oktaUserStatus ? toUserStatus(oktaUserStatus) : null;
  const storesCount = userUnits.length ? ` (${userUnits.length})` : '';
  const canEdit = !formik.values.canActivate;
  const inlineActionsLabel1 = t(
    `user_info_panel.${formik.values.canActivate ? 'activate_account' : 'reset_password'}`
  );
  const inlineActionsLabel2 = t(
    `user_info_panel.${formik.values.canDelete ? 'remove_permanently' : 'deactivate_account'}`
  );

  return (
    <form className={cx(styles.container, 'd-flex flex-column')} onSubmit={formik.handleSubmit}>
      <div className="p-4 p-sm-5">
        <Button
          onClick={() => history.push(Paths.userManagement)}
          className="mb-4 d-md-none"
          variant="outline"
          size="small"
        >
          <Icon className={styles.backIcon} name="caretBlue" ariaHidden alt="" />
          {t('back')}
        </Button>
        <div className="mb-3">
          <Text type="h4" tag="h2">
            {t('user_info_panel.header')}
          </Text>
          <Text type="body" tag="p">
            <Badge className={cx(styles.status, styles[`status--${userStatus}`])}>
              {t(`okta.user_statuses.${oktaUserStatus}`)}
            </Badge>{' '}
            •{' '}
            {formik.values.lastLogin
              ? t('user_info_panel.last_sign_in', { lastLogin })
              : t('user_info_panel.no_sign_in')}
          </Text>
        </div>
        <TextInput
          label={t('form.firstName')}
          control={{
            name: 'profile.firstName',
            placeholder: t('form.firstName'),
            value: formik.values.profile?.firstName,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            required: true,
            disabled: !canEdit,
          }}
        />
        <TextInput
          label={t('form.lastName')}
          control={{
            name: 'profile.lastName',
            placeholder: t('form.lastName'),
            value: formik.values.profile?.lastName,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            required: true,
            disabled: !canEdit,
          }}
        />
        <div className="mb-4">
          <Text type="h7" tag="p">
            {t('form.email')}
          </Text>
          <Text type="bodySm" tag="p">
            {formik.values.profile?.email}
          </Text>
        </div>
        <div className="mb-4">
          <Text type="h7" tag="p">
            {t('form.langPref')}
          </Text>
          <Text type="bodySm" tag="p">
            {formik.values.profile?.preferredLanguage
              ? LangToPlainTextMapping[formik.values.profile.preferredLanguage]?.[locale]
              : t('user_info_panel.no_lang')}
          </Text>
        </div>
        <StoreSelect
          label={`${t('form.search_for_stores')}${storesCount}`}
          getUnitsByRetailerGroup={async (retailerGroup) => {
            if (!retailerGroup.sys?.id) return [];
            const units = await UnitService.getUnitsByRetailer(retailerGroup.sys.id, token);
            setUnitMap((unitMap) => ({ ...unitMap, ...keyBy(units, 'sys.id') }));
            return units;
          }}
          getUnitsByProperty={async (property) => {
            if (!property?.sys?.id) return [];
            const units = await UnitService.getUnitsByProperty(property.sys.id, token);
            setUnitMap((unitMap) => ({
              ...unitMap,
              ...keyBy(units, 'sys.id'),
            }));
            return units;
          }}
          onChange={(newValue) => {
            formik.setFieldValue('profile.units', newValue);
          }}
          control={{
            onBlur: formik.handleBlur,
            value: userUnits,
            disabled: !canEdit,
          }}
          getUnitById={(unitId) => unitMap[unitId]}
          retailerGroups={retailerGroups}
          properties={properties}
        />
        <InlineActions
          actions={[
            {
              onClick: () => {
                (formik.values.canActivate
                  ? async () => {
                      await UserService.activate(id, token);
                      await updateUser({
                        status: OktaUserStatus.PROVISIONED,
                        canActivate: false,
                        canDelete: false,
                      });
                      toast.success(t('user_info_panel.user_activated'));
                    }
                  : async () => {
                      await UserService.resetPassword(id, token);
                      if (
                        formik.values.status &&
                        [OktaUserStatus.ACTIVE, OktaUserStatus.LOCKED_OUT].includes(
                          formik.values.status
                        )
                      ) {
                        await updateUser({ status: OktaUserStatus.RECOVERY });
                      }
                      toast.success(t('user_info_panel.reset_password_success'));
                    })();
              },
              label: inlineActionsLabel1,
              icon: 'circularArrow',
              color: 'cf-blue',
            },
            {
              onClick: formik.values.canDelete
                ? () =>
                    setAndShowModal({
                      type: ModalType.Generic,
                      title: t('user_info_panel.deletion_warning_title'),
                      body: t('user_info_panel.deletion_warning_body'),
                      handleClose: () => setShowModal(false),
                      handleAccept: async () => {
                        try {
                          await UserService.delete(id, token);
                          await updateUser(null);
                          toast.success(t('user_info_panel.deletion_warning_success'));
                          setShowModal(false);
                          history.push(Paths.userManagement);
                        } catch (e: unknown) {
                          if (e instanceof Error) {
                            toast.error(e.message);
                          }
                        }
                      },
                      confirmButtonText: t('user_info_panel.deletion_warning_confirm'),
                      cancelButtonText: t('cancel'),
                    })
                : () =>
                    setAndShowModal({
                      type: ModalType.Generic,
                      title: t('user_info_panel.deactivate_warning_title'),
                      body: t('user_info_panel.deactivate_warning_body'),
                      handleClose: () => setShowModal(false),
                      handleAccept: async () => {
                        try {
                          await UserService.deactivate(id, token);
                          await updateUser({
                            status: OktaUserStatus.DEPROVISIONED,
                            canDelete: true,
                            canActivate: true,
                          });
                          toast.success(t('user_info_panel.deactivate_warning_success'));
                          setShowModal(false);
                        } catch (e: unknown) {
                          if (e instanceof Error) {
                            toast.error(e.message);
                          }
                        }
                      },
                      confirmButtonText: t('user_info_panel.deactivate_warning_confirm'),
                      cancelButtonText: t('cancel'),
                    }),
              label: inlineActionsLabel2,
              icon: 'archiveRed',
              color: 'horizon-red',
            },
          ]}
        />
      </div>
      <div
        className={cx(
          styles.footer,
          'p-4 px-sm-5 d-flex flex-column flex-md-row align-items-stretch align-items-md-center justify-content-end'
        )}
      >
        <Button disabled={!canEdit || !formik.dirty} type="submit" size="small">
          {t('user_info_panel.save_edit')}
        </Button>
        <Button
          onClick={() => resetForm()}
          disabled={!canEdit || !formik.dirty}
          variant="outline"
          size="small"
        >
          {t('cancel')}
        </Button>
      </div>
    </form>
  );
}

export default ViewUserPanel;
