import axios from 'axios';
import { PROPERTY_API_ENDPOINT } from 'config/env';
import type Property from 'types/property.type';

const PropertyService = {
  getAllProperties: async (
    accessToken: string,
    restrictToUserUnits = false,
    signal?: AbortSignal
  ): Promise<Property[]> => {
    const res = await axios.get(PROPERTY_API_ENDPOINT, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        restrictToUserUnits,
      },
      signal,
    });
    return res.data;
  },
  getPropertiesByIds: async (
    accessToken: string,
    ids: string[],
    restrictToUserUnits = false
  ): Promise<Property[]> => {
    const res = await axios.get(PROPERTY_API_ENDPOINT, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        ids: ids.join(','),
        restrictToUserUnits,
      },
    });
    return res.data;
  },
  getAllPropertiesSecured: async (): Promise<Property[]> => {
    const res = await axios.get(`${PROPERTY_API_ENDPOINT}/secured`);
    return res.data;
  },
};

export default PropertyService;
