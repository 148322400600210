import { InputHTMLAttributes, useRef } from 'react';
import InputLayout, { getAriaTags, InputLayoutDtoProps } from '../input-layout';
import { IconProps } from 'components/rp-icon/rp-icon';
import InputField from '../input-field';

export interface TextInputProps extends InputLayoutDtoProps {
  control: InputHTMLAttributes<HTMLInputElement>;
  icon?: {
    left?: IconProps;
    right?: IconProps;
  };
}

function TextInput({ control, icon, ...props }: TextInputProps) {
  const layoutRef = useRef<HTMLElement>(null);
  const ariaTags = getAriaTags(layoutRef.current, props);
  return (
    <InputLayout {...props} required={control.required} ref={layoutRef}>
      <InputField
        error={!!props.error}
        control={{
          ...control,
          ...ariaTags,
        }}
        icon={icon}
      />
    </InputLayout>
  );
}

export default TextInput;
