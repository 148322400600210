import cx from 'classnames';
import { Text } from 'components';
import useTranslation from 'hooks/useTranslation';
import styles from './SignupForm.module.scss';
import { Trans } from 'react-i18next';
import termsAndPolicyUrls from 'constants/TermsAndPolicyUrls';
import { useAppContext } from 'context/app-context';

function ToSAgreement(props: {
  name: string;
  checked: boolean;
  error?: boolean;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
}) {
  const { t } = useTranslation();
  const { locale } = useAppContext();

  const tosLink = (
    <a
      href={termsAndPolicyUrls.tos[locale]}
      target="_blank"
      rel="noreferrer"
      className={styles.termsLink}
    >
      {t('signup.tosLinkText')}
    </a>
  );
  const ppLink = (
    <a
      href={termsAndPolicyUrls.privacy[locale]}
      target="_blank"
      rel="noreferrer"
      className={styles.termsLink}
    >
      {t('signup.ppLinkText')}
    </a>
  );

  return (
    <label>
      <div className="d-flex align-items-center">
        <input
          name={props.name}
          className={cx({ [styles.tosCheckboxError]: !!props.error })}
          type="checkbox"
          checked={props.checked}
          onChange={props.onChange}
        />
        <Text className="ml-3" color="cf-dark-grey">
          <Trans i18nKey="signup.agreeToS">
            {' '}
            {tosLink} {ppLink}
          </Trans>
        </Text>
      </div>
      {props.error && (
        <Text tag="div" type="bodySm" className="mt-2" color="horizon-red">
          {t('error:form.tos.MISSING')}
        </Text>
      )}
    </label>
  );
}

export default ToSAgreement;
