import { useRef, useEffect } from 'react';
import { DebouncedFunc } from 'lodash-es';

export function useEventListener(
  eventName: string,
  handler: DebouncedFunc<(event: Event) => void>,
  element?: Element
) {
  const savedHandler = useRef<DebouncedFunc<(event: Event) => void>>();

  // Update ref.current value if handler changes this allows our effect below to always get latest handler
  // Without us needing to pass it in effect deps array and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const target = element || window;

    const isSupported = target?.addEventListener;
    if (!isSupported) return;

    const eventListener = (event: Event) => savedHandler.current?.(event);

    target.addEventListener(eventName, eventListener);

    return () => {
      target.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}
