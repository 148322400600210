import cx from 'classnames';
import Button from 'components/button/button';
import CustomModal from 'components/modal/modals/GenericModal';
import Icon from 'components/rp-icon/rp-icon';
import Text from 'components/text/text';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TypedInputElement } from 'types/global.type';
import InputLayout, { getAriaTags, InputLayoutDtoProps } from '../input-layout';
import styles from './image-input.module.scss';

export interface ImageInputProps extends InputLayoutDtoProps {
  control: TypedInputElement<HTMLInputElement, File | undefined>;
  onChange: (newFile: File | null) => void;
  gallery?: string[];
}

export async function linkToFile(link: string, name: string) {
  const blob = await fetch(link).then((image) => image.blob());
  const file = new File([blob], name, {
    type: blob.type,
  });
  return file;
}

function ImageInput({ control, gallery, onChange, ...props }: ImageInputProps) {
  const { t } = useTranslation();
  const { value, ...inputProps } = control;
  const [dragging, setDragging] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [draftValue, setDraftValue] = useState<File>();
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const layoutRef = useRef<HTMLElement>(null);
  const ariaTags = getAriaTags(layoutRef.current, props);

  const getGalleryImageName = (idx: number) => `[RP] Gallery image ${idx}`;

  useEffect(() => {
    if (!value) return;
    setFileUrl(URL.createObjectURL(value));
    return () =>
      setFileUrl((_fileUrl) => {
        if (_fileUrl) URL.revokeObjectURL(_fileUrl);
        return null;
      });
  }, [value]);

  const renderPreview = () => {
    if (!fileUrl) return null;
    return (
      <>
        <button onClick={() => onChange?.(null)} className={styles.delete}>
          <Icon name="discard" alt={t('image_picker.discard')} />
        </button>
        <img alt={value?.name} className={styles.preview} src={fileUrl} width="240" height="120" />
      </>
    );
  };

  const renderUploadInstructions = () => (
    <Text className={styles.body}>
      <span className={styles.text}>{t('image_picker.instruction')}</span>
      {!!gallery?.length && (
        <>
          <span className={styles.text}>{t('image_picker.or')}</span>
          <Button
            onClick={() => {
              setDraftValue(control.value);
              setShowGallery(true);
            }}
            disabled={control.disabled}
            className={styles.galleryBtn}
            type="button"
            size="small"
          >
            {t('image_picker.gallery_button_text')}
          </Button>
        </>
      )}
    </Text>
  );

  const renderDropInstructions = () => (
    <Text className={styles.body}>
      <span className={styles.text}>{t('image_picker.drop_files_here')}</span>
    </Text>
  );

  let renderContent: () => JSX.Element | null = renderUploadInstructions;
  if (dragging) {
    renderContent = renderDropInstructions;
  } else if (fileUrl) {
    renderContent = renderPreview;
  }

  return (
    <InputLayout {...props} ref={layoutRef} required={control.required}>
      <div
        onDragLeave={() => setDragging(false)}
        onDragOver={() => setDragging(true)}
        onDrop={() => setDragging(false)}
        className={cx(
          control.disabled && styles.disabled,
          !!props.error && styles.error,
          dragging && styles.dragging,
          styles.container
        )}
      >
        <input
          {...inputProps}
          {...ariaTags}
          onChange={(e) => {
            const file = e.currentTarget.files?.item(0);
            if (file) onChange?.(file);
          }}
          className={cx(inputProps.className, styles.input)}
          disabled={control.disabled}
          accept="image/*"
          type="file"
        />
        {renderContent()}
      </div>
      <CustomModal
        title={t('image_picker.default_gallery')}
        show={showGallery && !!gallery?.length}
        handleClose={() => setShowGallery(false)}
        handleAccept={() => {
          if (draftValue && draftValue !== control.value) {
            onChange?.(draftValue);
          }
          setShowGallery(false);
        }}
        confirmButtonText={t('continue')}
        cancelButtonText={t('cancel')}
        props={{ className: styles.modal }}
      >
        <ul className={styles.gallery}>
          {gallery?.map((image, key) => (
            <li key={key}>
              <Button
                onClick={async () => {
                  const file = await linkToFile(image, getGalleryImageName(key));
                  setDraftValue(file);
                }}
                label={t('image_picker.select_gallery_image', { index: key + 1 })}
                className={cx(
                  draftValue?.name === getGalleryImageName(key) && styles.active,
                  styles.galleryItem
                )}
                variant="outline"
                type="button"
              >
                <img
                  className={styles.galleryImg}
                  alt={t('image_picker.select_gallery_image')}
                  src={image}
                  width="240"
                  height="120"
                />
              </Button>
            </li>
          ))}
        </ul>
      </CustomModal>
    </InputLayout>
  );
}

export default ImageInput;
