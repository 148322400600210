enum Lang {
  en = 'en-CA',
  fr = 'fr-CA',
}

const LangToPlainTextMapping: Record<string, Record<string, string>> = {
  [Lang.en]: {
    [Lang.en]: 'English',
    [Lang.fr]: 'Anglaise',
  },
  [Lang.fr]: {
    [Lang.en]: 'French',
    [Lang.fr]: 'Française',
  },
};

enum HtmlLang {
  fr = 'fr',
  en = 'en',
}

export default Lang;
export { LangToPlainTextMapping, HtmlLang };
