import Button from 'components/button/button';
import { Text } from 'components';
import { TokenComboboxInput } from 'components/form';
import { InputLayoutDtoProps } from 'components/form/input-layout';
import { TokenInputProps } from 'components/form/token-input';
import Icon from 'components/rp-icon/rp-icon';
import { debounce } from 'lodash';
import { ReactNode, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './multi-select.module.scss';

type MultiSelectItem = { label: ReactNode; search?: string; value: string };

interface MultiSelectProps<Item extends MultiSelectItem = MultiSelectItem>
  extends InputLayoutDtoProps {
  filter?: (search: string, item: Item) => boolean;
  onChange: TokenInputProps['onChange'];
  control: TokenInputProps['control'];
  exitRef?: HTMLElement;
  items: Item[];
}

function MultiSelect<Item extends MultiSelectItem>({
  filter = (search, item) =>
    item.search?.toLowerCase().includes(search.toLowerCase()) ||
    item.label?.toString().toLowerCase().includes(search.toLowerCase()) ||
    false,
  exitRef,
  items,
  ...props
}: MultiSelectProps<Item>) {
  const value = Array.isArray(props.control.value) ? props.control.value : [];
  const itemsRef = useRef<HTMLButtonElement[]>([]);
  const layoutRef = useRef<HTMLElement>(null);
  const [draft, setDraft] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const [show, _setShow] = useState(false);
  const { t } = useTranslation();

  const indexedItems = useMemo(
    () =>
      items.reduce<Record<string, Item>>((acc, item) => {
        acc[item.value] = item;
        return acc;
      }, {}),
    [items]
  );

  const setShow = useMemo(() => debounce(_setShow, 100, { trailing: true }), []);

  // ! Find better way to get this reference
  const getOriginRef = () => layoutRef.current?.getElementsByTagName('input')?.[0];

  // render related logic
  const renderItems = (items: Item[], search: string) => {
    const filteredItems = items.filter((item) => search && filter(search, item));
    if (!filteredItems.length) {
      return search ? (
        <li className={styles.text}>
          <Text color="dusty-gray">No results</Text>
        </li>
      ) : null;
    }

    return filteredItems.map((item, key) => {
      const isChecked = draft.includes(item.value);
      return (
        <li key={key}>
          <button
            ref={(el) => {
              if (el) itemsRef.current[key] = el;
            }}
            onKeyDown={(e) => {
              let target = null;
              if (e.key === 'ArrowUp') {
                target = itemsRef.current[key - 1] ?? itemsRef.current[key];
              } else if (e.key === 'ArrowDown') {
                target = itemsRef.current[key + 1] ?? itemsRef.current[key];
              }

              if (target) {
                e.preventDefault();
                target?.focus();
              }
            }}
            onClick={() => {
              setDraft(
                isChecked ? draft.filter((value) => value !== item.value) : [...draft, item.value]
              );
            }}
            className={styles.item}
            type="button"
          >
            <span>{item.label}</span>
            {isChecked && (
              <Icon alt={`${item.label} is selected`} name="checkMark" height="20" width="20" />
            )}
          </button>
        </li>
      );
    });
  };

  return (
    <TokenComboboxInput
      {...props}
      required={props.control.required}
      renderMenu={() => (
        <fieldset
          onKeyDownCapture={(e) => {
            if (e.key !== 'Escape') return;
            e.preventDefault();
            getOriginRef()?.focus();
          }}
          className={styles.container}
        >
          {props.control.alt && <legend>{props.control.alt}</legend>}
          <ul className={styles.items}>{renderItems(items, search)}</ul>
          <div className={styles.footer}>
            <Button
              onClick={() => {
                props.onChange?.(draft);
                getOriginRef()?.focus();
                setShow(false);
                setSearch('');
              }}
              className={styles.btn}
              size="small"
            >
              {t('done')}
            </Button>
          </div>
        </fieldset>
      )}
      onShowChange={(newShow) => {
        if (newShow && !show) setDraft(value);
        setShow(newShow);
      }}
      renderToken={(item) => indexedItems[item]?.label}
      onSearchChange={(newSearch, isClear) => {
        setSearch(newSearch);
        if (isClear) {
          setShow(false);
        }
      }}
      search={search}
      ref={layoutRef}
      show={show}
    />
  );
}

export default MultiSelect;
