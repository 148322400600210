import React, { useState } from 'react';
import Icon from '../rp-icon/rp-icon';
import { Link } from 'react-router-dom';
import useTranslation from 'hooks/useTranslation';
import cx from 'classnames';
import NavItem from 'types/NavItem';
import NavItemType from 'constants/NavItemTypes';
import styles from './header.module.scss';

function NavMenu({ navItems, toggleLocale, locale, isOpen, displayNav }: NavMenuProps) {
  const { t } = useTranslation();

  return (
    <nav className={cx(styles.mobileNav, { [styles.visible]: isOpen })}>
      <div className={styles.mobileNavBody}>
        {displayNav && (
          <>
            {navItems.map((v, i) => {
              if (v.type === NavItemType.link) {
                const to = v.to as string;
                return (
                  <Link
                    key={i}
                    to={to}
                    className={cx(styles.mobileNavLink, styles.button, {
                      [styles.active]: v.active,
                    })}
                    tabIndex={0}
                  >
                    {t(v.value)}
                  </Link>
                );
              } else if (v.type === NavItemType.button) {
                return (
                  <button
                    key={i}
                    className={cx(styles.mobileNavLink, styles.button, {
                      [styles.active]: v.active,
                    })}
                    onClick={v.onClick}
                    tabIndex={0}
                  >
                    {t(v.value)}
                  </button>
                );
              }
              return <></>;
            })}

            <div className={styles.mobileNavDivider} />
          </>
        )}

        <button className={styles.mobileLocaleButton} onClick={toggleLocale}>
          {locale}
        </button>
      </div>
    </nav>
  );
}

function MobileHeader({
  navItems,
  toggleLocale,
  locale,
  displayNav,
  homeRedirect,
}: MobileHeaderProps) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <nav className={styles.mobileHeaderContainer}>
      <div className={styles.innerMenuContainer}>
        {mobileMenuOpen ? (
          <button className={styles.iconButton} onClick={() => setMobileMenuOpen((open) => !open)}>
            <Icon name="close" alt="yes" width="16" height="16" />
          </button>
        ) : (
          <button className={styles.iconButton} onClick={() => setMobileMenuOpen((open) => !open)}>
            <Icon name="hamburger" alt="yes" width="16" height="16" />
          </button>
        )}

        <Link to={homeRedirect} tabIndex={0}>
          <img src="/images/cf-logo.png" alt="cf-logo" width="105" height="24" />
        </Link>
      </div>

      <NavMenu
        navItems={navItems}
        displayNav={displayNav}
        locale={locale}
        toggleLocale={toggleLocale}
        isOpen={mobileMenuOpen}
      />
    </nav>
  );
}

export interface MobileHeaderProps {
  navItems: NavItem[];
  toggleLocale: () => void;
  locale: string;
  displayNav: boolean;
  homeRedirect: string;
}

export interface NavMenuProps {
  navItems: NavItem[];
  toggleLocale: () => void;
  locale: string;
  isOpen: boolean;
  displayNav: boolean;
}

export default MobileHeader;
