import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import type { OfferBase } from 'types';
import useTranslation from 'hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { Layout, OfferForm, CustomButton, Text, Button } from 'components';
import styles from './CreateOfferPage.module.scss';
import { useFullPageLoaderContext } from 'context/full-page-loader-context';
import type OfferCategory from 'types/offerCategory.type';
import OfferService from 'services/offer';
import useRouter from 'hooks/useRouter';
import { useModalContext } from 'context/modal-context';
import { ModalType } from 'constants/ModalType';
import handleErrors from 'helpers/handleError';
import { useOfferRequester } from 'context/requesters/offer';

function CreateOfferPage() {
  const { t } = useTranslation('common');
  const [categories, setCategories] = useState<OfferCategory[]>([]);
  const [offer, setOffer] = useState<OfferBase>();
  const offerId = useRouter().queries.get('id');
  const history = useHistory();
  const { handleLoading, isLoading } = useFullPageLoaderContext();
  const { setAndShowModal, setShowModal } = useModalContext();
  const offerReq = useOfferRequester();
  const showOfferForm = !offerId || !!offer;
  const shouldRender = !isLoading || showOfferForm;

  useEffect(() => {
    (async () => {
      if (offerReq && offerId) {
        await handleLoading(
          offerReq.get(offerId).then((offer) => {
            setOffer(offer);
          }),
          true
        );
      }
    })();
  }, [handleLoading, offerReq, offerId]);

  useEffect(() => {
    handleErrors(
      OfferService.getCategories().then((categories) => {
        setCategories(categories);
      })
    );
  }, []);

  const showWarningModal = () =>
    setAndShowModal({
      type: ModalType.Generic,
      title: t('warning'),
      body: t('lost_data_warning'),
      handleClose: () => setShowModal(false),
      handleAccept: () => {
        setShowModal(false);
        history.push('/offers');
      },
      confirmButtonText: t('continue'),
      cancelButtonText: t('cancel'),
    });

  const cardWidths = showOfferForm
    ? {
        md: 8,
        lg: 8,
        xl: 8,
      }
    : {
        md: 8,
        lg: 6,
        xl: 4,
      };

  return (
    <Layout
      pageTitle={t('metadata:createOfferPage.pageTitle')}
      pageDescription={t('metadata:createOfferPage.description')}
      showHeaderNav
      backgroundColor="pale-blue"
      showFooterNav
      withFooterNavBar
      showTapestryLeft
    >
      <Row className="justify-content-md-center">
        <Col sm={12} {...cardWidths}>
          {shouldRender ? (
            <div className={styles.formContainer}>
              {showOfferForm ? (
                <>
                  <CustomButton
                    variant="cf-text"
                    iconName="back"
                    alt="back icon"
                    text={t('back')}
                    onClick={showWarningModal}
                  />
                  <OfferForm offer={offer} retailCategories={categories} />
                </>
              ) : (
                <>
                  <Text type="h2" tag="h1" typeMobile="h1" className="mb-2">
                    {t('error:404_offer_title')}
                  </Text>
                  <Text tag="p" type="h5" color="midnight-blue" className="mb-4">
                    {t('error:404_offer_description')}
                  </Text>
                  <Button href="/offers" size="stretch" label="Back to Home">
                    {t('backToHome')}
                  </Button>
                </>
              )}
            </div>
          ) : null}
        </Col>
      </Row>
    </Layout>
  );
}

export default CreateOfferPage;
