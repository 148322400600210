import axios from 'axios';
import { UNIT_API_ENDPOINT, RETAILER_GROUP_API_ENDPOINT, PROPERTY_API_ENDPOINT } from 'config/env';
import type UnitType from 'types/unit.type';

const UnitService = {
  getAllUnits: async (): Promise<UnitType[]> => {
    const res = await axios.get(UNIT_API_ENDPOINT);
    return res.data as UnitType[];
  },

  getUnitsByIds: async (ids: string[], accessToken: string): Promise<UnitType[]> => {
    const res = await axios.get(UNIT_API_ENDPOINT, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        ids: ids.join(','),
      },
    });
    return res.data as UnitType[];
  },

  getUnitsByRetailer: async (id: string, accessToken: string): Promise<UnitType[]> => {
    const res = await axios.get(`${RETAILER_GROUP_API_ENDPOINT}/${id}/units`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data as UnitType[];
  },

  getUnitsByProperty: async (
    id: string,
    accessToken: string,
    restrictToUserUnits = false
  ): Promise<UnitType[]> => {
    const res = await axios.get(`${PROPERTY_API_ENDPOINT}/${id}/units`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        restrictToUserUnits,
      },
    });
    return res.data as UnitType[];
  },

  getUnitsById: async (ids: string[], accessToken: string): Promise<UnitType[]> => {
    if (ids.length > 0) {
      const idParam = ids.join(',');
      const res = await axios.get(`${UNIT_API_ENDPOINT}?ids=${idParam}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return res.data as UnitType[];
    }
    return [];
  },
  getSecuredUnitsByRetailer: async (id: string): Promise<UnitType[]> => {
    const res = await axios.get(`${RETAILER_GROUP_API_ENDPOINT}/${id}/securedUnits`);
    return res.data as UnitType[];
  },
  getSecuredUnitsByPropertyCd: async (propertyCd: string): Promise<UnitType[]> => {
    const res = await axios.get(`${PROPERTY_API_ENDPOINT}/${propertyCd}/securedUnits`);
    return res.data as UnitType[];
  },
};

export default UnitService;
