import cx from 'classnames';
import styles from './radio-button.module.scss';

function RadioButton(props: RadioButtonProps) {
  return (
    <div className={cx(styles.container, props.className)}>
      <input
        className={styles.radio}
        type="radio"
        id={props.id}
        name={props.name}
        checked={props.checked}
        value={props.value}
        onChange={props.onChange}
        required={props.required}
      />
    </div>
  );
}

export interface RadioButtonProps {
  checked: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value: string;
  className?: string;
  id?: string;
  name?: string;
  required?: boolean;
}

export default RadioButton;
