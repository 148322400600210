import styles from './footer.module.scss';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import Icon from '../rp-icon/rp-icon';
import { useAppContext } from 'context/app-context';
import termsAndPolicyUrls from 'constants/TermsAndPolicyUrls';
import { useTranslation, useBreakpoints } from 'hooks';
import { useOktaAuth } from '@okta/okta-react';
import { useAuthContext } from 'context/auth-context';
import Paths from 'constants/Paths';

const Footer = ({ withNavBar = false, withNavItems = false }: FooterProps) => {
  const { oktaAuth } = useOktaAuth();
  const { isMobile } = useBreakpoints();
  const { locale } = useAppContext();
  const { t } = useTranslation();
  const scrollToTop = () => window.scroll(0, 0);
  const { homeRedirect, isUserMgmt } = useAuthContext();

  return (
    <footer
      className={cx(
        styles.footer,
        { [styles.navBar]: withNavBar },
        { [styles.navHidden]: !withNavItems }
      )}
    >
      <div className={styles.imageAnchor}>
        <Icon
          className={cx(styles.tapestry, { [styles.navHidden]: !withNavItems })}
          name="tapestry"
          height={isMobile ? '133' : '263'}
          width={isMobile ? '151' : '238'}
          alt=""
        />
      </div>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <Link to={homeRedirect} tabIndex={0} onClick={scrollToTop}>
            <img
              src="/images/cf-logo-white.png"
              alt="cf-logo"
              width={isMobile ? '140' : '180'}
              height={isMobile ? '30' : '40'}
            />
          </Link>

          <p className={styles.slogan}>{t('slogan')}</p>
        </div>

        <div className={styles.spacer} />

        <div className={styles.directoryContainer}>
          {withNavItems && (
            <div className={styles.navContainer}>
              <span className={styles.navContainerHeader}>CF Retail Portal</span>

              <Link
                className={styles.navItem}
                to={isUserMgmt ? Paths.userManagement : Paths.browseOffersPage}
                onClick={scrollToTop}
              >
                {isUserMgmt ? t('users') : t('offers')}
              </Link>

              <Link className={styles.navItem} to={Paths.profile} onClick={scrollToTop}>
                {t('my_profile')}
              </Link>

              <button
                className={cx([styles.navItem, styles.button])}
                onClick={async () => oktaAuth.signOut()}
              >
                {t('sign_out')}
              </button>
            </div>
          )}

          <div className={styles.navContainer}>
            <span className={styles.navContainerHeader}>CF Retail Portal</span>

            <a
              href={termsAndPolicyUrls.contactUs[locale]}
              target="_blank"
              rel="noreferrer"
              className={styles.navItem}
            >
              {t('contact_us')}
            </a>

            <a
              href={termsAndPolicyUrls.about[locale]}
              target="_blank"
              rel="noreferrer"
              className={styles.navItem}
            >
              {t('about')}
            </a>
          </div>
        </div>
      </div>

      {isMobile && <hr className={styles.mobileDivider} />}

      <div className={styles.termsContainer}>
        <p className={styles.copyrightText}>
          {t('cf_copyright', { year: new Date().getFullYear() })}
        </p>

        <p className={styles.copyrightText}>{t('cf_trademark')}</p>

        <div className={styles.policyContainer}>
          <a
            href={termsAndPolicyUrls.accessbility[locale]}
            target="_blank"
            rel="noreferrer"
            className={styles.policyLink}
          >
            {t('accessibility')}
          </a>

          <a
            href={termsAndPolicyUrls.privacy[locale]}
            target="_blank"
            rel="noreferrer"
            className={styles.policyLink}
          >
            {t('privacy_policy')}
          </a>

          <a
            href={termsAndPolicyUrls.tos[locale]}
            target="_blank"
            rel="noreferrer"
            className={styles.policyLink}
          >
            {t('terms_of_service')}
          </a>
        </div>
      </div>
    </footer>
  );
};

export interface FooterProps {
  withNavBar?: boolean;
  withNavItems?: boolean;
}

export default Footer;
