import React, { useState } from 'react';
import { Text, TextInput } from 'components';
import useTranslation from 'hooks/useTranslation';

function PasswordField(props: {
  id?: string;
  label: string;
  value: string;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onBlur(e: React.ChangeEvent<HTMLInputElement>): void;
  error?: string;
  className?: string;
  required?: boolean;
  ariaDescribedBy?: string;
}) {
  const { t } = useTranslation(['common', 'error']);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={props.className}>
      <label>
        <Text type="body" color="cf-dark-grey">
          {t(`form.${props.label}`)}
        </Text>

        <TextInput
          id={props.id}
          type={showPassword ? 'text' : 'password'}
          value={props.value}
          label={t(`form.${props.label}`)}
          name={props.label}
          icon={{
            name: showPassword ? 'eye' : 'eyeCrossed',
            alt: showPassword ? t('form.hidePassword') : t('form.showPassword'),
            height: '30',
            width: '30',
          }}
          error={!!props.error}
          errorText={props.error}
          onIconButtonClick={() => setShowPassword(!showPassword)}
          onChange={props.onChange}
          onBlur={props.onBlur}
          required={!!props.required}
          ariaDescribedBy={props.ariaDescribedBy}
        />
      </label>
    </div>
  );
}

export default PasswordField;
