import { useFormik } from 'formik';
import * as Validator from 'helpers/validator';
import UserType from 'types/user.type';
import ApiError from 'lib/errors/ApiError';
import UnitType from '../../types/unit.type';
import { useAuthContext } from 'context/auth-context';

export enum FormLabels {
  firstName = 'firstName',
  lastName = 'lastName',
  stores = 'stores',
}

export type EditProfileFormData = {
  [FormLabels.firstName]: string;
  [FormLabels.lastName]: string;
  [FormLabels.stores]: UnitType[];
};

interface UpdateUserPayload {
  firstName: string;
  lastName: string;
  units?: string[];
}

type FormErrors = Partial<Record<FormLabels, Validator.ValidationError | null>>;

function validateForm(data: EditProfileFormData): FormErrors {
  const errors: FormErrors = {};

  if (Validator.validateFirstName(data.firstName)) {
    errors.firstName = Validator.validateFirstName(data.firstName);
  }

  if (Validator.validateLastName(data.lastName)) {
    errors.lastName = Validator.validateLastName(data.lastName);
  }

  return errors;
}

function useEditProfileForm(params: {
  userData: Partial<EditProfileFormData> | undefined;
  onSuccess?(data: UserType): void | Promise<void>;
  updateUser: (userData: UpdateUserPayload) => Promise<UserType>;
}) {
  const { isUserMgmt } = useAuthContext();

  const form = useFormik<EditProfileFormData>({
    enableReinitialize: true,
    initialValues: {
      [FormLabels.firstName]: params.userData?.firstName ?? '',
      [FormLabels.lastName]: params.userData?.lastName ?? '',
      [FormLabels.stores]: params.userData?.stores ?? [],
    },
    validateOnMount: true,
    validate: (values) => validateForm(values),
    onSubmit: async (values) => {
      try {
        const updateBody: UpdateUserPayload = {
          firstName: values.firstName,
          lastName: values.lastName,
        };

        // admins shouldn't care about unit sin their profile
        if (!isUserMgmt) {
          const unitIds = values.stores.map((v) => (v.sys ? v.sys.id : v.unitId)) as string[];
          updateBody.units = unitIds;
        }

        const user = await params.updateUser(updateBody);

        if (params.onSuccess) {
          const userData = {
            ...user,
            profile: {
              ...user.profile,
              stores: values.stores,
            },
          };

          await params.onSuccess(userData);
        }
      } catch (err: unknown) {
        if (err instanceof ApiError) {
          const apiError = err as ApiError;

          if (apiError.status === 400) {
            const errors: Record<string, string> = {};
            if (apiError.details) {
              apiError.details.forEach((detail) => {
                if (detail.param) {
                  errors[detail.param as string] = detail.msg;
                }
              });
            }
            form.setErrors(errors);
          }
        }
      }
    },
  });

  return form;
}

export default useEditProfileForm;
