import { MouseEvent, ReactNode } from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import cx from 'classnames';
import styles from '../../modal.module.scss';
import { Icon, Button } from 'components';
import { useModalContext } from 'context/modal-context';
import useTranslation from 'hooks/useTranslation';
import { toast } from 'react-toastify';

const CustomModal = ({
  show,
  title,
  handleClose,
  handleAccept,
  children,
  confirmButtonText,
  cancelButtonText,
  props,
  fullWidthButtons = false,
  acceptDisabled = false,
}: CustomModalProps) => {
  const { setShowModal } = useModalContext();
  const { t } = useTranslation();

  const handleAcceptWithToasts = async (e: MouseEvent) => {
    if (!handleAccept) return;
    try {
      await handleAccept?.(e);
    } catch (e: unknown) {
      toast.error((e as Error).message);
      setShowModal(false);
    }
  };

  return (
    <Modal
      dialogClassName={styles.dialogContainer}
      contentClassName={styles.modalContainer}
      show={show}
      onHide={handleClose}
      centered
      {...props}
    >
      <Modal.Header className={styles.modalHeaderContainer}>
        <Modal.Title className={styles.modalHeader}>{title}</Modal.Title>

        <div className={styles.closeAnchor}>
          <button
            className={styles.closeButton}
            aria-label={t('generic_modal.close_label')}
            onClick={() => (handleClose ? handleClose() : setShowModal(false))}
          >
            <Icon name="closeBlue" alt="" width="20" height="20" />
          </button>
        </div>
      </Modal.Header>

      <Modal.Body className={styles.modalBody}>{children}</Modal.Body>

      <Modal.Footer
        className={cx(styles.modalFooter, {
          [styles['modalFooter--fullWidth']]: fullWidthButtons,
        })}
      >
        {!!handleAccept && (
          <Button
            className={cx(styles.confirmButton, {
              [styles['confirmButton--fullWidth']]: fullWidthButtons,
            })}
            onClick={handleAcceptWithToasts}
            disabled={acceptDisabled}
          >
            {confirmButtonText || t('continue')}
          </Button>
        )}

        {!!cancelButtonText && (
          <Button
            className={cx(styles.cancelButton, {
              [styles['cancelButton--fullWidth']]: fullWidthButtons,
            })}
            variant="outline"
            onClick={() => (handleClose ? handleClose() : setShowModal(false))}
          >
            {cancelButtonText}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

interface CustomModalProps {
  show: boolean;
  title: ReactNode;
  handleClose?: () => void;
  handleAccept?: (event: MouseEvent) => Promise<void> | void;
  children: ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  props?: ModalProps;
  fullWidthButtons?: boolean;
  acceptDisabled?: boolean;
}

export default CustomModal;
