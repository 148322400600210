import i18n from 'i18next';
import Locale from 'constants/Locale';
import { initReactI18next } from 'react-i18next';
import enTrans from 'constants/locales/en-CA';
import frTrans from 'constants/locales/fr-CA';

i18n.use(initReactI18next).init({
  react: {
    useSuspense: false,
  },
  resources: {
    [Locale.en]: enTrans,
    [Locale.fr]: frTrans,
  },
  defaultNS: 'common',
  fallbackLng: Locale.en,
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
