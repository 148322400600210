import styles from '../store-selection-dropdown/store-selection-dropdown.module.scss';
import { DROPDOWN_ITEM_PREFIX_ID } from '../store-selection-dropdown/store-selection-dropdown';
import type { Option } from './multi-select-dropdown';

function CategoryDropdown({ categories, setSelectedCategory }: CategoryDropdownProps) {
  return (
    <>
      {categories.map((v, i) => {
        const { label } = v;

        const displayName = label;

        // TODO: French Locale for categories
        // if (locale === Locale.fr && !!nameFrench) {
        //   displayName = nameFrench;
        // }

        return (
          <li
            key={i}
            id={`${DROPDOWN_ITEM_PREFIX_ID}-${i}`}
            tabIndex={0}
            className={`${styles.dropdownItem} ${styles.retailerGroupItem}`}
            onClick={(e) => {
              e.preventDefault();
              setSelectedCategory(v);
            }}
          >
            {displayName}
          </li>
        );
      })}
    </>
  );
}

export interface CategoryDropdownProps {
  categories: Option[];
  setSelectedCategory: (v: Option) => void;
}

export default CategoryDropdown;
