import axios from 'axios';
import { RETAILER_GROUP_API_ENDPOINT } from 'config/env';
import type RetailerGroup from 'types/retailerGroup.type';

const RetailerGroupService = {
  getAllRetailerGroups: async (
    accessToken: string,
    signal?: AbortSignal
  ): Promise<RetailerGroup[]> => {
    const res = await axios.get(RETAILER_GROUP_API_ENDPOINT, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal,
    });
    return res.data as RetailerGroup[];
  },
  getRetailerGroupsByIds: async (accessToken: string, ids: string[]): Promise<RetailerGroup[]> => {
    const res = await axios.get(RETAILER_GROUP_API_ENDPOINT, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        ids: ids.join(','),
      },
    });
    return res.data as RetailerGroup[];
  },
  getAllRetailerGroupsSecured: async (): Promise<RetailerGroup[]> => {
    const res = await axios.get(`${RETAILER_GROUP_API_ENDPOINT}/secured`);
    return res.data as RetailerGroup[];
  },
};

export default RetailerGroupService;
