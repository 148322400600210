import { Row, Col, Container, Nav, Tab } from 'react-bootstrap';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import cx from 'classnames';

import { useFullPageLoaderContext } from 'context/full-page-loader-context';
import PropertyService from 'services/property';
import RetailerGroupService from 'services/retailerGroup';
import Property from 'types/property.type';
import RetailerGroup from 'types/retailerGroup.type';
import handleError from 'helpers/handleError';
import imageMap from 'constants/IconUrlMap';
import Paths from 'constants/Paths';
import { Text, Layout } from 'components';
import ViewUserPanel from './ViewUserPanel';
import UserType from 'types/user.type';
import { UserService } from 'services';
import { OktaUserStatus } from 'constants/User';
import { BP } from 'hooks/useBreakpoints';
import ApproveUserPanel from './ApproveUserPanel';
import { useBreakpoints, useTranslation } from 'hooks';

import UserRequests from './UserRequests';
import PageHeader from './PageHeader';
import UserTable from './UserTable';

import styles from './UserManagementPage.module.scss';

type UserContext = { users: UserType[]; after?: string };

export interface UserFilter {
  status: OktaUserStatus[];
  search: string;
  after?: string;
}

function UserManagementPage() {
  const [requestCtx, setRequestCtx] = useState<UserContext>({ users: [] });
  const [userCtx, setUserCtx] = useState<UserContext>({ users: [] });
  const [filters, setFilters] = useState<UserFilter>({ search: '', status: [] });
  const [retailerGroups, setRetailerGroups] = useState<RetailerGroup[]>([]);
  const [properties, setProperties] = useState<Property[]>([]);
  const showSidePanel = useRouteMatch(`${Paths.userManagement}/*`);
  const { handleLoading } = useFullPageLoaderContext();
  const { breakpoint } = useBreakpoints();
  const { t } = useTranslation();
  const auth = useOktaAuth();

  const [activeTab, setActiveTab] = useState<string>('users');
  const isMobile = breakpoint < BP.M;
  const showAllPanes = !isMobile || undefined;
  const accessToken = auth.authState?.accessToken?.accessToken;

  const fetchUsers = useCallback(
    async (filters: UserFilter, abortController?: AbortController) => {
      if (!accessToken) return;

      const res = await UserService.getUsers(
        { ...filters, status: filters.status.join(',') },
        accessToken,
        abortController?.signal
      );
      setUserCtx((ctx) => ({
        users: [...(filters.after ? ctx.users : []), ...res.users],
        after: res.after,
      }));
      setFilters(filters);
    },
    [accessToken]
  );

  useEffect(() => {
    const abortController = new AbortController();
    async function fetchRetailerGroups() {
      if (!accessToken) return;
      const res = await handleError(
        Promise.all([
          RetailerGroupService.getAllRetailerGroups(accessToken, abortController.signal),
          PropertyService.getAllProperties(accessToken, false, abortController.signal),
          UserService.getRequests(accessToken, abortController.signal),
          fetchUsers({ search: '', status: [] }, abortController),
        ])
      );

      if (abortController.signal.aborted || !res) return;
      const [retailerGroups, properties, userRequests] = res;
      setRetailerGroups(retailerGroups);
      setProperties(properties);
      setRequestCtx(userRequests);
    }

    handleLoading(fetchRetailerGroups(), true);
    return () => abortController.abort();
  }, [accessToken, fetchUsers, handleLoading]);

  const updateRequest = async (id: string) => {
    const nextRequest = requestCtx.after
      ? await UserService.getUsers(
          {
            status: OktaUserStatus.STAGED,
            after: requestCtx.after,
            limit: 1,
          },
          accessToken
        )
      : null;

    // Modify request list
    setRequestCtx((ctx) => {
      const idx = ctx.users.findIndex((request) => request.id === id);
      const users = [...ctx.users];

      if (idx !== -1) {
        users.splice(idx, 1);
      }
      if (nextRequest) {
        users.push(nextRequest.users[0]);
      }

      return {
        after: nextRequest?.after ?? null,
        users,
      };
    });
  };

  const updateUser = async (id: string, payload: Partial<UserType | null>) => {
    let updatedUser: UserType | null = null;

    setUserCtx((ctx) => {
      const idx = ctx.users.findIndex((user) => user.id === id);
      const newList = [...ctx.users];
      if (idx !== -1) {
        if (!payload) {
          newList.splice(idx, 1);
        } else {
          updatedUser = { ...ctx.users[idx], ...payload };
          newList[idx] = updatedUser;
        }
      }
      return { ...ctx, users: newList };
    });

    return updatedUser;
  };

  return (
    <Layout
      pageTitle={t('metadata:userManagementPage.pageTitle')}
      pageDescription={t('metadata:userManagementPage.description')}
      showHeaderNav
      showFooterNav
    >
      <Container className={cx(isMobile && 'flex-column', 'd-flex flex-grow-1')} fluid>
        <Row className="flex-grow-1">
          <Col className={cx(isMobile && !!showSidePanel && 'd-none', 'p-0')} md="7" lg="6">
            <div className="d-flex flex-column px-4 px-sm-5 pb-4 pb-sm-5 pt-3 pt-md-5">
              <Tab.Container activeKey={activeTab} transition={false}>
                <Nav
                  onSelect={(tab) => setActiveTab(tab ?? 'users')}
                  className={cx('d-md-none mb-4', styles.nav)}
                  activeKey={activeTab}
                  variant="tabs"
                >
                  <Nav.Link eventKey="users" className="m-0 py-2" as="button" type="button">
                    {t('user_management_page.all_users')}
                  </Nav.Link>
                  <Nav.Link eventKey="requests" className="m-0 py-2" as="button" type="button">
                    {t('user_management_page.requests', { count: requestCtx.users.length })}
                  </Nav.Link>
                </Nav>
                <Tab.Content>
                  <Tab.Pane className="mb-4" eventKey="users" active={showAllPanes}>
                    <PageHeader />
                  </Tab.Pane>
                  <Tab.Pane className="mb-4" eventKey="requests" active={showAllPanes}>
                    <UserRequests userRequests={requestCtx.users} />
                  </Tab.Pane>
                  <Tab.Pane className="mb-4" eventKey="users" active={showAllPanes}>
                    <UserTable
                      fetchUsers={fetchUsers}
                      filters={filters}
                      users={userCtx.users}
                      after={userCtx.after}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
          <Col
            className={cx(isMobile && !showSidePanel && 'd-none', styles.sidePanelFrame, 'p-0')}
            md="5"
            lg="6"
          >
            <div className={styles.sidePanel}>
              <img src={imageMap['tapestryTop']} className={styles.tapestry} alt="tapestry" />
              <Switch>
                <Route path={Paths.userManagementViewUserRequest}>
                  <ApproveUserPanel onUpdate={updateRequest} />
                </Route>
                <Route path={Paths.userManagementViewUserInfo}>
                  <ViewUserPanel
                    retailerGroups={retailerGroups}
                    properties={properties}
                    onUserUpdate={updateUser}
                  />
                </Route>
                <Route path={Paths.userManagement}>
                  <div className="p-4 p-sm-5">
                    <Text type="h3" tag="h2">
                      {t('landing_panel_select_user')}
                    </Text>
                    <Text color="cf-dark-grey" type="body" tag="p">
                      {t('landing_panel_view_info')}
                    </Text>
                  </div>
                </Route>
              </Switch>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export interface UserManagementPageProps {
  SidePanelContent: JSX.Element;
}

export default UserManagementPage;
